import { useContext, useEffect, useState } from "react";

import "../style.css";

import Modal from "react-bootstrap/Modal";
import CloseIcon from "@mui/icons-material/Close";
import api from "../../../../services/api";
import { CartContext } from "../../../../contexts/cart";

function EnderecoModalAdd({
  isOpen,
  onRequestClose,
  atualizarSite,
}) {
    
  const [id_endereco, setId_Endereco] = useState(0);
  const [endereco, setEndereco] = useState("");
  const [complemento, setComplemento] = useState("");
  const [bairro, setBairro] = useState("");
  const [cidades, setCidades] = useState([]);
  const [cidade, setCidade] = useState("");
  const [uf, setUF] = useState("");
  const [cep, setCEP] = useState("");
  const [codCidade, setCodCidade] = useState("");
  const [ind_padrao, setInd_padrao] = useState("");
  const [mensagem, setMensagem] = useState("");

  const [numeroValido, setNumeroValido] = useState(false);
  const [mudando, setMudando] = useState(false);
  const [pegouInfoNumero, setPegouInfoNumero] = useState(false);
  const [usuarioExiste, setUsuarioExiste] = useState("");
  const [ enderecoTelefone, setEnderecoTelefone] = useState("");


  const {

    isMobile,

  } = useContext(CartContext);

  async function EnderecoPorCep() {
    try {

        if(cep.length === 8){
          const infoCep = await api.get(`/v1/endereco/${cep}`);


          if (!infoCep?.data.erro) {
                   const { cep, logradouro , ibge, localidade, bairro, uf } = infoCep?.data;
          
                   setCodCidade(ibge)
                   setCidade(localidade)
    
    
                   setId_Endereco(logradouro)
                   setCEP(cep?.replace("-", ""));
                   setEndereco(logradouro)
                    setUF(uf)
           
                   setBairro(bairro)
    
                   setNumeroValido(true);
                   setUsuarioExiste(true);
                   setPegouInfoNumero(true)
          }

        }

      


    } catch {
      console.log("CEP não existe");
    }
  }

  async function SalvarEndereco() {

    await api.post(`v1/usuarios/enderecos/`, {
        endereco,
        complemento,
        bairro,
        cidade,
        uf,
        cep,
        ind_padrao,
        cod_cidade: codCidade
    })
    onRequestClose()
    atualizarSite()

  }

  useEffect(() => {
    setEnderecoTelefone(true);
    atualizarSite();
  }, [mudando]);

  useEffect(() => {
    EnderecoPorCep()
  },[cep])
  
  useEffect(() => {
    
    
    if (cep.length < 8) {
      setBairro("");
      setCidade("");
      setUF("");
      setEndereco("");
      setCodCidade("")
      atualizarSite();
    }

  }, [cep]);



  return (
    <Modal
      show={isOpen}
      onHide={() => {
        onRequestClose();
      }}
      scrollable="true"
      centered
      fullscreen
    >
      <Modal.Header
        className="pb-0"
        style={{
          position: "relative",
          alignItems: "inherit",
          borderBottom: "none",
        }}
      >
        <Modal.Title
          style={{
            width: "100%",
            fontSize: "inherit",
            fontWeight: "inherit",
            alignItems: "inherit",
          }}
        >
          <div className="d-flex align-items-center justify-content-between">
            <h4 style={{ marginBottom: 0 }}> Novo endereço </h4>

            <button
              onClick={() => {
                onRequestClose();
              }}
              className="btn btn-sm btn-link text-danger btn-fechar"
              style={{ position: "absolute", right: 8, top: 8 }}
            >
              <CloseIcon style={{ fontSize: 32 }} />
            </button>
          </div>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
      <div className="endereco"  style={ isMobile ? {} : {margin: "200px 500px"} }> 
          <div className="col-12">
            <form>
              <div className="row">
                <div className="mb-2 col-12">
                  <input
                    type="text"
                    style={{ fontWeight: "bold" }}
                    placeholder="CEP"
                    onChange={(e) => {
                      setMudando(!mudando);
                      const regex = /^[0-9]{0,8}$/;
                      const inputValue = e.target.value;
                      if (regex.test(inputValue)) {
                        setCEP(inputValue);
                      }
                    }}
                    value={cep}
                    className="form-control"
                    id="InputNome"
                    aria-describedby="nome"
                    // disabled={!pegouInfoNumero}
                  />
                </div>
              </div>
              <div className="row">
                <div className=" col-8 d-inline-block">
                  <input
                    style={{ fontWeight: "bold" }}
                    placeholder="Endereço"
                    type="text"
                    onChange={(e) => {
                      setMudando(!mudando);

                      setEndereco(e.target.value);
                    }}
                    value={endereco}
                    className="form-control mb-2"
                    id="InputNome"
                    aria-describedby="nome"
                    disabled
                  />
                </div>
                <div className=" col-4 d-inline-block">
                  <input
                    type="text"
                    style={{ fontWeight: "bold" }}
                    placeholder="Complemento"
                    onChange={(e) => {
                      setMudando(!mudando);
                      setComplemento(e.target.value);
                    }}
                    value={complemento}
                    className="form-control mb-2"
                    id="InputNome"
                    aria-describedby="nome"
                    disabled={
                      // telefone.length !== numeroTelefoneQuantidade ||
                      cep.length !== 8
                        ? true
                        : false
                    }
                  />
                </div>
              </div>

              <div className="row">
                <div className=" col-12">
                  <input
                    style={{ fontWeight: "bold" }}
                    placeholder="Bairro"
                    type="text"
                    onChange={(e) => {
                      setMudando(!mudando);

                      setBairro(e.target.value);
                    }}
                    value={bairro}
                    className="form-control mb-2"
                    id="InputNome"
                    aria-describedby="nome"
                    disabled
                  />
                </div>
              </div>

              <div className="row">
                <div className=" col-4 d-inline-block">
                  <input
                    style={{ fontWeight: "bold" }}
                    placeholder="Cidade"
                    type="text"
                    className="form-control mb-2"
                    aria-describedby="nome"
                    name="cidades"
                    id="cidades"
                    onChange={(e) => {
                      setMudando(!mudando);
                      setCidade(e.target.value);
                    }}
                    value={cidade}
                    disabled
                  />
                </div>
                <div className=" col-4 d-inline-block">
                  <input
                    type="text"
                    style={{ fontWeight: "bold" }}
                    placeholder="Estado"
                    onChange={(e) => {
                      setMudando(!mudando);
                      setUF(e.target.value);
                    }}
                    value={uf}
                    className="form-control mb-2"
                    id="InputNome"
                    aria-describedby="nome"
                    disabled
                  />
                </div>
                <div className=" col-4 d-inline-block">
                  <input
                    type="text"
                    style={{ fontWeight: "bold" }}
                    placeholder="Cod Cidade"
                    onChange={(e) => {
                      setMudando(!mudando);
                      setCodCidade(e.target.value);
                    }}
                    value={codCidade}
                    className="form-control mb-2"
                    id="InputNome"
                    aria-describedby="nome"
                    disabled
                  />
                </div>
              </div>

              
            </form>
          </div>

          {mensagem.length > 0 ? (
            <div className="alert alert-danger mt-2 text-center">
              {mensagem}
            </div>
          ) : null}
        </div>
      </Modal.Body>

      <Modal.Footer>
        <div className="row">
          <div className="col-12 mt-3 d-flex justify-content-end align-items-center">
            <button
              onClick={SalvarEndereco}
              type="button mt-3"
              className={`btn btn-lg ${
                localStorage.getItem("sessionMode") === "light"
                  ? "btn-danger"
                  : "btn-secondary"
              }`}
            >
              Salvar Dados
            </button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default EnderecoModalAdd;
