import React, { createContext, useContext, useEffect } from 'react';

const Context = createContext();

export const StateContext = ({ children }) => {

  const getMode = () => {
    const loadMode = () => {
      if(localStorage.getItem('sessionMode') === 'light') {
        document.body.classList.remove('dark-mode')
        localStorage.setItem('sessionMode', 'light')
      } 
      if(localStorage.getItem('sessionMode') === 'dark') {
        document.body.classList.add('dark-mode')
        localStorage.setItem('sessionMode', 'dark')
      }
    }
    loadMode();
  }
  
  useEffect(() => {
    getMode()
  }, []);

  return (
    <Context.Provider
      value={{
        
      }}
    >
      {children}
    </Context.Provider>
  )
}

export const useStateContext = () => useContext(Context);