import { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { Navbar, Categoria, Estabelecimento, Footer } from "../../components";
import api from "../../services/api";

import { Tabs } from "@mui/material";

function Busca() {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [resultado, setResultado] = useState([]);
  const [mais, setMais] = useState(true);
  const [processando, setProcessando] = useState(false);
  const [pagina, setPagina] = useState(1);
  const [paginaApi, setPaginaApi] = useState(0);

  var id_categoria = searchParams.get("id_cat");
  var id_banner = searchParams.get("id_banner");
  var situacao = searchParams.get("situacao");
  var descricao = searchParams.get("descr") ?? "Busca";
  var busca = searchParams.get("q") ?? "";

  const [categorias, setCategorias] = useState([]);
  const [valueCategoria, setValueCategoria] = useState(0);

  const handleChangeCategoria = (event, newValue) => {
    setValueCategoria(newValue);
  };

  async function ListarEstabelecimentos(indReset) {
    setProcessando(true);

    setPaginaApi(indReset ? 1 : pagina + 1);

    try {
      const response = await api.get("/v1/estabelecimentos", {
        params: {
          cod_cidade: localStorage.getItem("sessionCodCidade"),
          nome: busca,
          id_categoria,
          id_banner,
          situacao,
          pagina: paginaApi,
        },
      });

      if (indReset) {
        setResultado(response.data);
        setPagina(1);
      } else {
        setResultado((oldArray) => [...oldArray, ...response.data]);
        setPagina(pagina + 1);
      }
      setProcessando(false);
      setMais(response.data.length >= 12);
    } catch (error) {
      setProcessando(false);
    }
  }

  useEffect(() => {
    ListarEstabelecimentos(true);
  }, [location]);

  useEffect(() => {
    api
      .get(
        `v1/categorias?cod_cidade=${localStorage.getItem("sessionCodCidade")}`
      )
      .then((response) => {
        setCategorias(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  });

  return (
    <>
      <Navbar />

      <div
        className="d-flex justify-content-center mt-3"
        style={{ paddingTop: "20px" }}
      >
        <Tabs
          value={false}
          onChange={handleChangeCategoria}
          textColor="inherit"
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          aria-label="scrollable force tabs example"
        >
          {categorias.map((categoria) => {
            return (
              <Categoria
                key={categoria.id_categoria}
                url_imagem={
                  localStorage.getItem("sessionMode") === "light"
                    ? categoria.foto
                    : categoria.foto_dark
                }
                descricao={categoria.categoria}
                id_categoria={categoria.id_categoria}
              />
            );
          })}
        </Tabs>
      </div>

      <div className="container-fluid">
        <div className="row mt-3">
          <h3>{descricao}</h3>
          <div className="col-12">
            <div>
              {busca.length > 0 && (
                <small className="mb-4 text-secondary">
                  Pesquisando por: {busca}
                </small>
              )}
            </div>
            {!processando && resultado.length === 0 && (
              <small>Nenhum resultado encontrado</small>
            )}
          </div>
        </div>

        <div className="container-fluid">
          <div className="row mt-2">
            {resultado.map((estabelecimento) => {
              return (
                <Estabelecimento
                  key={estabelecimento.nome}
                  url_imagem={estabelecimento.url_logo}
                  nome={estabelecimento.nome}
                  avaliacao={estabelecimento.avaliacao}
                  categoria={estabelecimento.categoria}
                  id_estabelecimento={estabelecimento.id_estabelecimento}
                  situacao={estabelecimento.situacao}
                />
              );
            })}
          </div>
        </div>

        <div className="container-fluid">
          {processando ? (
            <div className="text-center">
              <span
                className="spinner-grow spinner-grow-sm text-danger me-2"
                role="status"
              ></span>
              <span className="text-danger">Buscando restaurantes...</span>
            </div>
          ) : null}

          {!processando && mais ? (
            <div className="row my-4">
              <button
                onClick={(e) => ListarEstabelecimentos(false)}
                className="btn btn-outline-danger"
                style={{ width: "250px", margin: "auto", marginTop: 50 }}
              >
                Ver mais restaurantes
              </button>
            </div>
          ) : null}
        </div>
      </div>

      <Footer />
    </>
  );
}

export default Busca;
