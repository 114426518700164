import React, { useState, useEffect } from 'react'

import { useNavigate } from 'react-router-dom';

import Modal from 'react-bootstrap/Modal'

import api from '../../../services/api'
import CloseIcon from '@mui/icons-material/Close';

import { Box, Autocomplete, TextField, IconButton } from '@mui/material'
import { HiSearch } from 'react-icons/hi'
import { toast } from 'react-toastify'

const ModalCidades = (props) => {

    const navigate = useNavigate()

    const [cidades, setCidades] = useState([])

    const [cidade, setCidade] = useState('');
    const [uf, setUF] = useState('');    
    const [codCidade, setCodCidade] = useState('');

    useEffect(() => {
        const carregarCidades = async() => {
            const response = await api.get('v1/cidades')
            setCidades(response.data)
        }
        carregarCidades()
    }, [])

    const handleEscolherCidade = (e) => {
        e.preventDefault()

        if(!codCidade) {
            toast.error('Escolhe uma cidade');
        } else {
            localStorage.setItem('sessionCodCidade', codCidade);
            localStorage.setItem('sessionCidade', cidade);
            localStorage.setItem('sessionUF', uf);
            navigate(`/`);
        }
    }

    const handleSelecionarCidade = (value) => {        
        if(value === 'CIANORTE - PR') {
            setCidade('CIANORTE'); setUF('PR'); setCodCidade(4105508)
        } else if(value === 'TERRA BOA - PR') {
            setCidade('TERRA BOA'); setUF('PR'); setCodCidade(4127205)
        }
    }

    const [inputValue, setInputValue] = useState("");
    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        if (inputValue.length > 2) {
            setOpen(true);
        }
    };
    const handleInputChange = (event, newInputValue) => {
        setInputValue(newInputValue);
        
        if (newInputValue.length > 2) {
            setOpen(true);
        } else {
            setOpen(false);
        }
    };

    return (
        <>
            <Modal
                show={props.isOpen}
                onHide={() => { props.onRequestClose(); }}
                scrollable='true'
                backdropClassName='backdropFilter'
            >
                <Modal.Header className='pb-1' style={{ position: 'relative', alignItems: 'inherit', borderBottom: 'none' }}>

                    <Modal.Title style={{ width: '100%', fontSize: 'inherit', fontWeight: 'inherit', alignItems: 'inherit' }}>
                        <div className='d-flex align-items-center justify-content-between'>
                            
                            <h4 className="m-0 pe-3">Escolher Cidade</h4>

                            <button onClick={() => { props.onRequestClose(); }} className='pe-1 btn btn-sm btn-link text-danger btn-fechar py-0'>
                                <CloseIcon style={{ fontSize: 32 }} />
                            </button>
                        </div>

                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Box component='form' onSubmit={handleEscolherCidade} sx={{ display: 'flex', alignItems: 'center' }}>                        
                        <Autocomplete
                            fullWidth
                            noOptionsText='A cidade não possui estabelecimentos.'
                            options={cidades.map((c) => c.cidade + ' - ' + c.uf  )}
                            renderInput={(params) => (
                                <TextField id='input-cidade' {...params} label="Digite o nome da cidade" color='error' size='small' autoFocus />
                            )}
                            onChange={(event, value) => handleSelecionarCidade(value)}
                            open={open}
                            onOpen={handleOpen}
                            onClose={() => setOpen(false)}
                            inputValue={inputValue}
                            onInputChange={handleInputChange}
                        />
                        <IconButton
                            type='submit'
                            className='search-button'
                            sx={{ ml: .75, color: '#FFF', backgroundColor: '#dc3545' }}
                        >
                            <HiSearch />
                        </IconButton>
                    </Box>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ModalCidades