/* eslint-disable react/jsx-no-duplicate-props */
import React, { useContext, useEffect, useRef, useState } from "react";
import "./style.css";
import Modal from "react-bootstrap/Modal";
import ProdutoItemCheckbox from "../produto-item-checkbox";
import ProdutoItemRadio from "../produto-item-radio";
import ProdutoItemMultiplos from "../produto-item-multiplos";
import api from "../../../services/api";
import { CartContext } from "../../../contexts/cart";
import { v4 as uuidv4 } from "uuid";
import CloseIcon from "@mui/icons-material/Close";
import FormLogin from "../../forms/login";
import { CircularProgress, Stepper, Step, StepContent } from "@mui/material";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { DrawPizza } from "../../drawPizza";

// esse é o modal real para finalizar o pedido

function ProdutoModal(props) {
  const { cart, AddItemCart, isMobile } = useContext(CartContext);
  const navigate = useNavigate();

  const [id_produto, setId_produto] = useState(0);
  const [id_grade, setId_grade] = useState(0);
  const [tipo, setTipo] = useState("G");
  const [tamanho, setTamanho] = useState("");
  const [nome, setNome] = useState("");
  const [descricao, setDescricao] = useState("");
  const [vl_produto, setVl_produto] = useState(0);
  const [vl_promocao, setVl_promocao] = useState(0);
  const [url_foto, setUrl_foto] = useState("");
  const [qtd, setQtd] = useState(1);

  const [categorias, setCategorias] = useState([]);
  const [produtos, setProdutos] = useState([]);

  const [opcoes, setOpcoes] = useState([]);
  const [grupos, setGrupos] = useState([]);

  const [bloquearBtn, setBloquearBtn] = useState(true);
  const [verificarBloquear, setVerificarBloquear] = useState(true);
  const [total, setTotal] = useState(0);

  const [loading, setLoading] = useState(false);
  const [ingredientes, setIngredientes] = useState([]);
  const [observacao, setObservacao] = useState("");

  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [itensI, setItensI] = useState([]);

  // Cesar - 05/10/2022
  const [itemsCart, setItemsCart] = useState([]);
  const [amount_of_flavors, setAmountOfFlavors] = useState(0);
  const [type_flavor, setTypeFlavor] = useState("SALGADO");
  const [quantity_flavors, setQuantityFlavors] = useState(0);
  const [tpValor, setTpValor] = useState(0)

  const modalBodyRef = useRef(null);

  const handleScrollToTop = () => {
    if (modalBodyRef.current) {
      modalBodyRef.current.scrollTop = 0;
    }
  }

  useEffect(() => {
    if (props.id_produto <= 0) {
      return;
    }

    setLoading(true);
    api
      .get(`v1/produtos/${props.id_produto}`)
      .then((response) => {
        setId_produto(props.id_produto);
        setNome(response.data[0].nome);
        setDescricao(response.data[0].descricao);
        setUrl_foto(response.data[0].url_foto);
        setTipo(response.data[0].tipo);
        setId_grade(response.data[0].id_grade);
        setTamanho(response.data[0].tamanho);
        setVl_produto(response.data[0].vl_produto);
        setVl_promocao(response.data[0].vl_promocao);
        setQtd(1);
        setTotal(
          response.data[0].vl_promocao > 0
            ? response.data[0].vl_promocao
            : response.data[0].vl_produto
        );

        setItemsCart([]);
        setAmountOfFlavors(response.data[0].qt_fracionado); // quantidade de sabores
        setTypeFlavor("SALGADO");
        setQuantityFlavors(1);
        setLoading(false);
        setTpValor(response.data[0].tp_valor_divisao_item)

      })
      .catch((err) => console.log(err));

    api
      .get(`v1/produtos/ingredientes/${props.id_produto}`)
      .then((response) => {
        let ingredientesUnico = response.data.map((g) => {
          return {
            descricao: g.descricao,
            id_ingrediente: g.id_ingrediente,
            selecao: [],
          };
        });

        ingredientesUnico = ingredientesUnico.filter((item, index, arr) => {
          return (
            arr.findIndex((t) => {
              return t.id_ingrediente === item.id_ingrediente;
            }) === index
          );
        });

        setIngredientes(ingredientesUnico);
      })
      .catch((err) => console.log(err));

    api
      .get(`v1/cardapios/opcoes/${props.id_produto}`)
      .then((response) => {
        setOpcoes(response.data);

        let gruposUnico = response.data.map((g) => {
          return {
            id_opcao: g.id_opcao,
            id_produto: g.id_produto,
            nome_opcao: g.nome_opcao,
            descricao_opcao: g.descricao_opcao,
            ind_ativo: g.ind_ativo,
            ind_obrigatorio: g.ind_obrigatorio,
            ordem: g.ordem,
            qtd_max_escolha: g.qtd_max_escolha,
            qtd_limiteitem: g.qtd_limiteitem,
            qtd_minimoitem: g.qtd_minimoitem,
            selecao: [],
          };
        });

        gruposUnico = gruposUnico.filter((item, index, arr) => {
          return (
            arr.findIndex((t) => {
              return t.id_opcao === item.id_opcao;
            }) === index
          );
        });

        setGrupos(gruposUnico);

        HabilitaBotao(gruposUnico);
      })
      .catch((err) => console.log(err));
  }, [props.isOpen]);

  useEffect(() => {
    api
      .get(`/v1/cardapios/${props.id}`)
      .then((response) => {
        let categoriasUnica = response.data.map((item) => item.categoria);

        categoriasUnica = categoriasUnica.filter(
          (itemArray, i, arrayCompleto) => {
            return arrayCompleto.indexOf(itemArray) === i;
          }
        );

        setCategorias(categoriasUnica);
        setProdutos(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [props.id]);

  function ClickMais() {
    setQtd(qtd + 1);
  }

  function ClickMenos() {
    qtd > 1 ? setQtd(qtd - 1) : setQtd(1);
  }

  function AddItem() {
    let removidos = [];

    let detalhes = [];
    let vl_detalhes = 0;

    grupos.map((item) => {
      item.selecao.map((sel) => {
        let valor = 0;

        if (sel.quantidade == undefined) {
          valor = sel.vl_item;
        } else {
          valor = sel.vl_item * sel.quantidade;
        }

        vl_detalhes += valor;

        detalhes.push({
          nome: sel.nome ? sel.nome : sel.nome_item,
          descricao: sel.descricao,
          nome_opcao: item.nome_opcao,
          descricao_opcao: sel.descricao_opcao,
          id_item: sel.id_item,
          vl_item: sel.vl_item,
          quantidade: sel.quantidade,
          ordem: sel.ordem,
        });
      });
    });

    ingredientes.map((item) => {
      item.selecao.map((sel) => {
        removidos.push({
          descricao: sel.descricao,
        });
      });
    });

    const item = {
      descricao: descricao ? descricao : nome,
      detalhes: detalhes,
      id_carrinho: uuidv4(),
      id_produto: id_produto,
      nome: nome,
      observacao: observacao,
      qtd: qtd,
      removidos: removidos,
      tipo: tipo,
      url_foto: url_foto,
      vl_total: vl_detalhes + vl_promocao > 0 ? vl_promocao : vl_produto * qtd,
      vl_unit: vl_detalhes + (vl_promocao > 0 ? vl_promocao : vl_produto),
    };

    AddItemCart(item);
    props.onRequestClose();

    handleReset();
  }

  function AddProdutos() {
    if (itensI.length == 0) {
      toast.error("Selecione a(s) bebida(s)");
      return;
    }

    for (const item of itensI) {
      AddItemCart(item);
    }

    isMobile === true
      ? FinalizarPedido()
      : toast.success("Pedido foi adicionado na sacola");

    props.onRequestClose();

    handleReset();

    setItensI([]);
  }

  function SelecionaProduto(produto, type) {
    let arrayTemp = itensI;

    let objIndexSel = arrayTemp.findIndex(
      (obj) => obj.id_produto == produto.id_produto
    ); //Procura o item clicado
    let qtd = 0;

    if (type == "add") {
      if (objIndexSel >= 0) {
        //Achou o item clicado, então muda sua quantidade
        arrayTemp[objIndexSel].qtd += 1;
      } else {
        //Não achou o item clicado, então adiciona-o
        let prodFinal = {
          detalhes: [],
          removidos: [],
          id_carrinho: uuidv4(),
          id_produto: produto.id_produto,
          descricao: produto.nome,
          nome: produto.nome,
          qtd: 1,
          observacao: observacao,
          tipo: produto.tipo,
          url_foto: produto.url_foto,
          vl_produto: produto.vl_produto,
          vl_total:
            produto.vl_promocao > 0
              ? produto.vl_promocao
              : produto.vl_produto * qtd,
          vl_unit:
            produto.vl_promocao > 0 ? produto.vl_promocao : produto.vl_produto,
        };

        arrayTemp.push(prodFinal);
      }
      setItensI(arrayTemp);
    } else {
      //remover

      if (objIndexSel >= 0) {
        //Achou o item clicado, então muda sua quantidade
        arrayTemp[objIndexSel].qtd -= 1;

        if (arrayTemp[objIndexSel].qtd == 0) {
          arrayTemp.splice(objIndexSel, 1); //remove de vez o item, já que a quantidade é 0
        }
      }

      setItensI(arrayTemp);
    }

    let vlTotal = 0.0;


    for (const produto of arrayTemp) {

      vlTotal += produto.vl_unit * produto.qtd;
    }

    setTotal(vlTotal);

    let prods = produtos;
    let objIndexSel2 = itensI.findIndex(
      (obj) => obj.id_produto == produto.id_produto
    ); //Procura o item clicado
    for (const prod of prods) {
      if (prod.id_produto == produto.id_produto) {
        objIndexSel2 == -1
          ? (prod.quantidade = 0)
          : (prod.quantidade = itensI[objIndexSel2].qtd);
      }
    }
    setProdutos(prods);
  }

  function SelecionaRadioButton(op) {
    let g = grupos;


    // Descobrir o indice do grupo clicado...
    let objIndex = g.findIndex((obj) => obj.id_opcao == op.id_opcao);

    // Atualizar informacao do item naquele indice...
    // console.log(objIndex, grupos)
    
    if(objIndex != -1){
      g[objIndex].selecao = [op];
    } 

    // if (grupos && op && objIndex === 0) {
    //   g[objIndex]["selecao"] = [op]

    // }
    // if (grupos && op && objIndex === -1) {
    //   g[objIndex]["selecao"] = []
  
    // }
    // console.log('ping',grupos,objIndex)

    


    setGrupos(g);
    HabilitaBotao(g);
    CalculaTotal(g);
  }

  function SelecionaCheckbox(isChecked, op) {
    let g = grupos;
    let s = [];

    // Descobrir o indice do grupo clicado...
    let objIndex = g.findIndex((obj) => obj.id_opcao === op.id_opcao);

    // Extrai os itens selecionados...
    s = g[objIndex].selecao;

    // Verfificar se deve inserir ou remover um item...
    if (isChecked) {
      s.push(op);
    } else {
      let objIndexSel = s.findIndex((obj) => obj.id_item == op.id_item);
      s.splice(objIndexSel, 1);
    }

    g[objIndex].selecao = s;
    setGrupos(g);
    HabilitaBotao(g);
    CalculaTotal(g);
  }

  function SelecionaQuantidade(opcao, type) {
    let g = grupos;
    let s = [];

    let quantidade = 0;

    // Descobrir o indice do grupo clicado...
    let objIndex = g.findIndex((obj) => obj.id_opcao === opcao.id_opcao);

    // Extrai os itens selecionados...
    s = g[objIndex].selecao;

    if (type === "add") {
      let objIndexSel = s.findIndex((obj) => obj.id_item === opcao.id_item);
      if (objIndexSel === -1) {
        opcao.quantidade = 1;
        s.push(opcao);
      } else {
        let quantidade = s[objIndexSel].quantidade;
        s.splice(objIndexSel, 1);
        opcao.quantidade = quantidade + 1;
        s.push(opcao);
      }
    } else if (type === "del") {
      let objIndexSel = s.findIndex((obj) => obj.id_item === opcao.id_item);
      if (objIndexSel > -1) {
        if (s[objIndexSel].quantidade === 1) {
          s.splice(objIndexSel, 1);
          opcao.quantidade = 0;
        } else {
          let quantidade = s[objIndexSel].quantidade;
          s.splice(objIndexSel, 1);
          opcao.quantidade = quantidade - 1;
          s.push(opcao);
        }
      }
    }

    g[objIndex].selecao = s;
    setGrupos(g);
    HabilitaBotao(g);
    CalculaTotal(g);
  }

  function HabilitaBotao(grp) {
    let bloquear = false;

    grp.map((item) => {
      if (item.ind_obrigatorio === "S" && item.selecao.length === 0) {
        bloquear = true;
      }
    });

    setBloquearBtn(bloquear);
  }

  // Calculo da pizza, volta aqui

  function CalculaTotal(grp) {
    if (tipo === "E") {
      let vl_selecao = 0;
      let vl_prod = vl_promocao > 0 ? vl_promocao : vl_produto;

      let quantidade = 0;

      grp.map((item) => {
        item.selecao.map((sel) => {
          //vl_selecao = vl_selecao + sel.vl_item;
          if (sel.quantidade != undefined) {
            vl_selecao += sel.vl_item;
          } else {
            vl_selecao += sel.vl_item;
          }
        });
      });

      let vlTotal = 0.0;

      for (const pizza of itemsCart) {
        vlTotal += (pizza.vl_unit * pizza.quantity) / quantity_flavors;
        setTotal(vl_selecao + vlTotal * qtd);
      }

      if (quantidade > 0) {
        setTotal((vl_selecao + vl_prod + vlTotal) * quantidade);
      } else {
        setTotal((vl_selecao + vl_prod + vlTotal) * qtd);
      }
    } else {
      let vl_selecao = 0;
      let vl_prod = vl_promocao > 0 ? vl_promocao : vl_produto;

      let quantidade = 0;

      grp.map((item) => {
        item.selecao.map((sel) => {
          //vl_selecao = vl_selecao + sel.vl_item;
          if (sel.quantidade != undefined) {
            vl_selecao += sel.vl_item * sel.quantidade;
          } else {
            vl_selecao += sel.vl_item;
          }
        });
      });

      if (quantidade > 0) {

        setTotal((vl_selecao + vl_prod) * quantidade);
      } else {
        setTotal((vl_selecao + vl_prod) * qtd);
      }
    }
  }

  useEffect(() => {
    CalculaTotal(grupos);
  }, [qtd]);

  function RemoverCheckbox(isChecked, op) {
    let i = ingredientes;
    let s = [];

    // Descobrir o indice do grupo clicado...
    let objIndex = i.findIndex((obj) => obj.id_opcao === op.id_opcao);

    // Extrai os itens selecionados...
    s = i[objIndex].selecao;

    // Verfificar se deve inserir ou remover um item...
    if (isChecked) {
      s.push(op);
    } else {
      let objIndexSel = s.findIndex((obj) => obj.id_item === op.id_item);
      s.splice(objIndexSel, 1);
    }

    i[objIndex].selecao = s;
    setIngredientes(i);
  }

  const [activeStep, setActiveStep] = useState(0);
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    handleScrollToTop()
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    handleScrollToTop()
  };

  const handleReset = () => {
    setActiveStep(0);
    setObservacao("");
    setValue(0);
    setItensI([]);

    let prods = produtos;
    for (const prod of prods) {
      prod.quantidade = 0;
    }
    setProdutos(prods);

    setVerificarBloquear(true);
  };

  const handleObservacao = (e) => {
    setObservacao(e.target.value);
  };

  function handleProductOnCart(produto) {
    let idProdutos = itensI.map(({ id_produto }) => id_produto);

    if (idProdutos.includes(produto.id_produto)) {
      return `${localStorage.getItem("sessionMode") === "light"
        ? "bg-success"
        : "bg-secondary"
        } bg-opacity-10`;
    } else {
      return "";
    }
  }

  {
    /* Cesar - 05/10/2022 */
  }

  function handlePizzaOnCart(pizza) {
    let idProdutos = itemsCart.map(({ id_produto }) => id_produto);

    if (idProdutos.includes(pizza.id_produto)) {
      return `${localStorage.getItem("sessionMode") === "light"
        ? "bg-success"
        : "bg-secondary"
        } bg-opacity-10`;
    } else {
      return "";
    }
  }

  let pizzas = [];

  // let quantity = 0

  // valida pelo tamanho e id_grade
  for (let product of produtos) {
    if (
      product.id_produto !== id_produto &&
      product.tamanho == tamanho &&
      product.id_grade == id_grade
    ) {
      pizzas.push(product);
    }
  }

  let salty = [];
  let candy = [];

  const TP_SALGADO = "S";
  const TP_DOCE = "D";

  for (let pizza of pizzas) {
    pizza.quantity = 0;
    pizza.vlTotal = 0;
    if (String(pizza.tp_sabor) === TP_SALGADO) {
      salty.push(pizza);
    }
    if (String(pizza.tp_sabor) === TP_DOCE) {
      candy.push(pizza);
    }
  }

  function handleCheckboxQuanitityFlavors(event) {
    // let { itemsCart } = this.state;

    let quantity_flavors = event.target.value;

    let quantitys = 0;
    for (let product of itemsCart) {
      quantitys = parseInt(quantitys) + parseInt(product.quantity);
    }

    if (parseInt(quantitys) > parseInt(quantity_flavors)) {
      toast.error(
        "Remova itens do carrinho para poder diminuir a quantidade de sabores"
      );
      return;
    }

    setQuantityFlavors(quantity_flavors);

    // Cesar - 26/10/2022
    // let vlTotal = 0.0;

    // for (const pizza of itemsCart) {
    //     vlTotal += pizza.vl_unit * pizza.quantity
    //     setTotal(vlTotal / quantity_flavors);
    // }


    let vl_selecao = 0;
    let vl_prod = vl_promocao > 0 ? vl_promocao : vl_produto;

    let quantidade = 0;

    grupos.map((item) => {
      item.selecao.map((sel) => {
        //vl_selecao = vl_selecao + sel.vl_item;

        if (sel.quantidade != undefined) {
          vl_selecao += sel.vl_item;
        } else {
          vl_selecao += sel.vl_item;
        }
      });
    });

    if (quantidade > 0) {
      setTotal((vl_selecao + vl_prod) * qtd);
    } else {
      setTotal((vl_selecao + vl_prod) * qtd);
    }

    let vlTotal = 0.0;

    for (const pizza of itemsCart) {
      vlTotal += (pizza.vl_unit * pizza.quantity) / quantity_flavors;
      setTotal((vl_selecao + vl_prod + vlTotal) * qtd);
    }
  }

  const AmountOfFlavors = () => {
    // let { quantity_flavors, amount_of_flavors } = this.state;

    let rows = [];



    for (let i = 1; i <= amount_of_flavors; i++) {
      rows.push(
        <label
          key={i}
          className="form-check form-check-inline"
          htmlFor={`id-${i}`}
          style={{ cursor: "pointer" }}
        >
          <input
            checked={quantity_flavors == i}
            className="form-check-input"
            type="radio"
            name="quantity_flavors"
            id={`id-${i}`}
            value={i}
            onChange={(i) => handleCheckboxQuanitityFlavors(i)}
            style={{ cursor: "pointer" }}
          />
          <span className="form-check-label">{i}</span>
        </label>
      );
    }

    return rows;
  };

  function handleAddProduct(pizza) {
    // let { quantity_flavors, itemsCart } = this.state;

    let arrayTemp = itemsCart;
    let quantitys = 0;
    for (let product of itemsCart) {
      quantitys = parseInt(quantitys) + parseInt(product.quantity);
    }

    if (quantity_flavors == 0) {
      toast.error("Selecione a quantidade de sabores");
      return;
    }

    if (parseInt(quantitys) >= parseInt(quantity_flavors)) {
      toast.error("Já foi selecionada a quantidade máxima de sabor(es)");
      return;
    }

    let idProdutos = itemsCart.map(({ id_produto }) => id_produto);
    let qtd = 1;

    if (idProdutos.includes(pizza.id_produto)) {
      let temp = itemsCart;

      for (let index = 0; index < itemsCart.length; index++) {
        //Percorre o itemsCart com base no index
        let product = itemsCart[index]; //Cria um objeto copiando o cara que está no index atual
        if (product.id_produto == pizza.id_produto) {
          //Vê se é esse mesmo que precisamos alterar
          // product.vlTotal = parseFloat(product.vlTotal) + parseFloat(pizza.vl_produto); //Altera
          // product.quantity = parseInt(product.quantity) + 1; //Altera
          product.quantity += 1;
          product.vlTotal += product.vl_total;
          qtd = product.quantity;
        }

        //Agora vamos jogar esse cara que alteramos, para nossa variável principal
        temp[index] = product; //Trabalhamos lá em cima sobre a itemsCart, mas como a temp é igual, podemos tratar ela só aqui no final
      }

      setItemsCart(temp);
    } else {
      let pizzaTemp = {
        categoria: pizza.categoria,
        nome: pizza.nome,
        descricao: pizza.descricao,
        id_grade: pizza.id_grade,
        id_produto: pizza.id_produto,
        vl_produto: pizza.vl_produto,
        vl_total:
          pizza.vl_promocao > 0 ? pizza.vl_promocao : pizza.vl_produto * qtd,
        vl_unit: pizza.vl_promocao > 0 ? pizza.vl_promocao : pizza.vl_produto,
      };

      pizzaTemp.vlTotal = pizzaTemp.vl_produto;
      pizzaTemp.quantity = 1;

      let temp = itemsCart; //clonando só porque não tem como alterar direto o itemsCart

      temp.push(pizzaTemp); //Altera o cara que é alterável

      setItemsCart(temp); //Única forma de alterar o itemsCart é pelo set, então vamos jogar seu clone lá
    }

    //Atualizar a quantidade mostrada entre o mais e menos
    let pizzasTemp = pizzas;
    let index = 0;
    //encontrando o item clicado no json geral
    while (pizzas[index].id_produto != pizza.id_produto) {
      index++;
    }
    pizzasTemp[index].quantidade = qtd;

    let vlTotal = 0.0;


    // 0 = Soma todos os valores
    // 1 = Pega o maior valor



    if (tpValor == 0) {
      for (const pizza of arrayTemp) {

        vlTotal += ((pizza.vl_produto / quantity_flavors) * pizza.quantity);

      }
    }

    if (tpValor == 1) {


      let maiorValor = 0
      let quantidadePizza = 0

      for (const pizza of arrayTemp) {

        if (pizza.vl_unit > maiorValor) {
          maiorValor = pizza.vl_unit
        }

        quantidadePizza += pizza.quantity

        vlTotal = (maiorValor / parseInt(quantity_flavors)) * quantidadePizza

        //
      }
    }


    setTotal(vlTotal);
  }

  function handleRemoveProduct(pizza) {
    // let { itemsCart } = this.state;

    let arrayTemp = itemsCart;

    let quantitys = 0;
    let qtd = 0;

    for (let product of itemsCart) {
      if (product.id_produto == pizza.id_produto) {
        quantitys = parseInt(quantitys) + parseInt(product.quantity);
      }
    }

    if (parseInt(quantitys) == 1) {
      pizza.vlTotal = 0;
      pizza.quantity = 0;
      itemsCart.splice(itemsCart.indexOf(pizza), 1);

      setItemsCart(itemsCart);
    } else {
      for (let product of itemsCart) {

        if (product.id_produto == pizza.id_produto) {

          product.vlTotal = parseFloat(product.vlTotal) - parseFloat(pizza.vl_produto);
          product.quantity = parseInt(product.quantity) - 1;
          qtd = product.quantity;
        }
      }
      setItemsCart(itemsCart);
    }

    //Atualizar a quantidade mostrada entre o mais e menos
    let pizzasTemp = pizzas;
    let index = 0;

    //encontrando o item clicado no json geral
    while (pizzas[index].id_produto != pizza.id_produto) {
      index++;
    }
    pizzasTemp[index].quantidade = qtd;

    let vlTotal = 0.0;


    // for (const pizza of arrayTemp) {
    //   if(!!pizza){
    //   vlTotal += (pizza.vl_unit * pizza.quantity) / quantity_flavors;
    //   }
    // }
    if (tpValor == 0) {
      for (const pizza of arrayTemp) {

        vlTotal += ((pizza.vl_produto / quantity_flavors) * pizza.quantity);

      }
    }

    if (tpValor == 1) {


      let maiorValor = 0
      let quantidadePizza = 0

      for (const pizza of arrayTemp) {

        if (pizza.vl_unit > maiorValor) {
          maiorValor = pizza.vl_unit
        }

        quantidadePizza += pizza.quantity

        vlTotal = (maiorValor / parseInt(quantity_flavors)) * quantidadePizza

        //
      }
    }


    setTotal(vlTotal);
  }

  function SelecionaBorda(op) {
    let g = grupos;

    // Descobrir o indice do grupo clicado...
    let objIndex = g.findIndex((obj) => obj.id_opcao == op.id_opcao);

    // Atualizar informacao do item naquele indice...


    if (grupos && op && objIndex === 0) {

      g[0]["selecao"] = [op]

    }
    if (grupos && op && objIndex === -1) {
  

      g[0]["selecao"] = []
    }


    setGrupos(g);
    HabilitaBotao(g);
    // CalculaTotal(g);

    let vl_selecao = 0;
    let vl_prod = vl_promocao > 0 ? vl_promocao : vl_produto;

    let quantidade = 0;

    g.map((item) => {
      item.selecao.map((sel) => {
        //vl_selecao = vl_selecao + sel.vl_item;
        if (sel.quantidade != undefined) {
          vl_selecao += sel.vl_item;
        } else {
          vl_selecao += sel.vl_item;
        }
      });
    });

    if (quantidade > 0) {
      setTotal((vl_selecao + vl_prod) * qtd);


    } else {
      setTotal((vl_selecao + vl_prod) * qtd);
    }

    let vlTotal = 0.0;

    // for (const pizza of itemsCart) {
    //   vlTotal += (pizza.vl_unit * pizza.quantity) / quantity_flavors;
    //   setTotal((vl_selecao + vl_prod + vlTotal) * qtd);
    // }

    if (tpValor == 0) {
      for (const pizza of itemsCart) {

        vlTotal += (((pizza.vl_produto / quantity_flavors) * pizza.quantity)  + vl_selecao );
        setTotal(((pizza.vl_produto / quantity_flavors) * pizza.quantity) + vl_selecao);
      }
    }

    if (tpValor == 1) {


      let maiorValor = 0
      let quantidadePizza = 0

      for (const pizza of itemsCart) {

        if (pizza.vl_unit > maiorValor) {
          maiorValor = pizza.vl_unit
        }

        quantidadePizza += pizza.quantity

        vlTotal = ((maiorValor / parseInt(quantity_flavors)) * quantidadePizza) + vl_selecao
        setTotal( ((maiorValor / parseInt(quantity_flavors)) * quantidadePizza) + vl_selecao );


        //
      }
    }

  }

  function ClickMaisPizza() {
    setQtd(qtd + 1);
  }

  function ClickMenosPizza() {
    qtd > 1 ? setQtd(qtd - 1) : setQtd(1);
  }

  function handleAddPizzas() {
    if (itemsCart.length == 0) {
      toast.error("Selecione o(s) sabor(es)");
      return;
    }

    let pizzas = [];

    let quantity = 0;
    let total = 0;

    for (let itemCart of itemsCart) {
      pizzas.push(itemsCart);
      quantity = parseInt(quantity) + parseInt(itemCart.quantity);
      total = parseFloat(total) + parseFloat(itemCart.vlTotal);
    }

    if (quantity != quantity_flavors) {
      toast.error(
        "Selecione o(s) sabor(es) de acordo com a quantidade selecionada"
      );
      return;
    }

    // ADICIONANDO AO CARRINHO...

    let detalhes = [];
    let vl_detalhes = 0;

    let bordas = [];
    let vl_bordas = 0;

    itemsCart.map((item) => {
      // let valor = (item.vl_unit * item.quantity) / quantity_flavors;
      // vl_detalhes += valor;

      if (tpValor == 0) {
  
          vl_detalhes += ((item.vl_produto / quantity_flavors) * item.quantity);
        
      }

      if (tpValor == 1) {
  
        let maiorValor = 0
        let quantidadePizza = 0
  
          if (item.vl_unit > maiorValor) {
            maiorValor = item.vl_unit
          }
  
          quantidadePizza += item.quantity
  
          vl_detalhes = ((maiorValor / parseInt(quantity_flavors)) * quantidadePizza) *  parseInt(quantity_flavors)
  
        
      }


      detalhes.push({
        nome: item.nome,
        descricao: item.descricao,
        id_item: item.id_produto,
        vl_item: item.vl_unit,
        quantidade: item.quantity,
        ordem: item.id_produto,
      });
    });

    grupos.map((item) => {
      item.selecao.map((sel) => {
        let valor = sel.vl_item;
        vl_bordas += valor;

        bordas.push({
          nome: sel.nome,
          vl_item: sel.vl_item,
        });
      });
    });

    const item = {
      descricao: descricao ? descricao : nome,
      detalhes: detalhes,
      id_carrinho: uuidv4(),
      id_produto: id_produto,
      nome: nome,
      observacao: observacao,
      qtd: qtd,
      bordas: bordas,
      tipo: tipo,
      // url_foto: url_foto,
      vl_total: vl_detalhes + vl_promocao > 0 ? vl_promocao : vl_produto * qtd,
      vl_unit:
        vl_detalhes + vl_bordas + (vl_promocao > 0 ? vl_promocao : vl_produto),
    };

    // setTotal(total + vl_bordas / quantity_flavors)

    toast.success(`${nome} foi adicionada na sacola`);

    AddItemCart(item);

    props.onRequestClose();

    handleReset();
    navigate("/checkout/carrinho");


  }

  function FinalizarPedido() {
    navigate("/checkout/carrinho");
  }

  return (
    <>
      <Modal
        id="ads"
        show={props.isOpen}
        onHide={() => {
          props.onRequestClose();
          handleReset();
          setItensI([]);
        }}
        scrollable={true}
        centered="true"
        fullscreen={isMobile ? true : false}
      >
        {loading ? (
          <>
            <div className="h-100 px-1 py-5" >
              <div className="d-flex flex-column justify-content-center align-items-center">
                <div className="d-flex relative">
                  <div className="d-flex flex-column justify-content-center align-items-center">
                    <CircularProgress
                      sx={{
                        color: `${localStorage.getItem("sessionMode") === "light"
                          ? "#dc3545"
                          : "#6c757d"
                          }`,
                      }}
                    />
                    <h6
                      className={`mt-3 mb-0 ${localStorage.getItem("sessionMode") === "light"
                        ? "text-danger"
                        : "text-secondary"
                        }`}
                    >
                      Carregando
                    </h6>
                    <div style={{ position: "absolute", top: 0, right: 0 }}>
                      <button
                        onClick={() => {
                          props.onRequestClose();
                          handleReset();
                        }}
                        className="btn btn-sm btn-link text-danger btn-fechar"
                      >
                        <CloseIcon style={{ fontSize: 32 }} />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            {/* Caso o email seja do visitante, conferi se o estabelecimento requer registro no site para pedidos  */}

            {localStorage.getItem("sessionEmail") ===
              "visitante@comeraqui.com.br" && props.loginObrigatorio === 1 ? (
              <div className="p-3 relative">
                <FormLogin />
                <div style={{ position: "absolute", top: "0", right: "0" }}>
                  <button
                    onClick={() => {
                      props.onRequestClose();
                      handleReset();
                    }}
                    className="btn btn-sm btn-link text-danger btn-fechar"
                  >
                    <CloseIcon style={{ fontSize: 32 }} />
                  </button>
                </div>
              </div>
            ) : (
              <>
                <Modal.Header
                  className={`${tipo === "E" && "pizza"} ${tipo === "G" && "grade"
                    }`}
                  style={{
                    position: "relative",
                    alignItems: "inherit",
                    borderBottom: `${activeStep === 0 && tipo === "G"
                      ? "1px solid #dee2e6"
                      : "none"
                      }`,
                  }}
                >
                  <Modal.Title
                    style={{
                      width: "100%",
                      fontSize: "inherit",
                      fontWeight: "inherit",
                      alignItems: "inherit",
                    }}
                  >
                    <div className="d-flex align-items-center w-100">
                      {tipo !== "G" && (
                        <>
                          {url_foto && (
                            <div
                              className="me-3"
                              style={{
                                width: 100,
                                height: 100,
                                overflow: "hidden",
                              }}
                            >
                              <img
                                className="img-fluid rounded img-produto-modal"
                                src={url_foto}
                                alt="Produto"
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover",
                                }}
                              />
                            </div>
                          )}
                        </>
                      )}

                      <div className="flex flex-column cursor-default w-100">
                        <div className="d-flex justify-content-between">
                          <div>
                            <div className={`${tipo === "G" ? "mt-3" : ""}`}>
                              <h4 className="m-0">{nome}</h4>
                              <small>{descricao}</small>
                            </div>

                            <div>
                              {vl_promocao > 0 ? (
                                <>
                                  <small className="mt-3 text-success">
                                    {new Intl.NumberFormat("pt-BR", {
                                      style: "currency",
                                      currency: "BRL",
                                    }).format(vl_promocao)}
                                  </small>
                                  <small className="ms-4 mt-3 text-secondary">
                                    {new Intl.NumberFormat("pt-BR", {
                                      style: "currency",
                                      currency: "BRL",
                                    }).format(vl_produto)}
                                  </small>
                                </>
                              ) : (
                                <>
                                  {vl_produto && (
                                    <small>
                                      {new Intl.NumberFormat("pt-BR", {
                                        style: "currency",
                                        currency: "BRL",
                                      }).format(vl_produto)}
                                    </small>
                                  )}
                                </>
                              )}
                            </div>
                          </div>

                          <div
                            className="d-flex flex-column"
                            style={{ height: "auto" }}
                          >
                            <button
                              onClick={() => {
                                props.onRequestClose();
                                handleReset();
                              }}
                              className="btn btn-sm btn-link text-danger btn-fechar pe-0"
                            >
                              <CloseIcon style={{ fontSize: 32 }} />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Modal.Title>
                </Modal.Header>

                <Modal.Body className="p-0"  ref={modalBodyRef}>
  
                  {tipo === "N" && (
                    <Stepper
                      activeStep={activeStep}
                      orientation="vertical"
                      sx={{ height: "100vh" }}
                    >
                      {ingredientes.length > 0 && (
                        <Step key="ingrediente">
                          <StepContent
                            TransitionProps={{ unmountOnExit: false }}
                          >
                            <div className="card rounded-0 border-0">
                              <div className="card-header d-flex justify-content-between">
                                <div className="d-flex flex-column">
                                  <span className="text-uppercase">
                                    <b>Retirar Ingredientes</b>
                                  </span>
                                  <span
                                    className="badge bg-secondary text-uppercase"
                                    style={{
                                      lineHeight: "inherit",
                                      padding: ".17em .32em",
                                    }}
                                  >
                                    Opcional
                                  </span>
                                </div>
                              </div>
                              <ul className="list-group list-group-flush">
                                {ingredientes.map((ingrediente) => (
                                  <label
                                    htmlFor={`ingrediente-${ingrediente.id_ingrediente}`}
                                    key={ingrediente.id_ingrediente}
                                    className={`list-group-item d-flex justify-content-between`}
                                  >
                                    <div>
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value=""
                                        id={`ingrediente-${ingrediente.id_ingrediente}`}
                                        onClick={(e) =>
                                          RemoverCheckbox(e.target.checked, {
                                            id_ingrediente:
                                              ingrediente.id_ingrediente,
                                            descricao: ingrediente.descricao,
                                            ordem: ingrediente.ordem,
                                          })
                                        }
                                      />
                                      <span className="ms-2">
                                        {ingrediente.descricao}
                                      </span>
                                    </div>
                                  </label>
                                ))}
                              </ul>
                            </div>
                          </StepContent>
                        </Step>
                      )}

                      {grupos.length > 0 && (
                        <Step key="grupos">
                          <StepContent
                            TransitionProps={{ unmountOnExit: false }}
                          >
                            {grupos.length > 0 &&
                              grupos.map((grupo) => {
                                let op = opcoes.filter((item, index, arr) => {
                                  return item.id_opcao == grupo.id_opcao;
                                });

                                return (
                                  <div key={grupo.id_opcao}>
                                    {grupo.ind_ativo === "S" &&
                                      grupo.qtd_max_escolha === 1 &&
                                      grupo.qtd_limiteitem === 1 &&
                                      grupo.qtd_minimoitem === 1 && ( // radiobutton
                                        <ProdutoItemRadio
                                          titulo={grupo.nome_opcao}
                                          descricao_opcao={
                                            grupo.descricao_opcao
                                          }
                                          obrigatorio={
                                            grupo.ind_obrigatorio === "S"
                                              ? true
                                              : false
                                          }
                                          opcoes={op}
                                          onClickItem={SelecionaRadioButton}
                                        />
                                      )}
                                    {grupo.ind_ativo === "S" &&
                                      grupo.qtd_max_escolha === null &&
                                      grupo.qtd_minimoitem >= 1 && ( // check
                                        <ProdutoItemCheckbox
                                          titulo={grupo.nome_opcao}
                                          descricao_opcao={
                                            grupo.descricao_opcao
                                          }
                                          obrigatorio={
                                            grupo.ind_obrigatorio === "S"
                                              ? true
                                              : false
                                          }
                                          opcoes={op}
                                          onClickItem={SelecionaCheckbox}
                                        />
                                      )}
                                    {grupo.ind_ativo === "S" &&
                                      grupo.qtd_max_escolha === null &&
                                      grupo.qtd_limiteitem === null &&
                                      grupo.qtd_minimoitem === null && ( // + e -
                                        <ProdutoItemMultiplos
                                          titulo={grupo.nome_opcao}
                                          descricao_opcao={
                                            grupo.descricao_opcao
                                          }
                                          obrigatorio={
                                            grupo.ind_obrigatorio == "S"
                                              ? true
                                              : false
                                          }
                                          opcoes={op}
                                          onClickItem={SelecionaQuantidade}
                                        />
                                      )}
                                  </div>
                                );
                              })}
                          </StepContent>
                        </Step>
                      )}

                      <Step key="observacao">
                        <StepContent TransitionProps={{ unmountOnExit: false }}>
                          <div className="observacao">
                            <div className="col-12">
                              <div className="d-flex align-items-center justify-content-center">
                                <button
                                  onClick={ClickMenos}
                                  className={`btn ${localStorage.getItem("sessionMode") ===
                                    "light"
                                    ? "btn-outline-danger"
                                    : "btn-outline-secondary"
                                    }`}
                                  style={{ borderRadius: "100%" }}
                                >
                                  <i className="fas fa-minus"></i>
                                </button>
                                <span
                                  className={`cursor-default text-center ${localStorage.getItem("sessionMode") ===
                                    "light"
                                    ? ""
                                    : "text-secondary"
                                    }`}
                                  style={{ width: "32px" }}
                                >
                                  {qtd.toLocaleString("pt-BR", {
                                    minimumIntegerDigits: 2,
                                  })}
                                </span>
                                <button
                                  onClick={ClickMais}
                                  className={`btn ${localStorage.getItem("sessionMode") ===
                                    "light"
                                    ? "btn-outline-success"
                                    : "btn-outline-secondary"
                                    }`}
                                  style={{ borderRadius: "100%" }}
                                >
                                  <i className="fas fa-plus"></i>
                                </button>
                              </div>
                            </div>

                            <div className="col-12">
                              <label
                                style={{ marginLeft: 20 }}
                                htmlFor="observacao"
                                className="mb-1"
                              >
                                Observação{" "}
                              </label>
                              <textarea
                                id="observacao"
                                className="form-control"
                                rows={3}
                                value={observacao}
                                placeholder="Digite aqui sua observação..."
                                onChange={handleObservacao}
                              />
                            </div>
                          </div>
                        </StepContent>
                      </Step>
                    </Stepper>
                  )}

                  {/* Produto Pizza */}
                  {tipo === "E" && (
                    <>
                      <Stepper
                        activeStep={activeStep}
                        orientation="vertical"
                        sx={{ height: "100vh" }}
                      >
                        <Step key="sabores">
                          <StepContent
                            TransitionProps={{ unmountOnExit: false }}
                          >


                            <div
                              style={{

                                display: "flex",
                                flexDirection: "row",
                                margin: "15px 0 15px 10px"

                              }}
                            >

                              <DrawPizza quantidade={quantity_flavors} selecionado={itemsCart} />

                              <div style={{ display: "flex", flexDirection: "column" }}>
                                <span className="text-uppercase" style={{ fontSize: 14, whiteSpace: 'nowrap' }} >
                                  quantidade de sabores
                                </span>
                                <div
                                  className="d-flex align-items-center"
                                  style={{
                                    marginTop: 10,
                                    display: "flex",
                                    // justifyContent: "center",
                                    // alignItems: "center",
                                    // marginLeft: 30,
                                    flexWrap: 'wrap',


                                  }}
                                >
                                  <AmountOfFlavors />
                                </div>

                              </div>
                            </div>

                            <div
                              className="d-flex flex-column"
                              style={{ margin: 20 }}
                            >
                              <button
                                onClick={() => setTypeFlavor("SALGADO")}
                                className={`btn ${type_flavor === "SALGADO"
                                  ? "btn-danger active"
                                  : "btn-secondary"
                                  } border-0 rounded-0 w-100 mb-1`}
                                style={{ boxShadow: "none", height: "42px" }}
                              >
                                SALGADO
                              </button>
                              <button
                                onClick={() => setTypeFlavor("DOCE")}
                                className={`btn ${type_flavor === "DOCE"
                                  ? "btn-danger active"
                                  : "btn-secondary"
                                  } border-0 rounded-0 w-100`}
                                style={{ boxShadow: "none", height: "42px" }}
                              >
                                DOCE
                              </button>
                            </div>

                            <div className="container-fluid my-2">
                              <div className="d-flex align-items-center justify-content-between"></div>
                              <div className="w-100 pe-3">
                                {itemsCart.length > 0 && (
                                  <ul className="list-group list-group-flush">
                                    {itemsCart.map((item, index) => (
                                      <li
                                        className="list-group-item list-sabor d-flex align-items-center px-0 py-1 small"
                                        key={`${item.id_produto}-${index}`}
                                      >
                                        {item.quantity}/{quantity_flavors}{" "}
                                        {item.nome}
                                      </li>
                                    ))}
                                  </ul>
                                )}
                              </div>
                            </div>

                            {type_flavor === "SALGADO" && (
                              <>
                                <div
                                  className="card rounded-0"
                                  style={{ borderRight: "none" }}
                                >
                                  <div
                                    className={`card-header d-flex align-items-center justify-content-between ${localStorage.getItem("sessionMode") ===
                                      "light"
                                      ? "border-0"
                                      : ""
                                      } `}
                                  >
                                    <span className="text-uppercase">
                                      <b>SALGADO</b>
                                    </span>
                                  </div>
                                </div>

                                <ul className="list-group list-group-flush">
                                  {salty.map((pizza) => (
                                    <div
                                      key={pizza.id_produto}
                                      className={`list-group-item d-flex align-items-center ${handlePizzaOnCart(
                                        pizza
                                      )}`}
                                    >
                                      {pizza.url_foto && (
                                        <div
                                          className="me-3"
                                          style={{
                                            width: "120px",
                                            height: "130px",
                                            overflow: "hidden",
                                            borderRadius: 5,
                                            flex: "none", // Adicionado flex: "none" para evitar que o item se estenda
                                          }}
                                        >
                                          <img
                                            src={pizza.url_foto}
                                            alt=""
                                            style={{
                                              width: "100%",
                                              height: "100%",
                                              objectFit: "cover",
                                            }}
                                          />
                                        </div>
                                      )}

                                      <div className="d-flex flex-column cursor-default pe-3">
                                        <div>
                                          <b>{pizza.nome}</b>
                                        </div>
                                        <small className="my-1">
                                          {pizza.descricao}
                                        </small>
                                        {pizza.vl_produto > 0 ? (
                                          <span
                                            className={`${localStorage.getItem(
                                              "sessionMode"
                                            ) === "light"
                                              ? "text-danger"
                                              : "text-secondary"
                                              }`}
                                          >
                                            {new Intl.NumberFormat("pt-BR", {
                                              style: "currency",
                                              currency: "BRL",
                                            }).format(pizza.vl_produto)}
                                          </span>
                                        ) : null}
                                      </div>

                                      <div className="ms-auto">
                                        <div className="d-flex flex-column align-items-center">
                                          <button
                                            onClick={() =>
                                              handleAddProduct(pizza)
                                            }
                                            className={`btn ${localStorage.getItem(
                                              "sessionMode"
                                            ) === "light"
                                              ? "btn-outline-success"
                                              : "btn-outline-secondary"
                                              }`}
                                            style={{ borderRadius: "100%" }}
                                          >
                                            <i className="fas fa-plus"></i>
                                          </button>

                                          <span
                                            className={`d-flex align-items-center cursor-default text-center ${localStorage.getItem(
                                              "sessionMode"
                                            ) === "light"
                                              ? ""
                                              : "text-secondary"
                                              }`}
                                            style={{ height: "32px" }}
                                          >
                                            {pizza.quantidade
                                              ? pizza.quantidade
                                              : 0}
                                          </span>

                                          <button
                                            onClick={() =>
                                              handleRemoveProduct(pizza)
                                            }
                                            className={`btn ${localStorage.getItem(
                                              "sessionMode"
                                            ) === "light"
                                              ? "btn-outline-danger"
                                              : "btn-outline-secondary"
                                              }`}
                                            style={{ borderRadius: "100%" }}
                                          >
                                            <i className="fas fa-minus"></i>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </ul>
                              </>
                            )}

                            {type_flavor === "DOCE" && (
                              <>
                                <div
                                  className="card rounded-0"
                                  style={{ borderRight: "none" }}
                                >
                                  <div
                                    className={`card-header d-flex align-items-center justify-content-between ${localStorage.getItem("sessionMode") ===
                                      "light"
                                      ? "border-0"
                                      : ""
                                      } `}
                                  >
                                    <span className="text-uppercase">
                                      <b>DOCE</b>
                                    </span>
                                  </div>
                                </div>

                                <ul className="list-group list-group-flush">
                                  {candy.map((pizza) => (
                                    <div
                                      key={pizza.id_produto}
                                      className={`list-group-item d-flex align-items-center ${handlePizzaOnCart(
                                        pizza
                                      )}`}
                                    >
                                      {pizza.url_foto && (
                                        <div
                                          className="me-3"
                                          style={{
                                            width: "120px",
                                            height: "130px",
                                            overflow: "hidden",
                                            borderRadius: 5,
                                            flex: "none", // Adicionado flex: "none" para evitar que o item se estenda
                                          }}
                                        >
                                          <img
                                            src={pizza.url_foto}
                                            alt=""
                                            style={{
                                              width: "100%",
                                              height: "100%",
                                              objectFit: "contain",
                                            }}
                                          />
                                        </div>
                                      )}

                                      <div className="d-flex flex-column cursor-default pe-3">
                                        <div>
                                          <b>{pizza.nome}</b>
                                        </div>
                                        <small className="my-1">
                                          {pizza.descricao}
                                        </small>
                                        {pizza.vl_produto > 0 ? (
                                          <span
                                            className={`${localStorage.getItem(
                                              "sessionMode"
                                            ) === "light"
                                              ? "text-danger"
                                              : "text-secondary"
                                              }`}
                                          >
                                            {new Intl.NumberFormat("pt-BR", {
                                              style: "currency",
                                              currency: "BRL",
                                            }).format(pizza.vl_produto)}
                                          </span>
                                        ) : null}
                                      </div>

                                      <div className="ms-auto">
                                        <div className="d-flex flex-column align-items-center">
                                          <button
                                            onClick={() =>
                                              handleAddProduct(pizza)
                                            }
                                            className={`btn ${localStorage.getItem(
                                              "sessionMode"
                                            ) === "light"
                                              ? "btn-outline-success"
                                              : "btn-outline-secondary"
                                              }`}
                                            style={{ borderRadius: "100%" }}
                                          >
                                            <i className="fas fa-plus"></i>
                                          </button>

                                          <span
                                            className={`d-flex align-items-center cursor-default text-center ${localStorage.getItem(
                                              "sessionMode"
                                            ) === "light"
                                              ? ""
                                              : "text-secondary"
                                              }`}
                                            style={{ height: "32px" }}
                                          >
                                            {pizza.quantidade
                                              ? pizza.quantidade
                                              : 0}
                                          </span>

                                          <button
                                            onClick={() =>
                                              handleRemoveProduct(pizza)
                                            }
                                            className={`btn ${localStorage.getItem(
                                              "sessionMode"
                                            ) === "light"
                                              ? "btn-outline-danger"
                                              : "btn-outline-secondary"
                                              }`}
                                            style={{ borderRadius: "100%" }}
                                          >
                                            <i className="fas fa-minus"></i>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </ul>
                              </>
                            )}
                          </StepContent>
                        </Step>

                        {grupos.length && (
                          <Step key="grupos">
                            <StepContent
                              TransitionProps={{ unmountOnExit: false }}
                            >
                              {grupos.length > 0 &&
                                grupos.map((grupo) => {
                                  let op = opcoes.filter((item, index, arr) => {
                                    return item.id_opcao == grupo.id_opcao;
                                  });
                                  return (
                                    <div key={grupo.id_opcao}>
                                      {grupo.ind_ativo === "S" &&
                                        grupo.qtd_max_escolha === 1 &&
                                        grupo.qtd_limiteitem === 1 &&
                                        grupo.qtd_minimoitem === 1 && ( // radiobutton
                                          <ProdutoItemRadio
                                            titulo={grupo.nome_opcao}
                                            descricao_opcao={
                                              grupo.descricao_opcao
                                            }
                                            obrigatorio={
                                              grupo.ind_obrigatorio === "S"
                                                ? true
                                                : false
                                            }
                                            opcoes={op}
                                            onClickItem={SelecionaBorda}
                                          />
                                        )}
                                      {grupo.ind_ativo === "S" &&
                                        grupo.qtd_max_escolha === null &&
                                        grupo.qtd_minimoitem >= 1 && ( // check
                                          <ProdutoItemCheckbox
                                            titulo={grupo.nome_opcao}
                                            descricao_opcao={
                                              grupo.descricao_opcao
                                            }
                                            obrigatorio={
                                              grupo.ind_obrigatorio === "S"
                                                ? true
                                                : false
                                            }
                                            opcoes={op}
                                            onClickItem={SelecionaCheckbox}
                                          />
                                        )}
                                      {grupo.ind_ativo === "S" &&
                                        grupo.qtd_max_escolha === null &&
                                        grupo.qtd_limiteitem === null &&
                                        grupo.qtd_minimoitem === null && ( // + e -
                                          <ProdutoItemMultiplos
                                            titulo={grupo.nome_opcao}
                                            descricao_opcao={
                                              grupo.descricao_opcao
                                            }
                                            obrigatorio={
                                              grupo.ind_obrigatorio == "S"
                                                ? true
                                                : false
                                            }
                                            opcoes={op}
                                            onClickItem={SelecionaQuantidade}
                                            tipo={grupo.tipo}
                                          />
                                        )}
                                    </div>
                                  );
                                })}
                            </StepContent>
                          </Step>
                        )}

                        <Step key="observacao">
                          <StepContent
                            TransitionProps={{ unmountOnExit: false }}
                          >
                            <div className="observacao">
                              <div className="col-12">
                                <div className="d-flex align-items-center justify-content-center">
                                  <button
                                    onClick={ClickMenosPizza}
                                    className={`btn ${localStorage.getItem("sessionMode") ===
                                      "light"
                                      ? "btn-outline-danger"
                                      : "btn-outline-secondary"
                                      }`}
                                    style={{ borderRadius: "100%" }}
                                  >
                                    <i className="fas fa-minus"></i>
                                  </button>
                                  <span
                                    className={`cursor-default text-center ${localStorage.getItem("sessionMode") ===
                                      "light"
                                      ? ""
                                      : "text-secondary"
                                      }`}
                                    style={{ width: "32px" }}
                                  >
                                    {" "}
                                    {qtd.toLocaleString("pt-BR", {
                                      minimumIntegerDigits: 2,
                                    })}
                                  </span>
                                  <button
                                    onClick={ClickMaisPizza}
                                    className={`btn ${localStorage.getItem("sessionMode") ===
                                      "light"
                                      ? "btn-outline-success"
                                      : "btn-outline-secondary"
                                      }`}
                                    style={{ borderRadius: "100%" }}
                                  >
                                    <i className="fas fa-plus"></i>
                                  </button>
                                </div>
                              </div>

                              <div className="col-12">
                                <label
                                  htmlFor="observacao"
                                  className="mb-1"
                                  style={{ marginLeft: 10 }}
                                >
                                  Observação
                                </label>
                                <textarea
                                  id="observacao"
                                  className="form-control"
                                  rows={3}
                                  value={observacao}
                                  placeholder="Digite aqui sua observação..."
                                  onChange={handleObservacao}
                                />
                              </div>
                            </div>
                          </StepContent>
                        </Step>
                      </Stepper>
                    </>
                  )}

                  {/* Produto Tipo Grade */}
                  {tipo === "G" && (
                    <>
                      <ul
                        className="list-group list-group-flush"
                        style={{ height: "100vh" }}
                      >
                        {produtos.map((produto) => {
                          return (
                            produto.id_produto !== id_produto &&
                            produto.id_grade == id_grade && (
                              <div
                                key={produto.id_produto}
                                className={`list-group-item d-flex align-items-center ${handleProductOnCart(
                                  produto
                                )}`}
                              >
                                {produto.url_foto && (
                                  <div
                                    className="me-2"
                                    style={{
                                      width: 100,
                                      height: 100,
                                      overflow: "hidden",
                                    }}
                                  >
                                    <img
                                      src={produto.url_foto}
                                      alt=""
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "contain",
                                      }}
                                    />
                                  </div>
                                )}

                                <div className="d-flex flex-column cursor-default">
                                  <div>{produto.nome}</div>
                                  {produto.vl_produto > 0 ? (
                                    <span
                                      className={`${localStorage.getItem("sessionMode") ===
                                        "light"
                                        ? "text-success"
                                        : "text-secondary"
                                        }`}
                                    >
                                      {new Intl.NumberFormat("pt-BR", {
                                        style: "currency",
                                        currency: "BRL",
                                      }).format(produto.vl_produto)}
                                    </span>
                                  ) : null}
                                </div>

                                <div className="ms-auto">
                                  <div className="d-flex align-items-center justify-content-center">
                                    <button
                                      onClick={(e) =>
                                        SelecionaProduto(produto, "del")
                                      }
                                      className={`btn ${localStorage.getItem("sessionMode") ===
                                        "light"
                                        ? "btn-outline-danger"
                                        : "btn-outline-secondary"
                                        }`}
                                      style={{ borderRadius: "100%" }}
                                    >
                                      <i className="fas fa-minus" />
                                    </button>
                                    <span
                                      className={`cursor-default text-center ${localStorage.getItem("sessionMode") ===
                                        "light"
                                        ? ""
                                        : "text-secondary"
                                        }`}
                                      style={{ width: "32px" }}
                                    >
                                      {produto.quantidade
                                        ? produto.quantidade
                                        : 0}
                                    </span>
                                    <button
                                      onClick={(e) =>
                                        SelecionaProduto(produto, "add")
                                      }
                                      className={`btn ${localStorage.getItem("sessionMode") ===
                                        "light"
                                        ? "btn-outline-success"
                                        : "btn-outline-secondary"
                                        }`}
                                      style={{ borderRadius: "100%" }}
                                    >
                                      <i className="fas fa-plus" />
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )
                          );
                        })}
                      </ul>
                    </>
                  )}

                  {/* Produto Item da Grade */}
                  {tipo === "I" && (
                    <Stepper
                      activeStep={activeStep}
                      orientation="vertical"
                      style={{ height: "100vh" }}
                    >
                      {ingredientes.length && (
                        <Step key="ingrediente">
                          <StepContent
                            TransitionProps={{ unmountOnExit: false }}
                          >
                            <div className="card rounded-0 border-start-0 border-end-0">
                              <div className="card-header d-flex align-items-center justify-content-between">
                                <span className="text-uppercase">
                                  <b>Retirar Ingredientes</b>
                                </span>
                                <div className="ms-auto">
                                  <small
                                    className="badge bg-secondary"
                                    style={{
                                      lineHeight: "inherit",
                                      padding: ".17em .32em",
                                    }}
                                  >
                                    OPCIONAL
                                  </small>
                                </div>
                              </div>
                              <ul className="list-group list-group-flush">
                                {ingredientes.map((ingrediente) => (
                                  <label
                                    htmlFor={`ingrediente-${ingrediente.id_ingrediente}`}
                                    key={ingrediente.id_ingrediente}
                                    className={`list-group-item d-flex justify-content-between`}
                                  >
                                    <div>
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value=""
                                        id={`ingrediente-${ingrediente.id_ingrediente}`}
                                        onClick={(e) =>
                                          RemoverCheckbox(e.target.checked, {
                                            id_ingrediente:
                                              ingrediente.id_ingrediente,
                                            descricao: ingrediente.descricao,
                                            ordem: ingrediente.ordem,
                                          })
                                        }
                                      />
                                      <span className="ms-2">
                                        {ingrediente.descricao}
                                      </span>
                                    </div>
                                  </label>
                                ))}
                              </ul>
                            </div>
                          </StepContent>
                        </Step>
                      )}

                      {grupos.length && (
                        <Step key="grupos">
                          <StepContent
                            TransitionProps={{ unmountOnExit: false }}
                          >
                            {grupos.length > 0 &&
                              grupos.map((grupo) => {
                                let op = opcoes.filter((item, index, arr) => {
                                  return item.id_opcao == grupo.id_opcao;
                                });
                                return (
                                  <div key={grupo.id_opcao}>
                                    {grupo.ind_ativo === "S" &&
                                      grupo.qtd_max_escolha === 1 &&
                                      grupo.qtd_limiteitem === 1 &&
                                      grupo.qtd_minimoitem === 1 && ( // radiobutton
                                        <ProdutoItemRadio
                                          titulo={grupo.nome_opcao}
                                          descricao_opcao={
                                            grupo.descricao_opcao
                                          }
                                          obrigatorio={
                                            grupo.ind_obrigatorio === "S"
                                              ? true
                                              : false
                                          }
                                          opcoes={op}
                                          onClickItem={SelecionaRadioButton}
                                        />
                                      )}
                                    {grupo.ind_ativo === "S" &&
                                      grupo.qtd_max_escolha === null &&
                                      grupo.qtd_minimoitem >= 1 && ( // check
                                        <ProdutoItemCheckbox
                                          titulo={grupo.nome_opcao}
                                          descricao_opcao={
                                            grupo.descricao_opcao
                                          }
                                          obrigatorio={
                                            grupo.ind_obrigatorio === "S"
                                              ? true
                                              : false
                                          }
                                          opcoes={op}
                                          onClickItem={SelecionaCheckbox}
                                        />
                                      )}
                                    {grupo.ind_ativo === "S" &&
                                      grupo.qtd_max_escolha === null &&
                                      grupo.qtd_limiteitem === null &&
                                      grupo.qtd_minimoitem === null && ( // + e -
                                        <ProdutoItemMultiplos
                                          titulo={grupo.nome_opcao}
                                          descricao_opcao={
                                            grupo.descricao_opcao
                                          }
                                          obrigatorio={
                                            grupo.ind_obrigatorio == "S"
                                              ? true
                                              : false
                                          }
                                          opcoes={op}
                                          onClickItem={SelecionaQuantidade}
                                        />
                                      )}
                                  </div>
                                );
                              })}
                          </StepContent>
                        </Step>
                      )}

                      <Step key="observacao">
                        <StepContent TransitionProps={{ unmountOnExit: false }}>
                          <div className="observacao">
                            <div className="col-12" style={{ marginTop: 10 }}>
                              <div className="d-flex align-items-center justify-content-center">
                                <button
                                  onClick={ClickMenos}
                                  className={`btn ${localStorage.getItem("sessionMode") ===
                                    "light"
                                    ? "btn-outline-danger"
                                    : "btn-outline-secondary"
                                    }`}
                                  style={{ borderRadius: "100%" }}
                                >
                                  <i className="fas fa-minus"></i>
                                </button>
                                <span
                                  className={`cursor-default text-center ${localStorage.getItem("sessionMode") ===
                                    "light"
                                    ? ""
                                    : "text-secondary"
                                    }`}
                                  style={{ width: "32px" }}
                                >
                                  {qtd.toLocaleString("pt-BR", {
                                    minimumIntegerDigits: 2,
                                  })}
                                </span>
                                <button
                                  onClick={ClickMais}
                                  className={`btn ${localStorage.getItem("sessionMode") ===
                                    "light"
                                    ? "btn-outline-success"
                                    : "btn-outline-secondary"
                                    }`}
                                  style={{ borderRadius: "100%" }}
                                >
                                  <i className="fas fa-plus"></i>
                                </button>
                              </div>
                            </div>

                            <div className="col-12">
                              <label
                                htmlFor="observacao"
                                style={{ marginLeft: 10 }}
                                className="mb-1"
                              >
                                Observação{" "}
                              </label>
                              <textarea
                                id="observacao"
                                className="form-control"
                                rows={3}
                                value={observacao}
                                placeholder="Digite aqui sua observação..."
                                onChange={handleObservacao}
                              />
                            </div>
                          </div>
                        </StepContent>
                      </Step>
                    </Stepper>
                  )}
                </Modal.Body>

                <Modal.Footer
                  style={
                    isMobile
                      ? {
                        display: "block",
                        justifyContent: "inherit!important",
                        paddingLeft: "1rem",
                        paddingRight: "1rem",
                      }
                      : {}
                  }
                >
                  <div className="row">
                    <div className="col ps-1 pe-0 order-2">
                      <div style={{ whiteSpace: "nowrap" }}>
                        {tipo === "N" && (
                          <>
                            {ingredientes.length === 0 &&
                              grupos.length === 0 ? (
                              <button
                                onClick={() => {
                                  AddItem();
                                  handleReset();
                                  isMobile === true
                                    ? FinalizarPedido()
                                    : toast.success(
                                      "Pedido foi adicionado na sacola"
                                    );
                                }}
                                className={`btn ${localStorage.getItem("sessionMode") ===
                                  "light"
                                  ? "btn-dark"
                                  : "btn-secondary"
                                  } w-100 h-100`}
                                disabled={bloquearBtn}
                              >
                                Adicionar -{" "}
                                {new Intl.NumberFormat("pt-BR", {
                                  style: "currency",
                                  currency: "BRL",
                                }).format(total)}
                              </button>
                            ) : (
                              <>
                                {activeStep === 0 && (
                                  <button
                                    onClick={handleNext}
                                    className={`btn ${localStorage.getItem("sessionMode") ===
                                      "light"
                                      ? "btn-success"
                                      : "btn-success"
                                      } w-100 h-100`}
                                    disabled={bloquearBtn}
                                  >
                                    Continuar -{" "}
                                    {new Intl.NumberFormat("pt-BR", {
                                      style: "currency",
                                      currency: "BRL",
                                    }).format(total)}
                                  </button>
                                )}

                                {activeStep > 0 && (
                                  <button
                                    onClick={() => {
                                      AddItem();
                                      handleReset();
                                      isMobile === true
                                        ? FinalizarPedido()
                                        : toast.success(
                                          "Pedido foi adicionado na sacola"
                                        );
                                    }}
                                    className={`btn ${localStorage.getItem("sessionMode") ===
                                      "light"
                                      ? "btn-dark"
                                      : "btn-secondary"
                                      } w-100 h-100`}
                                    disabled={
                                      cart.length > 0 ? bloquearBtn : false
                                    }
                                  >
                                    Adicionar -{" "}
                                    {new Intl.NumberFormat("pt-BR", {
                                      style: "currency",
                                      currency: "BRL",
                                    }).format(total)}
                                  </button>
                                )}
                              </>
                            )}
                          </>
                        )}

                        {tipo === "I" && (
                          <>
                            {ingredientes.length === 0 &&
                              grupos.length === 0 ? (
                              <button
                                onClick={() => {
                                  AddItem();
                                  handleReset();
                                  isMobile === true
                                    ? FinalizarPedido()
                                    : toast.success(
                                      "Pedido foi adicionado na sacola"
                                    );
                                }}
                                className={`btn ${localStorage.getItem("sessionMode") ===
                                  "light"
                                  ? "btn-success"
                                  : "btn-success"
                                  } w-100 h-100`}
                                disabled={bloquearBtn}
                              >
                                Finalizar -{" "}
                                {new Intl.NumberFormat("pt-BR", {
                                  style: "currency",
                                  currency: "BRL",
                                }).format(total)}
                              </button>
                            ) : (
                              <>
                                {activeStep !== 2 && (
                                  <button
                                    onClick={handleNext}
                                    className={`btn ${localStorage.getItem("sessionMode") ===
                                      "light"
                                      ? "btn-success"
                                      : "btn-success"
                                      } w-100 h-100`}
                                    disabled={bloquearBtn}
                                  >
                                    Continuar -{" "}
                                    {new Intl.NumberFormat("pt-BR", {
                                      style: "currency",
                                      currency: "BRL",
                                    }).format(total)}
                                  </button>
                                )}

                                {activeStep === 2 && (
                                  <button
                                    onClick={() => {
                                      AddItem();
                                      handleReset();
                                      isMobile === true
                                        ? FinalizarPedido()
                                        : toast.success(
                                          "Pedido foi adicionado na sacola"
                                        );
                                    }}
                                    className={`btn ${localStorage.getItem("sessionMode") ===
                                      "light"
                                      ? "btn-dark"
                                      : "btn-secondary"
                                      } w-100 h-100`}
                                    disabled={bloquearBtn}
                                  >
                                    Adicionar -{" "}
                                    {new Intl.NumberFormat("pt-BR", {
                                      style: "currency",
                                      currency: "BRL",
                                    }).format(total)}
                                  </button>
                                )}
                              </>
                            )}
                          </>
                        )}

                        {tipo === "E" && (
                          <>
                            {ingredientes.length === 0 &&
                              grupos.length === 0 ? (
                              <button
                                onClick={(e) => {
                                  handleAddPizzas();
                                }}
                                className={`btn ${localStorage.getItem("sessionMode") ===
                                  "light"
                                  ? "btn-success"
                                  : "btn-success"
                                  } w-100 h-100`}
                              // disabled={verificarBloquear}
                              >
                                Finalizar -{" "}
                                {new Intl.NumberFormat("pt-BR", {
                                  style: "currency",
                                  currency: "BRL",
                                }).format(total)}
                              </button>
                            ) : (
                              <>
                                {activeStep !== 2 && (
                                  <button
                                    onClick={handleNext}
                                    className={`btn ${localStorage.getItem("sessionMode") ===
                                      "light"
                                      ? "btn-success"
                                      : "btn-success"
                                      } w-100 h-100`}
                                  // disabled={verificarBloquear}
                                  >
                                    Continuar -{" "}
                                    {new Intl.NumberFormat("pt-BR", {
                                      style: "currency",
                                      currency: "BRL",
                                    }).format(total)}
                                  </button>
                                )}

                                {activeStep === 2 && (
                                  <button
                                    onClick={(e) => {
                                      handleAddPizzas();
                                    }}
                                    className={`btn ${localStorage.getItem("sessionMode") ===
                                      "light"
                                      ? "btn-dark"
                                      : "btn-secondary"
                                      } w-100 h-100`}
                                  // disabled={bloquearBtn}
                                  >
                                    Adicionar -{" "}
                                    {new Intl.NumberFormat("pt-BR", {
                                      style: "currency",
                                      currency: "BRL",
                                    }).format(total)}
                                  </button>
                                )}
                              </>
                            )}
                          </>
                        )}

                        {tipo === "G" && (
                          <button
                            onClick={() => {
                              AddProdutos();
                              handleReset();
                            }}
                            className={`btn ${localStorage.getItem("sessionMode") === "light"
                              ? "btn-dark"
                              : "btn-secondary"
                              } w-100 h-100`}
                            disabled={bloquearBtn}
                          >
                            Adicionar -{" "}
                            {new Intl.NumberFormat("pt-BR", {
                              style: "currency",
                              currency: "BRL",
                            }).format(total)}
                          </button>
                        )}
                      </div>
                    </div>
                    <div className="col-auto ps-0 order-1">
                      {activeStep === 0 && (
                        <>
                          <button
                            className="btn btn-outline-secondary"
                            onClick={props.onRequestClose}
                          >
                            Fechar
                          </button>
                        </>
                      )}

                      <>
                        {activeStep > 0 && (
                          <button
                            className="btn btn-outline-secondary"
                            onClick={handleBack}
                          >
                            Voltar
                          </button>
                        )}
                      </>
                    </div>
                  </div>
                </Modal.Footer>
              </>
            )}
          </>
        )}
      </Modal>
    </>
  );
}

export default ProdutoModal;
