import { useEffect, useState } from "react";
import { Navbar } from '../../components'
import api from '../../services/api';

function Perfil(){

    const [nome, setNome] = useState('');
    const [email, setEmail] = useState('');
    const [mode, setMode] = useState(localStorage.getItem("sessionMode"))
    const [msg, setMsg] = useState('');
    const [erro, setErro] = useState('');

    function ExibeMsg(){
        setMsg('Dados alterados com sucesso');
        setTimeout(() => setMsg(''), 3000);
    }

    function ExibeErro(str){
        setErro(str);
        setTimeout(() => setErro(''), 5000);
    }

    function SalvarDados(e){
        e.preventDefault()
        api.patch('/v1/usuarios', {
            nome,
            email,
            mode,
        })
        .then(response => {
            ExibeMsg();
        })
        .catch(err => {
            if (err.response) {
                ExibeErro(err.response.data.erro);
            } else {
                ExibeErro('Ocorreu um erro na requisição.');
            }
        });
    }

    useEffect(() => {
        api.get(`/v1/usuarios/${localStorage.getItem("sessionId")}`)
        .then(response => {
            setNome(response.data[0].nome);
            setEmail(response.data[0].email);
            setMode(response.data[0].mode)

            if(localStorage.getItem('sessionMode') === 'light') {
                localStorage.setItem('sessionMode', 'light')
            } else if(localStorage.getItem('sessionMode') === 'dark') {
                localStorage.setItem('sessionMode', 'dark')
            }

        })
        .catch(err => console.log(err));
    }, []);

    function changeTheme(e) {
        setMode(e.target.value)

        localStorage.setItem('sessionMode', e.target.value);

        if(e.target.value === 'light') {
            document.body.classList.remove('dark-mode')
        } else if(e.target.value === 'dark') {
            document.body.classList.add('dark-mode')
        }
    }

    return (
        <>
        <Navbar />

        <div className="container-fluid perfil" style={{ paddingTop: '20px' }}>
            <div className="row col-lg-6 offset-lg-3">
            
                <div className="mt-2">
                    <h3>Meu Perfil</h3>
                </div>

                <div className="col-12">
                    <form onSubmit={SalvarDados}>
                        <div className="mb-3">
                            <label htmlFor="InputNome" className="form-label">Nome</label>
                            <input type="text" onChange={(e) => setNome(e.target.value)} value={nome} className="form-control" id="InputNome" aria-describedby="nome" />                    
                        </div>
                        <div className="mb-3">
                            <label htmlFor="InputEmail" className="form-label">E-mail</label>
                            <input type="email" onChange={(e) => setEmail(e.target.value)} value={email} className="form-control" id="InputEmail" aria-describedby="email" />                    
                        </div>

                        <div className='mb-3'>
                            <label htmlFor='InputMode' className='form-label'>Tema</label>
                            <select
                                value={mode}
                                onChange={changeTheme}
                                className='form-select' 
                                id='InputMode' 
                                aria-describedby='mode'
                            >
                                <option value='light'>Claro</option>
                                <option value='dark'>Escuro</option>
                            </select>
                        </div>

                        <div className="d-flex justify-content-end">
                            <button type="submit" className={`btn btn-lg ${localStorage.getItem('sessionMode') === 'light' ? 'btn-danger' : 'btn-secondary'}`}>Salvar Dados</button>
                        </div>

                        {
                            msg.length > 0 ? <div className="alert alert-success mt-4 text-center fade-in-out">{msg}</div> : null
                        }

                        {
                            erro.length > 0 ? <div className="alert alert-danger mt-4 text-center">{erro}</div> : null
                        }
                    </form>
                </div>

            </div>
        </div>
        </>
    )
}

export default Perfil;