import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./styles.css"
import LogoGerence from "../../assets/gerenceSem.png"
import ComerAqui from "../../assets/logo.png"

const Error404Page = () => {
  const navigate = useNavigate();

  const emojis = ["🍔", "🍕", "🍩", "🍪", "🍟", "🌮", "🍿"]; // Adicione mais emojis aqui!

  const [randomEmoji, setRandomEmoji] = useState("");

  useEffect(() => {
    // Escolhe aleatoriamente um emoji da matriz
    const randomIndex = Math.floor(Math.random() * emojis.length);
    setRandomEmoji(emojis[randomIndex]);
  }, [emojis]);

  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    backgroundColor: "#f0f8ff ", // Amarelo claro
    color: "#ff5a5f", // Vermelho claroe53935
    padding: "18px", // Espaçamento interno
  };

  const emojiStyle = {
    fontSize: "100px",
    animation: "bounce 1s infinite, fly 5s infinite", // Animação de salto e voo
    transformOrigin: "center bottom", // Origem da transformação para o centro inferior
  };

  const messageStyle = {
    fontSize: "24px",
    marginBottom: "20px",
    fontFamily: "cursive",
  };

  const buttonStyle = {
    padding: "10px 20px",
    marginTop: "30px",
    backgroundColor: "#e53935", // Vermelho escuro
    color: "white",
    border: "none",
    borderRadius: "5px",
    fontSize: "18px",
    cursor: "pointer",
    // fontFamily: "cursive",
    textTransform: "uppercase",
    letterSpacing: "2px",
    boxShadow: "0px 3px 5px rgba(0, 0, 0, 0.2)", // Sombra no botão
    transition: "transform 0.2s ease-in-out", // Efeito de transição no hover
  };

  const handleBackToHome = () => {
    navigate("/");
  };

  return (
    <div style={containerStyle}>
      <div style={emojiStyle}>4{randomEmoji}4</div>
      <h1 style={{color: "#000", marginBottom: 5, marginTop:10}}> Oops!  </h1>
      <div style={messageStyle}>Parece que você se perdeu...</div>
      <div style={messageStyle}>Essa página não existe!</div>
      <button
        style={buttonStyle}
        onClick={handleBackToHome}
        onMouseEnter={(e) => (e.target.style.transform = "scale(1.1)")}
        onMouseLeave={(e) => (e.target.style.transform = "scale(1)")}
      > 
        Voltar
      </button>
      <img src={ComerAqui} style={{marginTop: 40}} />
      <img src={LogoGerence}  style={{marginTop: 30}} width={150}/>
    </div>
  );
};

export default Error404Page;
