import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import Logo from '../../assets/logo.png';
import GerenceLogo from '../../assets/gerence.png'
import './style.css';
import api from '../../services/api';

import SaltPassword from '../../services/md5';

function Login(){

    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [senha, setSenha] = useState('');
    const [sucesso, setSucesso] = useState('');
    const [loading, setLoading] = useState(false);

    function ProcessaLogin(e){
        e.preventDefault();

        setSucesso('');
        setLoading(true);


        api.post('v1/usuarios/login', {
            email: email,
            senha: SaltPassword(senha),
        })
        .then(response => {            
            localStorage.setItem('sessionToken', response.data.token);
            localStorage.setItem('sessionId', response.data.id_usuario);
            localStorage.setItem('sessionEmail', email);
            localStorage.setItem('sessionCodCidade', response.data.cod_cidade);
            localStorage.setItem('sessionCidade', response.data.cidade);
            localStorage.setItem('sessionUF', response.data.uf);
            localStorage.setItem('sessionMode', response.data.mode)            
            setSucesso('S');
            navigate('/home');

            if(localStorage.getItem('sessionMode') === 'light') {
                document.body.classList.remove('dark-mode')
            } else if(localStorage.getItem('sessionMode') === 'dark') {
                document.body.classList.add('dark-mode')
            }
        })
        .catch(err => {
            setSucesso('N');
            setLoading(false);
        })
    }

    return (
        <div className="container-fluid" style={{ height: '100vh' }}>
            <div className="row h-100">
                <div 
                    className="col-lg-6 px-0 d-none d-lg-block" 
                    style={{
                        background: `url(https://source.unsplash.com/random/?food)`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                />
                <div className="col-lg-6 d-flex justify-content-center align-items-center text-center" style={{ boxShadow:  '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)' }}>
                    <form  onSubmit={ProcessaLogin} noValidate className="form-login py-3">
                        <h3 className="mb-4">Peça seu delivery<br /><span className='text-danger'>agora mesmo</span>.</h3>
                        <h6 className="mb-3">Acesse sua conta</h6>

                        <div className="form-floating">
                            <input type="email" onChange={(e) => setEmail(e.target.value)} className="form-control" id="email" placeholder="E-mail" />
                            <label htmlFor="email">E-mail</label>
                        </div>

                        <div className="form-floating">
                            <input type="password" onChange={(e) => setSenha(e.target.value)} className="form-control" id="senha" placeholder="Senha" />
                            <label htmlFor="senha">Senha</label>
                        </div>

                        <button type='submit' className="w-100 btn btn-lg btn-danger" disabled={loading}>
                            {loading ? (
                                <div>
                                    <span className="spinner-border spinner-border-sm text-light" role="status"></span>
                                    <span className="ms-2">Enviando...</span>
                                </div> 
                            ) :  (
                                <span className="ms-2">Acessar</span>
                            )}
                        </button>

                        {sucesso === 'N' ? <div className="alert alert-danger mt-2" role="alert">E-mail ou senha inválida</div> : null}

                        <div className="mt-3">
                            Não tenho uma conta. <Link to="/cadastro" className="text-danger text-decoration-none">Criar Agora!</Link>
                        </div>

                        <Link to='/'>
                            <img src={Logo} alt="Comer Aqui" width={196} height='auto' className="mt-5"/>
                        </Link>

                        <div className="mt-5">
                            <p className="text-secondary small">desenvolvimento</p>
                            <a href='http://gerencesistemas.com.br/' target='_blank' rel="noreferrer"><img src={GerenceLogo} alt='Gerence Sistemas' width={128} height='auto' /></a>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Login;