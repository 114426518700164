import { BASE_URL } from '../../constants/BASE_URL';
import './style.css';
import { Link } from "react-router-dom";


function Banner({id_banner, descricao, url_imagem }){
    return <div className="banner">
        <Link to={`/busca?id_banner=${id_banner}&descr=${descricao}`}>
            <div>
                <img className="img-banner" src={  BASE_URL + url_imagem} alt="Banner" />
            </div>
        </Link>
    </div>
}
export default Banner;