import "./styles.css";
import pizzaP from "../../assets/img/pizza/pizzaP.png"
import pizzaPDark from "../../assets/img/pizza/pizzaPDark.png"
import pizzaM from "../../assets/img/pizza/pizzaM.png"
import pizzaM1Dark from "../../assets/img/pizza/pizzaM1Dark.png"

import pizzaMDark from "../../assets/img/pizza/pizzaMDark.png"
import pizzaG from "../../assets/img/pizza/pizzaG.png"
import pizzaGDark from "../../assets/img/pizza/pizzaGDark.png"
import pizzaG1Dark from "../../assets/img/pizza/pizzaGP1.png"
import pizzaG2Dark from "../../assets/img/pizza/pizzaGP2.png"
import pizzaGG from "../../assets/img/pizza/pizzaGG.png"
import pizzaGGDark from "../../assets/img/pizza/pizzaGGDark.png"
import pizzaGGDark1 from "../../assets/img/pizza/pizzaGGDark1.png"
import pizzaGGDark2 from "../../assets/img/pizza/pizzaGGDark2.png"
import pizzaGGDark3 from "../../assets/img/pizza/pizzaGGDark3.png"

export const DrawPizza = ({ quantidade, selecionado }) => {

  const Pizza = () => {

    // Pizza P 
    if (quantidade == 1) {
      if (selecionado.length == 0) {
        return <div className="spinner">
          <img src={pizzaPDark} className="pizza-part pizza-part-1" />

        </div>
      }
      if (selecionado.length == 1) {
        return <div className="spinner">
          <img src={pizzaP} className="pizza-part pizza-part-1" />

        </div>
      }
    }
    // Pizza M
    if (quantidade == 2) {
      if (selecionado.length == 0) {
        return <div className="spinner">
          <img src={pizzaMDark} className="pizza-part pizza-part-1" />

        </div>
      }
      if (selecionado.length == 1) {
        return <div className="spinner"><img src={pizzaM1Dark} className="pizza-part pizza-part-1" /> </div>
      }
      if (selecionado.length == 2) {
        return <div className="spinner"><img src={pizzaM} className="pizza-part pizza-part-1" /> </div>
      }
    }
    // pizza G
    if (quantidade == 3) {
      if (selecionado.length == 0) {
        return <div className="spinner">
          <img src={pizzaGDark} className="pizza-part pizza-part-1" />

        </div>
      }
      if (selecionado.length == 1) {
        return <div className="spinner"><img src={pizzaG2Dark} className="pizza-part pizza-part-1" /> </div>
      }
      if (selecionado.length == 2) {
        return <div className="spinner"><img src={pizzaG1Dark} className="pizza-part pizza-part-1" /> </div>
      }
      if (selecionado.length == 3) {
        return <div className="spinner"><img src={pizzaG} className="pizza-part pizza-part-1" /> </div>
      }
      
 
    }

        // pizza GG
        if (quantidade == 4) {
          if (selecionado.length == 0) {
            return <div className="spinner">
              <img src={pizzaGGDark} className="pizza-part pizza-part-1" />
    
            </div>
          }
          if (selecionado.length == 1) {
            return <div className="spinner"><img src={pizzaGGDark3} className="pizza-part pizza-part-1" /> </div>
          }
          if (selecionado.length == 2) {
            return <div className="spinner"><img src={pizzaGGDark2} className="pizza-part pizza-part-1" /> </div>
          }
          if (selecionado.length == 3) {
            return <div className="spinner"><img src={pizzaGGDark1} className="pizza-part pizza-part-1" /> </div>
          }
          if (selecionado.length == 4) {
            return <div className="spinner"><img src={pizzaGG} className="pizza-part pizza-part-1" /> </div>
          }  
     
        }
    
  }

  return (
    <div style={{ display: "flex", flexDirection: "row", fontSize: 9 }}>

      <Pizza />
      
      {
        selecionado && selecionado.length >= 1 && <p style={{ position: "absolute", color: "#000", marginLeft: 75, marginTop: 40,  color:  localStorage.getItem("sessionMode") === "light" ? '#a60000' :  '#fff', fontWeight: 600}}>  {selecionado[0].nome}</p>

      }
              {
        selecionado && selecionado.length >= 2 && <p style={{ position: "absolute", color: "#000", marginLeft: 20, marginTop: 70,  color:localStorage.getItem("sessionMode") === "light" ? '#a60000' :  '#fff', fontWeight: 600 }}>  {selecionado[1].nome}</p>

      }

      {
        selecionado && selecionado.length >= 3 && <p style={{ position: "absolute", color: "#000", marginLeft:20, marginTop: -15,  color:localStorage.getItem("sessionMode") === "light" ? '#a60000' :  '#fff', fontWeight: 600 }}>  {selecionado[2].nome}</p>

      }
    
            {
        selecionado && selecionado.length >= 4 && <p style={{ position: "absolute", color: "#000", marginLeft: 75, marginTop: 10,  color:localStorage.getItem("sessionMode") === "light" ? '#a60000' :  '#fff', fontWeight: 600 }}>   {selecionado[3].nome}</p>

      }
    </div>

  );

};
