import { useNavigate, useParams } from "react-router-dom";
import PropTypes from "prop-types";

import { Navbar } from "../../components";

import "./style.css";
import Produto from "../../components/produto/lista";
import { useContext, useEffect, useState } from "react";
import api from "../../services/api";
import CoverModal from "../../components/produto/cover";
import LogoModal from "../../components/produto/logo";
import ProdutoModal from "../../components/produto/modal";
import { HiOutlineHeart, HiHeart } from "react-icons/hi";
import { CartContext } from "../../contexts/cart";

import icon from "../../assets/icon.png";
import iconDark from "../../assets/icon-dark.png";

import { Rating, Tabs, Tab, Box, Button } from "@mui/material";
import MiniSidebar from "../../components/mini-sidebar";

import { TiShoppingCart } from "react-icons/ti";
import { FormatarNomeUrl } from "../../hook/FormatarNome";

import { NotificarPedido } from "../../components/notificarPedido";
import SaltPassword from "../../services/md5";
import { BASE_URL } from "../../constants/BASE_URL";


function Cardapio() {
  const {
    cart,
    setEntregaCart,
    idEstabelecimentoCart,
    setIdEstabelecimentoCart,
    totalCart,
    isMobile,
    setNomeEmpresaParams,
    setImagemEmpresa,
    setFormaDeTrabalho,
    setFormaDePagamento,
    statusApi
  } = useContext(CartContext);
  const [id, setId] = useState();
  const navigate = useNavigate();

  const { nomeEmpresa } = useParams();
  setNomeEmpresaParams(nomeEmpresa);

  // Informações do banco de dados
  const [logo, setLogo] = useState("");
  const [nome, setNome] = useState("");
  const [endereco, setEndereco] = useState("");
  const [complemento, setComplemento] = useState("");
  const [bairro, setBairro] = useState("");
  const [cidade, setCidade] = useState("");
  const [uf, setUF] = useState("");
  const [avaliacao, setAvaliacao] = useState(0);
  const [foto, setFoto] = useState("");
  const [entrega, setEntrega] = useState(0);
  const [minimo, setMinimo] = useState(0);
  const [qtd, setQtd] = useState(0);
  const [favorito, setFavorito] = useState(false);
  const [idFavorito, setIdFavorito] = useState(0);
  const [id_produto, setId_produto] = useState(0);
  const [categorias, setCategorias] = useState([]);
  const [produtos, setProdutos] = useState([]);
  //
  const [facebook, setFacebook] = useState("");
  const [whatsapp, setWhatsApp] = useState("");
  const [instagram, setInstagram] = useState("");
  //
  const [isCoverOpen, setIsCoverOpen] = useState(false);
  const [isLogoOpen, setIsLogoOpen] = useState(false);
  const [isInfoOpen, setIsInfoOpen] = useState(false);
  const [isProdutoOpen, setIsProdutoOpen] = useState(false);
  const [loginObrigatorio, setLoginObrigatorio] = useState(true);
  const [situacao, setSituacao] = useState("");



  if (!localStorage.getItem("sessionToken")) {
    api.post('v1/usuarios/login', {
      email: 'visitante@comeraqui.com.br',
      senha: SaltPassword('ger5455++')
    })
      .then(response => {

        localStorage.setItem('sessionToken', response.data.token);
        localStorage.setItem('sessionId', response.data.id_usuario);
        localStorage.setItem('sessionEmail', 'visitante@comeraqui.com.br');


        localStorage.setItem('sessionCodCidade', 4105508);
        localStorage.setItem('sessionCidade', "CIANORTE");
        localStorage.setItem('sessionUF', 'PR');

        localStorage.setItem('sessionMode', response.data.mode)

        window.location.reload(true);


      })
  }


  function TabPanel(props) {
    const { children, value, index, ...other } = props;


    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ px: "16px", pb: 3 }}>{children}</Box>}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    (async () => {
      const responseDestaques = await api.get(
        "v1/destaques?cod_cidade=" + localStorage.getItem("sessionCodCidade")
      );

      const modResponse = responseDestaques && responseDestaques?.data?.filter((item) => {
        return FormatarNomeUrl(item.nome) === FormatarNomeUrl(nomeEmpresa);
      });

      if (modResponse && modResponse?.length) {

        setId(modResponse[0].id_estabelecimento);
      }

      let responseEstabelecimentos =
        id && (await api.get(`/v1/estabelecimentos/${id}`));

      if (responseEstabelecimentos) {
        responseEstabelecimentos = responseEstabelecimentos.data[0];
        const {
          url_logo,
          nome,
          endereco,
          complemento,
          bairro,
          cidade,
          uf,
          avaliacao,
          url_foto,
          vl_taxa_entrega,
          vl_min_pedido,
          qtd_avaliacao,
          id_favorito,
          login_obrigatorio,
          fd_entrega,
          fd_pagamento,
          whatsapp

        } = responseEstabelecimentos;

        setLogo(url_logo);
        setNome(nome);
        setEndereco(endereco);
        setComplemento(complemento);
        setBairro(bairro);
        setCidade(cidade);
        setUF(uf);
        setAvaliacao(avaliacao);
        setFoto(url_foto);
        setEntrega(vl_taxa_entrega);
        setMinimo(vl_min_pedido);
        setQtd(qtd_avaliacao);
        setFavorito(id_favorito > 0);
        setIdFavorito(id_favorito);
        setFacebook(facebook);
        setWhatsApp(whatsapp);
        setInstagram(instagram);
        setLoginObrigatorio(login_obrigatorio);
        setSituacao(situacao);
        setImagemEmpresa(url_logo);
        setFormaDeTrabalho(fd_entrega)
        setFormaDePagamento(fd_pagamento)


      }

      const responseCardapios = id && (await api.get(`/v1/cardapios/${id}`));

      if (responseCardapios) {
        let categoriasUnica = responseCardapios && responseCardapios?.data?.map(
          (item) => item.categoria
        );

        categoriasUnica = categoriasUnica && categoriasUnica?.filter(
          (itemArray, i, arrayCompleto) => {
            return arrayCompleto.indexOf(itemArray) === i;
          }
        );
        setCategorias(categoriasUnica);
        setProdutos(responseCardapios.data);
      }
    })();
  }, [id && id, id]);

  function FinalizarPedido() {
    navigate("/checkout/carrinho");
  }

  function openModalCover() {
    setIsCoverOpen(true);
  }
  function closeModalCover() {
    setIsCoverOpen(false);
  }

  function openModalLogo() {
    setIsLogoOpen(true);
  }
  function closeModalLogo() {
    setIsLogoOpen(false);
  }

  function openModalInfo() {
    setIsInfoOpen(true);
  }
  function closeModalInfo() {
    setIsInfoOpen(false);
  }

  function openModalProduto(id_prod) {
    // Valida se pode abrir o produto (nao pode ter iniciado compra de outro estab)
    if (
      cart?.length > 0 &&
      idEstabelecimentoCart !== id &&
      idEstabelecimentoCart > 0
    ) {
      alert("Já existem produtos de outro estabelecimento na sacola.");
      return;
    }
    setId_produto(id_prod);
    setEntregaCart(entrega);
    setIdEstabelecimentoCart(id);
    setIsProdutoOpen(true);
  }
  function closeModalProduto() {
    setIsProdutoOpen(false);
  }

  async function Favoritar() {
    const response = await api.post("/v1/estabelecimentos/favoritos", {
      id_estabelecimento: id,
    });
    setFavorito(true);
    setIdFavorito(response.data.id_favorito);
  }

  async function RemoverFavorito() {
    await api.delete(`/v1/estabelecimentos/favoritos/${idFavorito}`);
    setFavorito(false);
  }

  return (
    <>
      <Navbar />

      <div
        className="container-fluid mt-page cardapio"
        style={isMobile ? { paddingTop: "20px" } : { padding: "20px 40px 20px 40px" }}
      >
        <div>
          <CoverModal
            isOpen={isCoverOpen}
            onRequestClose={closeModalCover}
            cover={foto}
            nome={nome}
          />

          <LogoModal
            isOpen={isLogoOpen}
            onRequestClose={closeModalLogo}
            logo={logo}
            nome={nome}
            endereco={endereco}
            complemento={complemento}
            bairro={bairro}
            cidade={cidade}
            uf={uf}
            facebook={facebook}
            whatsapp={whatsapp}
            instagram={instagram}
          />

          <ProdutoModal
            isOpen={isProdutoOpen}
            onRequestClose={closeModalProduto}
            id_produto={id_produto}
            id={id}
            loginObrigatorio={loginObrigatorio}
          />

          <div className="row">
            {foto && (
              <>
                {situacao === 0 ? (
                  <div className="col-12 px-0 px-lg-3">
                    <div style={{ position: "relative" }}>
                      <div
                        className="d-flex align-items-center justify-content-center w-100 h-100 shadow-cover"
                        style={{
                          position: "absolute",
                          backgroundColor: "rgba(0, 0, 0, .50)",
                          color: "#FFF",
                        }}
                      >
                        <h4
                          className="m-0 cursor-default"
                          style={{
                            textShadow: "0 0.05rem 0.1rem rgb(0 0 0 / 50%)",
                          }}
                        >
                          Estabalecimento fechado
                        </h4>
                      </div>
                      <img
                        className="img-fluid img-estab-cardapio"
                        src={foto}
                        alt="Estabelecimento"
                        onClick={openModalCover}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="col-12 px-0 px-lg-3">
                    <img
                      className="img-fluid img-estab-cardapio"
                      src={foto}
                      alt="Estabelecimento"
                      onClick={openModalCover}
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                )}
              </>
            )}

            {!foto && situacao === 0 && (
              <div className="col-12">
                <div className="alert text-danger bg-danger bg-opacity-10 text-center m-0">
                  Estabalecimento fechado
                </div>
              </div>
            )}

            <div className="col-12 mt-3">
              <div className="row">
                <div className="col-auto">
                  {logo ? (
                    <img
                      src={BASE_URL + logo}
                      alt={nome}
                      height={100}
                      className="rounded"
                      onClick={openModalLogo}
                      style={{ borderRadius: 5, cursor: "pointer" }}
                    />
                  ) : (
                    <>
                      {localStorage.getItem("sessionMode") === "light" ? (
                        <img
                          className="img-estabelecimento"
                          src={icon}
                          alt="Sem imagem"
                          style={{ opacity: 0.35 }}

                        />
                      ) : (
                        <img
                          className="img-estabelecimento"
                          src={iconDark}
                          alt="Sem imagem"
                          style={{ opacity: 0.35 }}
                        />
                      )}
                    </>
                  )}
                </div>
                <div className="col ps-0">
                  <div className="d-flex align-items-center mb-1">
                    <h2 className="m-0">{nome}</h2>

                    {/* Voltar aqui */}


                    {!isMobile && (<NotificarPedido

                      navigate={navigate}
                      statusApi={statusApi.status === false ? {"STATUS": 0} : statusApi[0].pedido}
                    />)}



                    <div className="ms-auto">
                      <div className="d-flex align-items">
                        {localStorage.getItem("sessionEmail") ===
                          "visitante@comeraqui.com.br" ? (
                          <></>
                        ) : (
                          <>
                            {favorito ? (
                              <HiHeart
                                size={32}
                                className={`${localStorage.getItem("sessionMode") ===
                                  "light"
                                  ? "text-danger"
                                  : "text-secondary"
                                  }`}
                                onClick={RemoverFavorito}
                                style={{ cursor: "pointer" }}
                              />
                            ) : (
                              <HiOutlineHeart
                                size={32}
                                className={`${localStorage.getItem("sessionMode") ===
                                  "light"
                                  ? "text-danger"
                                  : "text-secondary"
                                  }`}
                                onClick={Favoritar}
                                style={{ cursor: "pointer" }}
                              />
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="classificacao">
                    <span>
                      {endereco}{" "}
                      {complemento?.length > 0 ? " - " + complemento : null} -{" "}
                      {bairro} - {cidade} - {uf}
                    </span>
                  </div>
                </div>
              </div>

              <div className="classificacao my-4">
                <div className="d-flex align-items-center">
                  <Rating
                    name="half-rating-read"
                    value={avaliacao}
                    precision={0.5}
                    readOnly
                  />
                  <span className="ms-1">{avaliacao.toFixed(1)}</span>
                  <span className="ms-1">- {qtd} avaliações</span>
                </div>
              </div>

              <div className="row classificacao">
                <div className="col-12 col-lg-auto">
                  <span>
                    <b>Taxa de entrega: </b>
                    {new Intl.NumberFormat("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    }).format(entrega)}
                  </span>
                </div>
                <div className="col-12 col-lg-auto">
                  <span>
                    <b>Pedido mínimo: </b>
                    {new Intl.NumberFormat("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    }).format(minimo)}
                  </span>


                </div>
                <div className="col-12 col-lg">

                  <span>
                    <b> Telefone Estabelecimento: </b>
                    {whatsapp}
                  </span>

                </div>
              </div>

            </div>
          </div>
        </div>
      </div>


      <div style={{ textAlign: "center", marginTop: 20 }}>
        {isMobile && (<NotificarPedido

          navigate={navigate}
          statusApi={statusApi.status === false ? {"STATUS": 0} : statusApi[0].pedido}

        />)}

      </div>

      {categorias?.length > 0 && (
        <>
          <div
            className="d-flex justify-content-center mt-3 sticky-top"
            style={{
              zIndex: -1,
              position: "static",
              top: "94px",
              display: "flex",
              alignItems: "center",
              background:
                localStorage.getItem("sessionMode") === "light"
                  ? "#FFF"
                  : "#212529",
            }}
          >
            <Tabs
              indicatorColor="primary"
              value={value}
              onChange={handleChange}
              textColor="inherit"
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
              aria-label="navigation for product categories"
            >
              {categorias && categorias?.map((categoria, index) => (
                <Tab key={index} label={categoria} {...a11yProps(index)} />
              ))}
            </Tabs>
          </div>

          <div
            className="container-fluid mt-3"
            style={
              isMobile == false
                ? { paddingLeft: 100, paddingRight: 100, paddingTop: 20 }
                : {}
            }
          >
            <div className="row">
              <div className={"col-12 col-xl-9"}>
                <div className="cardapio">
                  {categorias && categorias?.map((categoria, index) => (
                    <TabPanel key={index} value={value} index={index}>
                      {/* <h5>{categoria}</h5> */}

                      <div className="row" style={{ marginTop: 30, marginBottom: 50 }} >
                        {produtos && produtos?.map((produto) => {
                          return produto.categoria === categoria &&
                            produto.categoria === categoria &&
                            produto.mostrar === null ? (
                            <Produto
                              id_produto={produto.id_produto}
                              key={produto.id_produto}
                              nome={produto.nome}
                              descricao={produto.descricao}
                              vl_produto={produto.vl_produto}
                              vl_promocao={produto.vl_promocao}
                              url_foto={produto.url_foto}
                              onClickProduto={openModalProduto}
                              id={id}
                              situacao={situacao}
                              produtos={produtos}
                              isMobile={isMobile}
                            />
                          ) : null;
                        })}
                      </div>
                    </TabPanel>
                  ))}
                </div>
              </div>

              <div className={"d-none d-xl-block col-xl-3"}>
                <div
                  className="sticky-top"
                  style={{
                    top: "156px",
                    zIndex: 0,
                  }}
                >
                  <div
                    style={{
                      backgroundColor:
                        localStorage.getItem("sessionMode") === "light"
                          ? "rgba(238, 238, 238, .35)"
                          : "rgba(238, 238, 238, .05)",
                      borderRadius: "4px",
                    }}
                  >
                    <MiniSidebar />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <div className="d-lg-none">
        <div
          class="d-grid gap-2"
          style={{ position: "fixed", width: "100%", bottom: 0 }}
        >
          <button
            onClick={() => {
              FinalizarPedido();
            }}
            type="button"
            class="btn btn-success"
            style={{
              padding: 12,
              borderRadius: 0,
              display: "flex",
              alignItems: "center",
              textAlign: "center",
              justifyContent: "center",
            }}
          >
            {" "}
            <TiShoppingCart style={{ fontSize: 25, marginRight: 10 }} />{" "}
            {new Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL",
            }).format(totalCart)}{" "}
          </button>
        </div>
      </div>
    </>
  );
}

export default Cardapio;
