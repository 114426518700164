import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
} from "@mui/lab";

import { Typography } from "@mui/material";

import AccessTimeIcon from "@mui/icons-material/AccessTime";
import InfoIcon from "@mui/icons-material/Info";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

import {
  green,
  orange,
  blue,
  red,
  deepOrange,
  yellow,
} from "@mui/material/colors";
import cooking from "../../../assets/img/CheckoutStatus/cooking.svg";
import motoboy from "../../../assets/img/CheckoutStatus/motoboy.svg";
import menu from "../../../assets/img/CheckoutStatus/menu.svg";

export const StatusPedidoCheckout = ({ statusApi }) => {
  let status = statusApi === undefined ? "4" : statusApi?.pedido?.STATUS

  return (
    <>
      <div className="col-12" style={{ backdropFilter: "blur(3px)" }}>
        <div className="d-flex flex-column">
          <TimelineItem
            sx={{
              "&:before": {
                display: "none",
              },
              marginLeft: 2,
              marginBottom: 2,
              marginTop: 2,
            }}
          >
            <TimelineSeparator>
              <TimelineDot sx={{ bgcolor: blue[600] }}>
                <InfoIcon />
              </TimelineDot>
            </TimelineSeparator>
            <TimelineContent sx={{ display: "flex", flexDirection: "column" }}>
              <div className="d-flex align-items-center">
                <Typography sx={{ color: blue[600] }}>Informação</Typography>
              </div>
              <div>Mantenha essa página aberta para acompanhar seu pedido</div>
            </TimelineContent>
          </TimelineItem>

          <h4 className="cursor-default" style={{ margin: "0 0 0 20px", fontWeight: 400 }}>
            Status do pedido
          </h4>
          <Timeline sx={{ px: 4, cursor: "default" }}>

            {/* Etapa 1 */}
            {
              status != "0" && <>  <TimelineItem
                sx={{
                  "&:before": {
                    display: "none",
                  },
                }}
              >
                <TimelineSeparator>
                  <TimelineDot
                    sx={{
                      bgcolor: orange[600],
                      opacity: `${statusApi && status === "1" ? "" : "0.25"}`,
                      animation: `${statusApi && status === "1" ? "pulse 2s infinite" : ""
                        } `,
                      "@keyframes pulse": {
                        "0%, 100%": {
                          transform: "scale(1)",
                        },
                        "50%": {
                          transform: "scale(1.3)",
                        },
                      },
                    }}
                  >
                    <AccessTimeIcon />
                  </TimelineDot>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    opacity: `${statusApi && status === "1" ? "" : "0.25"}`,
                  }}
                >
                  <div className="d-flex align-items-center">
                    <Typography
                      sx={{
                        color: orange[600],
                      }}
                    >
                      Pedido pendente
                    </Typography>
                  </div>
                  <div> Aguardando a loja receber seu pedido</div>
                </TimelineContent>
              </TimelineItem>

                {/* Etapa 2 */}

                <TimelineItem
                  sx={{
                    opacity: `${statusApi && status === "2" ? "" : "0.25"}`,
                    "&:before": {
                      display: "none",
                    },
                  }}
                >
                  <TimelineSeparator
                    sx={
                      {
                        // ".MuiTimelineConnector-root": { display: "none" },
                      }
                    }
                  >
                    <TimelineDot
                      sx={{
                        bgcolor: yellow[800],
                        animation: `${statusApi && status === "2" ? "pulse 2s infinite" : ""
                          } `,
                        "@keyframes pulse": {
                          "0%, 100%": {
                            transform: "scale(1)",
                          },
                          "50%": {
                            transform: "scale(1.3)",
                          },
                        },
                      }}
                    >
                      <img src={menu} width={25} height={25} />
                    </TimelineDot>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent
                    sx={{ display: "flex", flexDirection: "column" }}
                  >
                    <Typography sx={{ color: yellow[800] }}>
                      Pedido recebido
                    </Typography>
                    Não se preocupe a loja já recebeu o seu pedido!
                  </TimelineContent>
                </TimelineItem>

                {/* Etapa 4 */}

                <TimelineItem
                  sx={{
                    opacity: `${statusApi && status === "3" ? "" : "0.25"}`,
                    "&:before": {
                      display: "none",
                    },
                  }}
                >
                  <TimelineSeparator
                    sx={
                      {
                        ".MuiTimelineConnector-root": { display: "none" },
                      }
                    }
                  >
                    <TimelineDot
                      sx={{
                        bgcolor: green[400],
                        animation: `${statusApi && status === "3" ? "pulse 2s infinite" : ""
                          } `,
                        "@keyframes pulse": {
                          "0%, 100%": {
                            transform: "scale(1)",
                          },
                          "50%": {
                            transform: "scale(1.3)",
                          },
                        },
                      }}
                    >
                      <img src={motoboy} width={25} height={25} />
                    </TimelineDot>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent
                    sx={{ display: "flex", flexDirection: "column" }}
                  >
                    <Typography sx={{ color: green[400] }}>
                      Motoboy na estrada
                    </Typography>
                    O pedido já esta chegando...
                  </TimelineContent>
                </TimelineItem>
              </>
            }


            {/* Etapa 0 */}

            {status === "0" && <TimelineItem
              sx={{
                opacity: `${statusApi && status === "0" ? "" : "0.25"}`,
                "&:before": {
                  display: "none",
                },
              }}
            >
              <TimelineSeparator
                sx={{
                  ".MuiTimelineConnector-root": { display: "none" },
                }}
              >
                <TimelineDot
                  sx={{
                    bgcolor: red[600],

                    animation: `${statusApi && status === "0" ? "pulse 2s infinite" : ""
                      } `,
                    "@keyframes pulse": {
                      "0%, 100%": {
                        transform: "scale(1)",
                      },
                      "50%": {
                        transform: "scale(1.3)",
                      },
                    },
                  }}
                >
                  <CloseIcon />
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent
                sx={{ display: "flex", flexDirection: "column" }}
              >
                <Typography sx={{ color: red[600] }}>
                  Pedido cancelado
                </Typography>
                <Typography>A loja não recebeu seu pedido</Typography>
              </TimelineContent>
            </TimelineItem>

            }

          </Timeline>
        </div>
      </div>
    </>
  );
};
