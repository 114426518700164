import { useEffect, useState } from "react";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Button } from "react-bootstrap";
import AddIcon from "@mui/icons-material/Add";
import EnderecoModalAdd from "../modal/adicionar";
import api from "../../../services/api";

function ButtonAddEndereco({ atualizarSite }) {
  const [isEnderecoOpen, setIsEnderecoOpen] = useState(false);
  const [dadosEndereco, setDadosEndereco] = useState([]);

  async function openModalEndereco(id) {
    if (id > 0) {
      const response = await api.get(`v1/usuarios/enderecos/${0}`);
      setDadosEndereco(response.data[0]);
      setIsEnderecoOpen(true);
    } else {
      setDadosEndereco([]);
      setIsEnderecoOpen(true);
    }
  }
  function closeModalEndereco() {
    setIsEnderecoOpen(false);
  }

  useEffect(() => {
    (async () => {
      const response = await api.get(`v1/usuarios/enderecos/${0}`);
      setDadosEndereco(response.data);
    })();
  }, []);
  return (
   
    <>
    <EnderecoModalAdd
      isOpen={isEnderecoOpen}
      onRequestClose={closeModalEndereco}
      dados_endereco={dadosEndereco}
      atualizarSite={atualizarSite && atualizarSite}
    />
    <Button
      style={{
        backgroundColor: "#0090c9",
        borderColor: "#0090c9",
        height: 35,
        width: 35,
        borderRadius: "100%",
        display: "flex",
        alignItems: "center",
        textAlign: "center",
        justifyContent: "center",
        padding: 12,
      }}
      onClick={() => {
        openModalEndereco(0);
      }}
    >
      <AddIcon sx={{ fontSize: 28 }} />
    </Button>
   </>
  )
}
export default ButtonAddEndereco;
