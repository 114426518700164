import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CartContext } from "../../contexts/cart";
import api from "../../services/api";
import { ToastContainer, toast } from "react-toastify";
import "../../styles/scrollCart.css";
import { EntregaEnderecoCheckout } from "../../components/checkout/entrega";
import LightLogo from "../../assets/logo-grande.png";
import DarkLogo from "../../assets/logo-dark.png";
import Modal from "react-bootstrap/Modal";
import TelefoneModal from "../../components/checkout/entrega/modalTelefone";
import GerenceLogo from "../../assets/gerence.png";
import { FooterEnderecoCheckout } from "../../components/checkout/checkoutFooter/endereco";
import pattern from '../../assets/patternBorrado.png'
import { BASE_URL } from "../../constants/BASE_URL";

export const CheckoutEndereco = () => {
  const navigate = useNavigate();
  const {
    setFinalizarPC,
    finalizarPC,
    setCheckoutEtapa,
    checkoutEtapa,
    isMobile,
    darkMode,
    // formfinalizarPedido, 
    setFormFinalizarPedido,
    formaDeTrabalho,
    telaHome,
    nomeEmpresaParams

  } = useContext(CartContext);

  const [atualizarApi, setAtualizarApi] = useState(false);

  const [enderecos, setEnderecos] = useState([]);

  const [telefone, setTelefone] = useState(
    localStorage.getItem("telefone") || ""
  );
  const [nome, setNome] = useState("");
  const [cep, setCEP] = useState("");
  const [endereco, setEndereco] = useState("");
  const [complemento, setComplemento] = useState("");
  const [bairro, setBairro] = useState("");
  const [cidade, setCidade] = useState("");
  const [uf, setUF] = useState("");
  const [codCidade, setCodCidade] = useState(
    localStorage.getItem("sessionCodCidade")
      ? localStorage.getItem("sessionCodCidade")
      : ""
  );

  const [user, setUser] = useState(false);

  const [entregar, setEntregar] = useState(true);
  const [retirar, setRetirar] = useState(false);
  const [enderecoTelefone, setEnderecoTelefone] = useState(false);

  const [usuarioExiste, setUsuarioExiste] = useState(false);
  const [buscarTelefone, setBuscarTelefone] = useState(false)

  const cidadeEmpresa = localStorage.getItem("sessionCidade").toLowerCase()
  const imgEmpresa = BASE_URL + `/assets/img/estabelecimentos/${cidadeEmpresa}/${(nomeEmpresaParams + '.png')}`


  async function EnderecoPorTelefoneApi(telefone) {
      try{
        if (telefone) {
          const resultApi = await api.get(`v1/usuarios/login/${telefone}`);
  
          const { nome } = resultApi?.data;
  
          setNome(nome);
        }
      } catch {}
  }

  useEffect(() => {
    if(telefone){
      EnderecoPorTelefoneApi(telefone)
    }

    if (finalizarPC) {
      setUser(true);
      setEntregar(true);
      setRetirar(false);
      setFinalizarPC(false);
    }
    api.get("v1/usuarios/enderecos", {
        params: {
          cod_cidade: localStorage.getItem("sessionCodCidade"),
        },
      })
      .then((response) => setEnderecos(response.data))
      .catch((err) => console.log(err));
  }, [atualizarApi]);

  function SelecionarEndereco(end) {
    setEndereco(end.endereco);
    setComplemento(end.complemento);
    setBairro(end.bairro);
    setCidade(end.cidade);
    setUF(end.uf);
    setCEP(end.cep);
    setCodCidade(end.cod_cidade);
  }

  const handleUserInfo = (end) => {
    if (entregar === true) {
      if (!endereco) {
        toast.error("Endereço obrigatório");
        return;
      }
    }
    if (retirar === true) {
      if (!nome && !telefone) {
        toast.error("Telefone e Nome são obrigatórios");
        return;
      } else if (nome && !telefone) {
        toast.error("Telefone obrigatório");
        return;
      } else if (!nome && telefone) {
        toast.error("Nome obrigatório");
        return;
      }
    }
    setUser(false);

    handleInfo();
  };


  const handleInfo = () => {
    const objetoEndereco = {
      endereco: endereco ? endereco : 'Retirar',
      complemento,
      bairro,
      cidade,
      uf,
      cep,
      cod_cidade: codCidade,
    }

    setFormFinalizarPedido({endereco: objetoEndereco})
  
  };

  function atualizarSite() {
    setAtualizarApi(!atualizarApi);
  }

  const SalvarDadosUsuarioTelefone = async () => {
    if (usuarioExiste) {
      try {
        await api.patch(`/v1/usuarios/enderecos/telefone/${telefone}`, {
          nome,
          endereco,
          complemento,
          bairro,
          cidade,
          uf,
          cep,
        });
        atualizarSite();
        toast.success("Salvado com sucesso!");
      } catch {
        toast.error("Deu algum error ao salvar");
      }
    } else {
      try {
        await api.post(`/v1/usuarios/registro/${telefone}`, {
          nome,
          telefone,
          endereco,
          complemento,
          bairro,
          cidade,
          uf,
          cep,
          cod_cidade: codCidade
        });
        localStorage.setItem("telefone", telefone);
        setBuscarTelefone(!buscarTelefone)
        atualizarSite();
     
        toast.success("Usuario criado!");
      } catch {
        toast.error("Deu algum error ao cadastra o usuario");
      }
    }
  };

  return (
    <>
      <Modal
        id="ads"
        show
        scrollable={true}
        centered="true"
        fullscreen
        backdrop="static"
>
        <Modal.Header>
          <img src={darkMode === "dark" ? DarkLogo : LightLogo} width={130} />
          {nomeEmpresaParams ? <img src={imgEmpresa} width={50} style={{ marginRight: 30, borderRadius: 100 }} /> : <img src={GerenceLogo} width={80} style={{ marginRight: 30 }} />}


        </Modal.Header>

        <Modal.Body style={ { backgroundImage: `url(${pattern})`} }>
          <div style={ isMobile ? {} : {margin: "0 500px"} }> 
          <ToastContainer containerId="toast-container" />

          {localStorage.getItem("sessionEmail") ===
          "visitante@comeraqui.com.br" ? (
            <TelefoneModal
              telefone={telefone}
              nome={nome}
              cep={cep}
              setTelefone={setTelefone}
              setNome={setNome}
              setCEP={setCEP}
              endereco={endereco}
              setEndereco={setEndereco}
              complemento={complemento}
              setComplemento={setComplemento}
              bairro={bairro}
              setBairro={setBairro}
              cidade={cidade}
              setCidade={setCidade}
              setUF={setUF}
              uf={uf}
              codCidade={codCidade}
              setCodCidade={setCodCidade}
              enderecos={enderecos}
              SelecionarEndereco={SelecionarEndereco}
              atualizarSite={atualizarSite}
              setUser={setUser}
              setEnderecoTelefone={setEnderecoTelefone}
              setUsuarioExiste={setUsuarioExiste}
              // setId_Endereco={setId_Endereco}
              setRetirar={setRetirar}
              setEntregar={setEntregar}
              entregar={entregar}
              retirar={retirar}
              buscarTelefone={buscarTelefone}
              setCheckoutEtapa={setCheckoutEtapa}
              navigate={navigate}
            />
          ) : (
            <EntregaEnderecoCheckout
              setEntregar={setEntregar}
              setRetirar={setRetirar}
              entregar={entregar}
              retirar={retirar}
              telefone={telefone}
              nome={nome}
              cep={cep}
              setTelefone={setTelefone}
              setNome={setNome}
              setCEP={setCEP}
              endereco={endereco}
              setEndereco={setEndereco}
              complemento={complemento}
              setComplemento={setComplemento}
              bairro={bairro}
              setBairro={setBairro}
              cidade={cidade}
              setCidade={setCidade}
              setUF={setUF}
              codCidade={codCidade}
              setCodCidade={setCodCidade}
              enderecos={enderecos}
              SelecionarEndereco={SelecionarEndereco}
              atualizarSite={atualizarSite &&  atualizarSite}
              formaDeTrabalho={formaDeTrabalho}
            />
          )}
          </div>
        </Modal.Body>

        <Modal.Footer style={isMobile ? {} : { margin: "0 500px" }}>

          <FooterEnderecoCheckout
            // setSite={setSite}
            // setEntrega={setEntrega}
            user={user}
            handleUserInfo={handleUserInfo}
            endereco={endereco}
            darkMode={darkMode}
            enderecoTelefone={enderecoTelefone}
            setEnderecoTelefone={setEnderecoTelefone}
            SalvarDadosUsuarioTelefone={SalvarDadosUsuarioTelefone}
            navigate={navigate}
            setCheckoutEtapa={setCheckoutEtapa}
            checkoutEtapa={checkoutEtapa}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};
