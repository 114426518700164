import { useState, useEffect } from "react";
import { Navbar, Estabelecimento } from '../../components'
import api from '../../services/api';

function Favoritos(){

    const [favoritos, setFavoritos] = useState([]);

    function ListarFavoritos(){
        api.get('v1/estabelecimentos/favoritos')
        .then(response => setFavoritos(response.data))
        .catch(err => console.log(err));
    }

    useEffect(() => ListarFavoritos(), []);

    function DeleteFavorito(id){
        api.delete(`v1/estabelecimentos/favoritos/${id}`)
        .then(response => ListarFavoritos())
        .catch(err => console.log(err));
    }

    return (
        <>
        <Navbar />

        <div className="container-fluid" style={{ paddingTop: '20px' }}>

            <div className="row col-lg-8 offset-lg-2">

                <div className="col-12 mt-2">
                    <h2>Meus Favoritos</h2>
                    {favoritos.length === 0 && <small className="text-secondary">Nenhum estabelecimento favorito.</small>}
                </div>

                <div className="row">
                {
                    favoritos.map(estabelecimento => {
                        return (
                            <Estabelecimento 
                                key={estabelecimento.id_estabelecimento}
                                url_imagem={estabelecimento.url_logo}
                                nome={estabelecimento.nome}
                                avaliacao={estabelecimento.avaliacao}
                                categoria={estabelecimento.categoria}
                                id_estabelecimento={estabelecimento.id_estabelecimento}
                                id_favorito={estabelecimento.id_favorito}
                                btnRemoverFavorito
                                onClickRemoverFavorito={DeleteFavorito}
                                situacao={estabelecimento.situacao}
                                colFavorito={6}
                            />
                        )
                    })
                }
                </div>

            </div>
        </div>
        </>
    )
}

export default Favoritos;