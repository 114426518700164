import { useState } from "react";

function ProdutoItemRadio(props) {
    const [checkboxAtivo, setCheckboxAtivo] = useState(false);

    const handleCheckboxClick = (opcao) => {
        if (checkboxAtivo === opcao.id_item) {

            setCheckboxAtivo(false);
            props.onClickItem([]);

        } else {

            setCheckboxAtivo(opcao.id_item);
            props.onClickItem(opcao);
        }
    };


    return (
        <div className="card rounded-0 border-start-0 border-end-0">
            {/* <div className="card-header d-flex justify-content-between">
                {props.titulo}

                {props.obrigatorio ? <span className="badge bg-secondary" style={{ lineHeight: 'inherit' }}>OBRIGATÓRIO</span> : <span className="badge bg-secondary" style={{ lineHeight: 'inherit' }}>OPCIONAL</span>}
            </div> */}
            <div className="card-header d-flex align-items-center">
                <span className='text-uppercase'><b>{props.titulo}</b></span>
                <small className='px-2'>{props.descricao_opcao}</small>
                <div className='ms-auto'>
                    {props.obrigatorio ?
                        <small className="badge bg-secondary" style={{ lineHeight: 'inherit', padding: '.17em .32em' }}>OBRIGATÓRIO</small> :
                        <small className="badge bg-secondary" style={{ lineHeight: 'inherit', padding: '.17em .32em' }}>OPCIONAL</small>
                    }
                </div>
            </div>

            <ul className="list-group list-group-flush">
                {props.opcoes.map(opcao => {
                    return <label className="list-group-item d-flex justify-content-between" key={opcao.id_item} htmlFor={`flexRadioDefault${opcao.id_item}`}>
                        <div>
                            <input
                                className="form-check-input"
                                type="checkbox"
                                name={`flexRadioDefault${opcao.id_opcao}`}
                                id={`flexRadioDefault${opcao.id_item}`}
                                checked={checkboxAtivo === opcao.id_item}
                                onChange={() => handleCheckboxClick(opcao)}
                            />

                            <span className="ms-2">
                                {opcao.nome_item}
                            </span>
                        </div>
                        <div>
                            {opcao.vl_item > 0 ?
                                <span className={`${localStorage.getItem('sessionMode') === 'light' ? 'text-danger' : 'text-secondary'}`}>{
                                    new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(opcao.vl_item)
                                }</span>
                                : null
                            }
                        </div>
                    </label>
                })

                }
            </ul>

        </div>
    )
}

export default ProdutoItemRadio;