import { useContext, useEffect, useState } from "react";
import {
  Navbar,
  Categoria,
  Banner,
  Estabelecimento,
  Footer,
} from "../../components";
import api from "../../services/api";

import { Tabs } from "@mui/material";
import { CartContext } from "../../contexts/cart";

import img from "../../assets/favicon.ico"
import { BASE_URL } from "../../constants/BASE_URL";

function Home() {
  const [categorias, setCategorias] = useState([]);
  const [banners, setBanners] = useState([]);
  const [grupos, setGrupos] = useState([]);
  const [destaques, setDestaques] = useState([]);

  const [valueCategoria, setValueCategoria] = useState(0);
  const handleChangeCategoria = (event, newValue) => {
    setValueCategoria(newValue);
  };
  const [valueBanner, setValueBanner] = useState(0);
  const handleChangeBanner = (event, newValue) => {
    setValueBanner(newValue);
  };

  const { setHomeTrue, setNomeEmpresa, setCheckoutEtapa,setTelaHome } = useContext(CartContext);

  setCheckoutEtapa(0)

  // setNomeEmpresa("Home");

  setTelaHome("Home")

  setHomeTrue(true);

  useEffect(() => {
    api
      .get(
        "v1/categorias?cod_cidade=" + localStorage.getItem("sessionCodCidade")
      )
      .then((response) => {
        setCategorias(response.data);
      })
      .catch((err) => {
        console.log(err);
      });

    api
      .get("v1/banners?cod_cidade=" + localStorage.getItem("sessionCodCidade"))
      .then((response) => {

        setBanners(response.data);
      })
      .catch((err) => {
        console.log(err);
      });

    api
      .get(
        `v1/destaques?cod_cidade=${localStorage.getItem("sessionCodCidade")}`
      )
      .then((response) => {
        let gruposUnico = response.data.map((grupo) => grupo.descricao);

        gruposUnico = gruposUnico.filter((itemArray, i, arrayCompleto) => {
          return arrayCompleto.indexOf(itemArray) === i;
        });

        setGrupos(gruposUnico);
        setDestaques(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const ativarNotificacoes = () => {
    // Verifica se o browser suporta notificações
    if (!("Notification" in window)) {
      alert("Este browser não suporta notificações de Desktop");
    }

    // Let's check whether notification permissions have already been granted
    else if (Notification.permission === "granted") {
      // If it's okay let's create a notification
      var notification = new Notification("Hi there!");
    }

    // Otherwise, we need to ask the user for permission
    else if (Notification.permission !== "denied") {
      Notification.requestPermission(function (permission) {
        // If the user accepts, let's create a notification
        if (permission === "granted") {
          var notification = new Notification("Hi there!");
        }
      });
    }

    // At last, if the user has denied notifications, and you
    // want to be respectful there is no need to bother them any more.
  }


  return (
    <>
      <Navbar />

      <div
        className="d-flex justify-content-center mt-3"
        style={{ paddingTop: "20px" }}
      >
        <Tabs
          value={false}
          onChange={handleChangeCategoria}
          textColor="inherit"
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          aria-label="nav categories"
        >
          {categorias.map((categoria) => {
            return (
              <Categoria
                key={categoria.id_categoria}
                url_imagem={
                  localStorage.getItem("sessionMode") === "light"
                    ? categoria.foto
                    : categoria.foto_dark
                }
                descricao={categoria.categoria}
                id_categoria={categoria.id_categoria}
              />
            );
          })}
        </Tabs>
      </div>

      <div className="d-flex justify-content-center mt-5">
        <Tabs
          value={false}
          onChange={handleChangeBanner}
          textColor="inherit"
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          aria-label="navigation for banners"
        >
          {banners.map((banner) => (
            <Banner
              key={banner.id_banner}
              url_imagem={banner.foto}
              descricao={banner.descricao}
              id_banner={banner.id_banner}
            />
          ))}
        </Tabs>
      </div>
      <div className="container-fluid" style={{ padding: 30 }}>
        {grupos.map((grupo) => {
          return (
            <div key={grupo} className="mt-5">
              <h4 style={{ paddingBottom: 30 }}>{grupo}</h4>
              <div className="container-fluid">
                <div className="row">
                  {destaques.map((destaque) => {
                    return destaque.descricao === grupo ? (
                      <Estabelecimento
                        key={destaque.id_estabelecimento}
                        url_imagem={destaque.url_logo && BASE_URL + destaque.url_logo}
                        nome={destaque.nome}
                        avaliacao={destaque.avaliacao}
                        categoria={destaque.categoria}
                        id_estabelecimento={destaque.id_estabelecimento}
                        situacao={destaque.situacao}
                      />
                    ) : null;
                  })}
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <Footer />
    </>
  );
}

export default Home;
