import Modal from 'react-bootstrap/Modal'
import CloseIcon from '@mui/icons-material/Close';

function CoverModal(props) {
    return (
        <>
            <Modal
                show={props.isOpen}
                onHide={() => { props.onRequestClose(); }}
                id='cover-modal'
                backdropClassName='backdropFilter'
            >
                <Modal.Header style={{ position: 'relative', alignItems: 'inherit', borderBottom: 'none' }}>
                    <Modal.Title style={{ width: '100%', fontSize: 'inherit', fontWeight: 'inherit', alignItems: 'inherit' }}>

                        <div className='d-flex align-items-center justify-content-between'>
                            <h6 className="mt-1 m-0 cursor-default">{props.nome}</h6>

                            <button 
                                onClick={() => { props.onRequestClose(); }} 
                                className='btn btn-sm btn-link text-danger btn-fechar' 
                                style={{ position: 'absolute', right: 8, top: 8 }}
                            >
                                <CloseIcon style={{ fontSize: 32 }} />
                            </button>
                        </div>

                    </Modal.Title>
                </Modal.Header>

                <Modal.Body className='p-0'>
                    <img src={props.cover} alt='' className='img-fluid' style={{ display: 'flex', margin: 'auto', maxHeight: '512px', borderBottomLeftRadius: '4px', borderBottomRightRadius: '4px' }} />
                </Modal.Body>
            </Modal>
        </>
    )
}

export default CoverModal