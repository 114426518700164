import { useEffect, useState } from "react";
import { Navbar } from '../../components'
import Pedido from "../../components/pedido";
import api from '../../services/api';
import { BASE_URL } from "../../constants/BASE_URL";

function Pedidos() {

    const [pedidos, setPedidos] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await api.get('/v1/pedidos');
                setPedidos(response.data);
            } catch (err) {
                console.error(err);
            }
        };

        fetchData();

        const intervalId = setInterval(fetchData, 3000);

        return () => clearInterval(intervalId);
    }, []);

    return (

        <>
            <Navbar />

            <div className="container-fluid" style={{ paddingTop: '20px' }}>
                <div className="row col-lg-8 offset-lg-2">

                    <div className="col-12 mt-2">
                        <h2>Meus Pedidos</h2>
                        {pedidos.length === 0 && <small className="text-secondary">Você não possui pedidos.</small>}
                    </div>

                    <div className="mt-3">
                        {
                            pedidos.map(pedido => {
                                return <Pedido
                                    key={pedido.id_pedido}
                                    url_imagem={BASE_URL + pedido.url_logo}
                                    avaliacao={pedido.avaliacao}
                                    qtd_item={pedido.qtd_item}
                                    id_pedido={pedido.id_pedido}
                                    vl_total={pedido.vl_total}
                                    dt_pedido={pedido.dt_pedido}
                                    id_estabelecimento={pedido.id_estabelecimento}
                                    status={pedido.status}
                                />
                            })
                        }
                    </div>

                </div>
            </div>
        </>
    )

}

export default Pedidos;