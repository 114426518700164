import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CartContext } from "../../contexts/cart";
import { ToastContainer } from "react-toastify";

import "../../styles/scrollCart.css";

import { StatusPedidoCheckout } from "../../components/checkout/statusPedido";

import LightLogo from "../../assets/logo-grande.png";
import DarkLogo from "../../assets/logo-dark.png";

import Modal from "react-bootstrap/Modal";
import { FooterStatusCheckout } from "../../components/checkout/checkoutFooter/status";
import { PedidoFinalizado } from "../../components/checkout/pedidoFinalizado";
import pattern from '../../assets/patternBorrado.png'
import { Card, CardContent, CircularProgress, Skeleton, Typography } from "@mui/material";
import { BASE_URL } from "../../constants/BASE_URL";

import Logo from "../../assets/gerence.png"

export const CheckoutStatus = () => {
  const navigate = useNavigate();
  const { homeTrue, isMobile, darkMode, nomeEmpresaParams, statusApi } =
    useContext(CartContext);

  const [cardSelecionado, setCardSelecionado] = useState(statusApi.length - 1)
  const [showProgress, setShowProgress] = useState(true);
  const [showMessage, setShowMessage] = useState(false);

  const cidade = localStorage.getItem("sessionCidade").toLowerCase()
  const imgEmpresa = BASE_URL + `/assets/img/estabelecimentos/${cidade}/${(nomeEmpresaParams + '.png')}`


  useEffect(() => {
    const timer = setTimeout(() => {
      setShowProgress(false);
      setShowMessage(true);
    }, 6000); // 6 segundos

    // Lembre-se de limpar o timer quando o componente for desmontado
    return () => clearTimeout(timer);
  }, [statusApi?.status !== false]);

  useEffect(() => {

    setCardSelecionado(statusApi.length - 1)

  }, [statusApi?.length])


  PedidoFinalizado()


  return (

    <>
      <Modal
        id="ads"
        show
        scrollable={true}
        centered="true"
        fullscreen
        backdrop="static"
      >
        <Modal.Header>
          <img src={darkMode === "dark" ? DarkLogo : LightLogo} width={130} />
          {nomeEmpresaParams ? <img src={imgEmpresa} width={50} style={{ marginRight: 30, borderRadius: 100 }} /> : <img src={Logo} width={80} style={{ marginRight: 30 }} />}
        </Modal.Header>

        <Modal.Body style={isMobile ? { backgroundImage: `url(${pattern})` } : { backgroundImage: `url(${pattern})`, display: "flex" }}>
          <div style={isMobile ? {} : { margin: "0 50px", backgroundColor: "#ffffff4b7f" }}>
            {/* <div style={isMobile ? {} : { margin: "0 500px", backgroundColor: "#ffffff4b7f", display: "grid" }}> */}


            <div id="scrollStatus" style={{ display: "flex", flexDirection: "row", gap: 30, overflowX: 'auto', overflowY: "auto", width: "90vw" }}>

    
              {

                statusApi.status !== false && statusApi.map((item, index) => {
                  let status = item.pedido.STATUS

                  return <Card sx={{ borderRadius: 2, minWidth: 300, backgroundColor: cardSelecionado === index ? "#f0f0f0" : "#fafafa", cursor: "pointer", display: 'flex', justifyContent: 'space-around', flexDirection: 'column', marginBottom: 1 }} onClick={() => setCardSelecionado(index)}>

                    <CardContent style={{ padding: 0, padding: "15px 0 0 15px",}}>
                      <Typography variant="h5" sx={{
                        fontSize: 20, fontWeight: 600, display: 'flex',

                        color: status == "0" ? "#c90202" :
                          status == "1" ? "#ed7d05" :
                            status == "2" ? "#e8c902" :
                              status == "3" ? "#1ec900" : "#000"

                      }} component="div" color={"#444"}>
                        <div style={{
                          width: 10, height: 10, borderRadius: 100, backgroundColor:

                            status == "0" ? "#c90202" :
                              status == "1" ? "#ed7d05" :
                                status == "2" ? "#e8c902" :
                                  status == "3" ? "#1ec900" : "#000"

                          , marginRight: 10, marginTop: 5
                        }} />
                        {status === '0' ? "Pedido cancelado" : status === '1' ? "Pedido pendente" : status === '2' ? "Pedido recebido" : status === '3' ? "Motoboy na estrada" : ""}
                      </Typography>

                      <ul style={{ marginTop: 5 }}>

                        {
                          Object.values(item?.pedido.itens).map((objeto) =>  <li style={{ fontSize: 12 }}> {objeto.NOME} </li>)

                        }
                      </ul>

                    </CardContent>
                    <div style={{ display: 'flex', flexDirection: 'row', gap: 2, justifyContent: 'center', alignContent: 'center' }}>
                      <p style={{ textAlign: "end", marginRight: 10, fontWeight: 200, fontSize: 14 }}>  {new Date(item?.pedido?.DT_PEDIDO).toLocaleString("pt-BR", { timeZone: "America/Sao_Paulo" })}</p>
                      <Typography variant="p" component="div" color={"#333"}>
                        Total : {item?.pedido.VL_TOTAL.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                      </Typography>
                    </div>
                  </Card>
                }).reverse()

              }

              {
                statusApi?.status === false && (
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    {showMessage && (
                      <div style={{ textAlign: 'center', marginTop: '20px', fontWeight: 600, color: "#444" }}>
                        Ainda sem pedidos! 😋 Que tal fazer um agora e desfrutar de uma refeição incrível? 🍔🍕🍣
                      </div>
                    )}
                    {showProgress && (
                      <div style={{ width: "50vh", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <CircularProgress color="secondary" />
                      </div>
                    )}
                  </div>
                )
              }
            </div>

            <ToastContainer containerId="toast-container" />

            <StatusPedidoCheckout statusApi={statusApi && statusApi[cardSelecionado]} />


          </div>
        </Modal.Body>

        <Modal.Footer style={isMobile ? {} : { margin: "0 500px" }}>
          <FooterStatusCheckout
            navigate={navigate}
            homeTrue={homeTrue}
            nomeEmpresaParams={nomeEmpresaParams}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};
