function ProdutoItemCheckbox(props) {

    return (
        <div className="card rounded-0 border-start-0 border-end-0">
            <div className="card-header d-flex align-items-center justify-content-between">

                <div className='d-flex align-items-center'>
                    <span className='text-uppercase'><b>{props.titulo}</b></span>
                    <small className='px-2'>{props.descricao_opcao}</small>
                </div>
                <div className='ms-auto'>
                    {props.obrigatorio ? 
                        <small className="badge bg-secondary" style={{ lineHeight: 'inherit', padding: '.17em .32em' }}>OBRIGATÓRIO</small> : 
                        <small className="badge bg-secondary" style={{ lineHeight: 'inherit', padding: '.17em .32em' }}>OPCIONAL</small>
                    }
                </div>
            </div>

            <ul className="list-group list-group-flush">

                {props.opcoes.map(opcao => {
                    return (
                        <label className="list-group-item d-flex justify-content-between" key={opcao.id_item} htmlFor={`checkb-x${opcao.id_item}`}>
                            <div>
                                <input className="form-check-input" type="checkbox" value="" 
                                    id={`checkb-x${opcao.id_item}`} 
                                    onClick={(e) => props.onClickItem(e.target.checked, {
                                        id_opcao: opcao.id_opcao,
                                        nome: opcao.nome_item,
                                        id_item: opcao.id_item,
                                        vl_item: opcao.vl_item,
                                        ordem: opcao.ordem 
                                    })}/>
                                <span className="ms-2" >
                                    {opcao.nome_item}
                                </span>
                            </div>
                            
                            {opcao.vl_item > 0 && (
                                <div>
                                    <span className={`${localStorage.getItem('sessionMode') === 'light' ? 'text-danger' : 'text-secondary'}`}>{new Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(opcao.vl_item)}</span>
                                </div>
                            )}
                            
                        </label>
                    )
                })}
            </ul>

        </div>
    )
}

export default ProdutoItemCheckbox;