import icon from '../../../assets/icon.png'
import iconDark from '../../../assets/icon-dark.png'

function Produto(props){
    let pizzaQuantidade = 0 
    
    props.detalhes.map((item) => {
        pizzaQuantidade += item.quantidade
    })


    return <div > 
        <div className="row"  >

                {/* produtos do carrinho pc */}

                {props.tp_sabor !== 'S' && props.tp_sabor !== 'D' && (
                    <ul className='p-0 m-0'>
                    
                        <div className="d-flex align-items-start justify-content-between py-1 border-bottom">
                            <small className='pe-3'>

                                {props.qtd > 0 ? `${new Intl.NumberFormat('pt-BR', {minimumIntegerDigits: 2}).format(props.qtd)} x ` : null}<b>{props.nome}</b>

                            </small>
                            <div className='d-flex align-items-center'>
                                <small>
                                    {new Intl.NumberFormat('pt-BR', {style: 'currency',currency: 'BRL'}).format(props.valor_total)}
                                    
                                </small>
                                <a role='button' onClick={(e) => props.onClickRemover(props.id_carrinho)} className="d-flex ms-2 text-danger">
                                    <i className='fas fa-trash' style={{ fontSize: '16px', height: '16px' }} />
                                </a>
                            </div>
                        </div>

                    </ul>
                )}

                {props.detalhes && (
                    <ul className='p-0 m-0'>
                        {props.detalhes.map((detalhe, i) => {
                            return (
                                <div key={`${detalhe.nome}-${i}`}>
                                    <li id={`${detalhe.nome}-${i}`} className='d-flex align-items-center py-1'>
                                        <small className='ps-4 ms-1'>
                                            {detalhe.quantidade > 0 ? `${detalhe.quantidade} ${pizzaQuantidade && '/ ' + pizzaQuantidade || "x"} - ` : null}
                                            {detalhe.nome ? detalhe.nome : detalhe.detalhe_nome}
                                        </small>
                                    </li>
                                </div>
                            )
                        })}
                    </ul>
                )}

                {props.tp_sabor && (
                    <div className='d-flex flex-column'>
                        <div className="d-flex align-items-start justify-content-between py-1 border-bottom">
                            <small className='pe-3'>
                                {props.qtd > 0 ? `${new Intl.NumberFormat('pt-BR', {minimumIntegerDigits: 2}).format(props.qtd)} x ` : null}
                                
                                <b>
                                    {props.tamanho === 'P' && 'Pizza P'}
                                    {props.tamanho === 'M' && 'Pizza M'}
                                    {props.tamanho === 'G' && `Pizza G`}
                                </b>

                            </small>
                            <div className='d-flex align-items-center'>
                                <small>
                                    {new Intl.NumberFormat('pt-BR', {style: 'currency',currency: 'BRL'}).format(props.valor_total)}
                                    
                                </small>
                                <a role='button' onClick={(e) => props.onClickRemover(props.id_carrinho)} className="d-flex ms-2 text-danger">
                                    <i className='fas fa-trash' style={{ fontSize: '16px', height: '16px' }} />
                                </a>
                            </div>
                        </div>
                
                        {props.tipo === 'I' && (
                            <li className='d-flex align-items-center py-1'>
                                <small className='ps-4 ms-1'>
                                    <div>
                                        <div>{props.qtd}/{props.quantity_flavors} {props.nome}</div>
                                    </div>
                                </small>
                            </li>
                        )}
                        
                    </div>
                )}

                {props.removidos ? (
                    <ul className='p-0 m-0'>
                        {props.removidos.map((removido, i) => (
                            <li key={`${i}`} id={`${i}`} className='d-flex align-items-center justify-content-between py-1 border-bottom'>
                                <small className='ps-4 ms-1' key={removido.descricao}>S/ {removido.descricao}</small>
                            </li>
                        ))}
                    </ul>
                ) : null
                }

                {props.bordas && (
                    <ul className='p-0 m-0'>
                        {props.bordas.map((borda, i) => (
                            <li key={`${i}`} id={`${i}`} className='d-flex align-items-center justify-content-between py-1 border-bottom'>
                                <small className='ps-4 ms-1' key={borda.nome}>Borda {borda.nome}</small>
                            </li>
                        ))}
                    </ul>
                )}

                {props.observacao ?
                    (
                        <div className='ps-4 ms-1'>
                            <small className="d-block mt-1"><b>Observação:</b></small>
                            <small className="d-block">{props.observacao}</small>
                        </div>
                    )
                    : null
                }

                {props.onClickRemover ? 
                    <button onClick={(e) => props.onClickRemover(props.id_carrinho)} className="btn btn-sm btn-outline-danger mt-2 d-none">
                        <i className='fas fa-trash' />
                    </button>
                : null
                }

            </div>
        </div>
}

export default Produto;