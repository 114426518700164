import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/logo.png";
import GerenceLogo from "../../assets/gerence.png";
import "./style.css";
import api from "../../services/api";
import SaltPassword from "../../services/md5";
import axios from "axios";

function Cadastro() {
  const navigate = useNavigate();
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [senha, setSenha] = useState("");
  const [senha2, setSenha2] = useState("");
  const [endereco, setEndereco] = useState("");
  const [complemento, setComplemento] = useState("");
  const [bairro, setBairro] = useState("");
  const [cidade, setCidade] = useState("");
  const [uf, setUF] = useState("");
  const [codCidade, setCodCidade] = useState("");
  const [cep, setCep] = useState("");
  const [mensagem, setMensagem] = useState("");
  const [loading, setLoading] = useState(false);
  const [cidades, setCidades] = useState([]);

  const [numeroValido, setNumeroValido] = useState(false);
  const [mudando, setMudando] = useState(false);
  const [pegouInfoNumero, setPegouInfoNumero] = useState(false);
  const numeroTelefoneQuantidade = 11;
  const [usuarioExiste, setUsuarioExiste] = useState("");
  const [telefone, setTelefone] = useState("");

  const [enderecoTelefone, setEnderecoTelefone] = useState(true)

  const [idUsuario, setIdUsuario ] = useState("")

  async function EnderecoPorTelefoneApi() {
    try {
      const resultApi = await api.get(
        `v1/usuarios/login/${telefone}`
      );

      if (!!resultApi?.data) {
        localStorage.setItem("telefone", telefone);

        const { nome, cep, complemento } = resultApi?.data;

        setNome(nome);
        setCep(cep?.replace("-", ""));
        setComplemento(complemento);
        // setIdUsuario(id_usuario)

        setNumeroValido(true);
        setUsuarioExiste(true);
      } else {
        setNumeroValido(false);
        // atualizarSite();
        setUsuarioExiste(false);
      }
    } catch {
      setNumeroValido(false);
      // atualizarSite();
      setUsuarioExiste(false);
    }
  }



  async function EnderecoPorCep(cep) {
    try {
      // Cep para testes, pode ser que mude a api de buscar o cep
      const infoCep = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);

      return infoCep;
    } catch {
      alert("CEP não existe");
    }
  }

  function SalvarCidade(e) {
    const [cid, est] = e.target[e.target.selectedIndex].text.split(" - ");
    setCidade(cid);
    setUF(est);
    setCodCidade(e.target.value);
  }

  async function ProcessaCadastro(e) {
    e.preventDefault();
    setMensagem("");

    if (senha !== senha2) {
      setMensagem("As senhas não conferem. Digite novamente.");
      return;
    }

    setLoading(true);

    if (usuarioExiste) {
      try {

        const response = await api.patch(`/v1/usuarios/registro/${telefone}`, {
          nome,
          email,
          senha: senha.length > 0 ? SaltPassword(senha) : "",
          endereco,
          complemento,
          bairro,
          cidade,
          uf,
          cep,
          cod_cidade: codCidade,
          mode: "light",
          telefone
        }) 
        if (response.status === 201) {
          localStorage.setItem("sessionToken", response.data.token);
          localStorage.setItem("sessionId", response.data.id_usuario);
          localStorage.setItem("sessionEmail", email);
          localStorage.setItem("sessionCodCidade", codCidade);
          localStorage.setItem("sessionCidade", cidade);
          localStorage.setItem("sessionUF", uf);
          localStorage.setItem("sessionMode", "light");
          navigate("/");
        } else {
          setLoading(false);
          setMensagem("Ocorreu um erro no cadastro: " + response.data.erro);
        }

      } catch (err) {
        if (err.response) {
          setMensagem(err.response.data.erro);
        } else {
          setMensagem("Ocorreu um erro na requisição.");
        }
        setLoading(false);
      }


    } else{

      try {
        const response = await api.post("/v1/usuarios/registro", {
          nome,
          email,
          senha: senha.length > 0 ? SaltPassword(senha) : "",
          endereco,
          complemento,
          bairro,
          cidade,
          uf,
          cep,
          cod_cidade: codCidade,
          mode: "light",
          telefone
        }) 
        if (response.status === 201) {
          localStorage.setItem("sessionToken", response.data.token);
          localStorage.setItem("sessionId", response.data.id_usuario);
          localStorage.setItem("sessionEmail", email);
          localStorage.setItem("sessionCodCidade", codCidade);
          localStorage.setItem("sessionCidade", cidade);
          localStorage.setItem("sessionUF", uf);
          localStorage.setItem("sessionMode", "light");
          navigate("/");
        } else {
          setLoading(false);
          setMensagem("Ocorreu um erro no cadastro: " + response.data.erro);
        }

      } catch (err) {
        if (err.response) {
          setMensagem(err.response.data.erro);
        } else {
          setMensagem("Ocorreu um erro na requisição.");
        }
        setLoading(false);
      }
    }
  }

  useEffect(() => {
    api
      .get("v1/cidades")
      .then((response) => {
        setCidades(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    EnderecoPorTelefoneApi(telefone);

    if (telefone.length < 11 || nome === "") {
      setNumeroValido(false);
      setNome("");
      setCep("");
      setBairro("");
      setCidade("");
      setComplemento("");
      setPegouInfoNumero(false);

      setUF("");
      setEndereco("");
      // atualizarSite();
      setEnderecoTelefone(false);
    }
    
    if (cep.length < 8) {
      setBairro("");
      setCidade("");
      setUF("");
      setEndereco("");
      // atualizarSite();
      setEnderecoTelefone(false);
    }


    if (telefone.length === numeroTelefoneQuantidade) {
      if (cep.length === 8) {
        (async () => {
          const responseApi = await EnderecoPorCep(cep);
          const api = responseApi.data;
          setBairro(api.bairro);
          setEndereco(api.logradouro);
          setCidade(api.localidade);
          setUF(api.uf);
          // atualizarSite();

          if (!pegouInfoNumero) {
            setEnderecoTelefone(false);
          }
        })();
      }
    }
  }, [cep, telefone]);


  return (
    <div className="container-fluid">
      <div className="row">
        <div
          className="col-lg-6 d-flex justify-content-center align-items-center text-center"
          style={{
            boxShadow:
              "0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)",
            zIndex: 1020,
          }}
        >
          <form className="form-cadastro py-4">
            <h3 className="mb-4">
              Crie sua conta
              <br />e <span className="text-danger">faça seu pedido</span>.
            </h3>
            <h6 className="mb-3">Informe os dados abaixo</h6>

            <input
              style={{ fontWeight: "bold" }}
              type="text"
              value={telefone}
              onChange={(e) => {
                const regex = /^[0-9]{0,11}$/;
                const inputValue = e.target.value;
                if (regex.test(inputValue)) {
                  setTelefone(inputValue);
                }
              }}
              placeholder="Telefone"
              onBlur={() => {
                if (telefone.length === numeroTelefoneQuantidade) {
                  if (!pegouInfoNumero) {
                    EnderecoPorTelefoneApi(telefone);
                    setPegouInfoNumero(true);
                  }
                }
              }}
              className="form-control mb-2"
              id="InputTelefone"
              aria-describedby="Telefone"
            />

            <input
              type="text"
              style={{ fontWeight: "bold" }}
              onChange={(e) => setNome(e.target.value)}
              value={nome}
              className="form-control"
              id="nome"
              placeholder="Nome completo"
              disabled={!pegouInfoNumero}

            />

            <input
              type="email"
              style={{ fontWeight: "bold" }}
              onChange={(e) => setEmail(e.target.value)}
              className="form-control"
              id="email"
              placeholder="E-mail"
              disabled={!pegouInfoNumero}

            />

            <div className="row">
              <div className="col-lg-6">
                <input
                  type="password"
                  style={{ fontWeight: "bold" }}
                  onChange={(e) => setSenha(e.target.value)}
                  className="form-control"
                  id="senha"
                  placeholder="Senha"
                  disabled={!pegouInfoNumero}

                />
              </div>

              <div className="col-lg-6">
                <input
                  type="password"
                  style={{ fontWeight: "bold" }}
                  onChange={(e) => setSenha2(e.target.value)}
                  className="form-control"
                  id="confirmar-senha"
                  placeholder="Confirme a senha"
                  disabled={!pegouInfoNumero}

                />
              </div>
            </div>

            <div className="row">
              <div className=" col-12">
                <input
                  type="text"
                  style={{ fontWeight: "bold" }}
                  placeholder="CEP"
                  onChange={(e) => {
                    setMudando(!mudando);
                    const regex = /^[0-9]{0,8}$/;
                    const inputValue = e.target.value;
                    if (regex.test(inputValue)) {
                      setCep(inputValue);
                    }
                  }}
                  value={cep}
                  className="form-control"
                  id="InputNome"
                  aria-describedby="nome"
                  disabled={!pegouInfoNumero}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-8 d-inline-block">
                <input
                  style={{ fontWeight: "bold" }}
                  placeholder="Endereço"
                  type="text"
                  onChange={(e) => {
                    setMudando(!mudando);

                    setEndereco(e.target.value);
                  }}
                  value={endereco}
                  className="form-control mb-2"
                  id="InputNome"
                  aria-describedby="nome"
                  disabled
                />
              </div>
              <div className=" col-4 d-inline-block">
                <input
                  type="text"
                  style={{ fontWeight: "bold" }}
                  placeholder="Complemento"
                  onChange={(e) => {
                    setMudando(!mudando);

                    setComplemento(e.target.value);
                  }}
                  value={complemento}
                  className="form-control mb-2"
                  id="InputNome"
                  aria-describedby="nome"
                  disabled={
                    telefone.length !== numeroTelefoneQuantidade ||
                    cep.length !== 8
                      ? true
                      : false
                  }
                />
              </div>
            </div>

            <div className="row">
              <div className=" col-12">
                <input
                  style={{ fontWeight: "bold" }}
                  placeholder="Bairro"
                  type="text"
                  onChange={(e) => {
                    setMudando(!mudando);

                    setBairro(e.target.value);
                  }}
                  value={bairro}
                  className="form-control mb-2"
                  id="InputNome"
                  aria-describedby="nome"
                  disabled
                />
              </div>
            </div>

            <div className="row">
              <div className=" col-12">
                <input
                  style={{ fontWeight: "bold" }}
                  placeholder="Cidade"
                  type="text"
                  className="form-control mb-2"
                  aria-describedby="nome"
                  name="cidades"
                  id="cidades"
                  onChange={(e) => {
                    setMudando(!mudando);
                    setCidade(e.target.value);
                  }}
                  value={cidade}
                  disabled
                />
              </div>
            </div>

            <button
              style={{ marginTop: 20 }}
              onClick={ProcessaCadastro}
              className="w-100 btn btn-lg btn-danger"
              disabled={loading}
            >
              {loading ? (
                <div>
                  <span
                    className="spinner-border spinner-border-sm text-light"
                    role="status"
                  ></span>
                  <span className="ms-2">Enviando...</span>
                </div>
              ) : (
                <span className="ms-2">Criar conta</span>
              )}
            </button>

            {mensagem.length > 0 ? (
              <div className="alert alert-danger mt-2" role="alert">
                {mensagem}
              </div>
            ) : null}

            <div className="mt-5">
              Já tenho uma conta.{" "}
              <Link to="/login" className="text-danger text-decoration-none">
                Fazer login!
              </Link>
            </div>

            <Link to="/">
              <img
                src={Logo}
                alt="Comer Aqui"
                width={196}
                height="auto"
                className="mt-5"
              />
            </Link>

            <div className="mt-5">
              <p className="text-secondary small">desenvolvimento</p>
              <a
                href="http://gerencesistemas.com.br/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={GerenceLogo}
                  alt="Gerence Sistemas"
                  width={128}
                  height="auto"
                />
              </a>
            </div>
          </form>
        </div>

        <div
          className="col-lg-6 px-0 d-none d-lg-block"
          style={{
            background: `url(https://source.unsplash.com/random/?food)`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
      </div>
    </div>
  );
}

export default Cadastro;
