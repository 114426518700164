import Produto from "../../produto/sacola";
import { TiShoppingCart } from "react-icons/ti";

export const CarrinhoCheckout = ({
  cart,
  nomeEmpresaParams,
  RemoveItemCart,
  navigate,
}) => {
  return (
    <>
      <h4
        className="m-0"
        style={{
          padding: "2px 20px 0 20px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <TiShoppingCart style={{ fontSize: 25, color: "#b8241a", marginRight: 10 }} /> 
        Carrinho
      </h4>

      <div style={{ height: "85%" }}>
        <div
          id="scrollFinalizeSeuPedido"
          style={{ overflowY: "auto", padding: 10 }}
        >
          {cart.length == 0 ? (
            <div style={{ textAlign: "center"}}>
              <p style={{ marginTop: 50 }}>Nenhum produto no carrinho.</p>
    
            </div>
          ) : (
            <div>
              {cart.map((item) => (
                <div key={item.id_carrinho}>
                  <Produto
                    nome={item.nome}
                    valor_total={item.vl_unit * item.qtd}
                    qtd={item.qtd}
                    valor_unit={item.vl_unit}
                    id_carrinho={item.id_carrinho}
                    url_foto={item.url_foto}
                    onClickRemover={RemoveItemCart}
                    removidos={item.removidos}
                    detalhes={item.detalhes}
                    observacao={item.observacao}
                    bordas={item.bordas}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
