import { createContext, useEffect, useRef, useState } from "react";
import api from "../services/api";
import statusMusic from "../assets/music/newStatus.mp3";
import { toast } from "react-toastify";
import { TimelineDot } from "@mui/lab";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

import {
  green,
  orange,
  blue,
  red,
  deepOrange,
  yellow,
} from "@mui/material/colors";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

import cooking from "../assets/img/CheckoutStatus/cooking.svg";
import motoboy from "../assets/img/CheckoutStatus/motoboy.svg";
import menu from "../assets/img/CheckoutStatus/menu.svg";
import menuIco from "../assets/img/CheckoutStatus/menu.ico";
import timeIco from "../assets/img/CheckoutStatus/time.ico";
import motoboyIco from "../assets/img/CheckoutStatus/motoboy.ico";
import cookingIco from "../assets/img/CheckoutStatus/cooking.ico";
import errado from "../assets/img/CheckoutStatus/errado.ico";
import certo from "../assets/img/CheckoutStatus/certo.ico";
import { Link, useNavigate } from "react-router-dom";


const CartContext = createContext({});

function CartProvider(props) {
  const [cart, setCart] = useState([]);
  const [subtotalCart, setSubtotalCart] = useState(0);
  const [descontoCart, setDescontoCart] = useState(0);
  const [entregaCart, setEntregaCart] = useState(0);
  const [idCupomCart, setIdCupomCart] = useState(0);
  const [cupomCart, setCupomCart] = useState("");
  const [msgCart, setMsgCart] = useState("");
  const [totalCart, setTotalCart] = useState(0);
  const [idEstabelecimentoCart, setIdEstabelecimentoCart] = useState(0);
  const [isMobile, setisMobile] = useState(null);
  const [telaHome, setTelaHome] = useState("");
  const [nomeEmpresa, setNomeEmpresa] = useState("");
  const [nomeEmpresaParams, setNomeEmpresaParams] = useState("");
  const [homeTrue, setHomeTrue] = useState(false);
  const [checkoutEtapa, setCheckoutEtapa] = useState(0);
  const [valorTroco, setValorTroco] = useState("");
  const [imagemEmpresa, setImagemEmpresa] = useState("");
  const [pagamento, setPagemento] = useState("");
  const [formaDeTrabalho, setFormaDeTrabalho] = useState("A");
  const [formaDePagamento, setFormaDePagamento] = useState("E");

  const darkMode = localStorage.getItem("sessionMode");

  const [finalizarPC, setFinalizarPC] = useState(false);
  const [itens, setItens] = useState([]);

  const [formFinalizarPedido, setFormFinalizarPedido] = useState({});

  const [statusApi, setStatusApi] = useState({ status: false });

  // const [statusApiCompare, setStatusApiCompare] = useState(statusApi);


  // useEffect(() => {
  //   if (statusApi && statusApi?.status != false && parseFloat(statusApi.status) != parseFloat(statusApiCompare)) {
  //     setStatusApiCompare(statusApi.status);
  //   }
  // }, [statusApi]);

  useEffect(() => {
    const interval = setInterval(() => {
      (async () => {
        if (localStorage.getItem("sessionToken")) {
          await api.get(
            "/v1/estabelecimentos/status/pedido"
          );
          const resultStatus = await api.get(
            "/v1/estabelecimentos/info/pedidos"
          );

          setStatusApi(resultStatus.data);

          if (
            resultStatus.data.status !== false &&
            resultStatus.data.status !== undefined
          ) {
            setStatusApi(resultStatus.data);
          }
        }
      })();
    }, 5000); // Atualiza a cada 15 segundos em milissegundos ou seja 15000, porem para agilizar o processo coloquei 3 segundos

    // Limpar o intervalo quando o componente for desmontado ou quando o intervalo for reconfigurado
    return () => {
      clearInterval(interval);
    };
  }, []); // Mantenha o array vazio para executar o efeito a cada montagem inicial

  let formPedidoFinalizado = {
    id_estabelecimento: idEstabelecimentoCart,
    id_cupom: idCupomCart ?? 0,
    vl_taxa_entrega: entregaCart,
    vl_desconto: descontoCart,
    vl_total: totalCart,
    itens: cart,

    // pagamento
    vl_troco: formFinalizarPedido.vl_troco ? formFinalizarPedido.vl_troco : 0,
    cl_pagamento: pagamento,

    // entrega
    bairro: formFinalizarPedido.endereco
      ? formFinalizarPedido.endereco.bairro
      : "",
    cep: formFinalizarPedido.endereco ? formFinalizarPedido.endereco.cep : "",
    cidade: formFinalizarPedido.endereco
      ? formFinalizarPedido.endereco.cidade
      : "",
    cod_cidade: formFinalizarPedido.endereco
      ? formFinalizarPedido.endereco.cod_cidade
      : "",
    complemento: formFinalizarPedido.endereco
      ? formFinalizarPedido.endereco.complemento
      : "",
    endereco: formFinalizarPedido.endereco
      ? formFinalizarPedido.endereco.endereco
      : "Retirar",
    uf: formFinalizarPedido.endereco ? formFinalizarPedido.endereco.uf : "",
  };

  const detect_mobile = () => {
    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) {
      setisMobile(true);
    } else {
      setisMobile(false);
    }
  };

  function AddItemCart(item) {
    let cartTemp = cart;
    cartTemp.push(item);

    setCart([...cartTemp]);
    setItens([itens, ...cartTemp]);
  }

  function RemoveItemCart(id) {
    const novoCart = cart.filter((item, index, arr) => {
      return item.id_carrinho != id;
    });

    setCart(novoCart);
    setItens(novoCart);
  }

  async function ValidarCupom() {
    setMsgCart("");
    setItens(cart);

    const response = await api.get("v1/cupons/validacao", {
      params: {
        cod_cupom: cupomCart,
        valor: Math.trunc(subtotalCart * 100),
        id_estabelecimento: idEstabelecimentoCart,
      },
    })

    // console.log(response)

    if(response?.data?.error){
      toast.error(response?.data?.error)
      setIdCupomCart(0);
      setDescontoCart(0);
      setMsgCart(response?.data?.error);

      setCupomCart('')
    }

    if (!response?.data?.error ) {

      const {porc_cupom, vl_cupom, id_cupom } = response.data[0]
  
      setIdCupomCart(id_cupom);
      setDescontoCart(vl_cupom + (subtotalCart * porc_cupom) / 100);
    

    } else {
      setIdCupomCart(0);
      setDescontoCart(0);
      setMsgCart("Cupom inválido");
 
    }


  }

  useEffect(() => {
    setCart(itens);
    setCupomCart(cupomCart);
    setIdEstabelecimentoCart(idEstabelecimentoCart);
    setEntregaCart(entregaCart);
    setIdCupomCart(idCupomCart);
  }, []);

  useEffect(() => {
    if (cupomCart.length > 0) {
      ValidarCupom();
    }
  }, [subtotalCart]);

  useEffect(() => {
    setMsgCart("");
  }, [cupomCart]);

  useEffect(() => {
    if (cart.length === 0) {
      setSubtotalCart(0);
    } else {
      const soma = cart.reduce((a, b) => {
        const vlUnit = parseFloat(b.vl_unit);
        const qtd = parseInt(b.qtd);

        if (!isNaN(vlUnit) && !isNaN(qtd)) {
          return a + (vlUnit * qtd);
        } else {
          return a;
        }
      }, 0);

      setSubtotalCart(soma);
    }
  }, [cart]);
  useEffect(() => {
    setTotalCart(subtotalCart - descontoCart + entregaCart);
  }, [subtotalCart, descontoCart, entregaCart]);

  useEffect(() => {
    window.scrollTo(0, 0);
    detect_mobile();
  }, []);

  return (
    <CartContext.Provider
      value={{
        cart,
        setCart,
        subtotalCart,
        setSubtotalCart,
        descontoCart,
        setDescontoCart,
        entregaCart,
        setEntregaCart,
        idCupomCart,
        setIdCupomCart,
        totalCart,
        setTotalCart,
        idEstabelecimentoCart,
        setIdEstabelecimentoCart,
        AddItemCart,
        RemoveItemCart,
        ValidarCupom,
        cupomCart,
        msgCart,
        setCupomCart,
        setMsgCart,
        isMobile,
        nomeEmpresa,
        setNomeEmpresa,
        finalizarPC,
        setFinalizarPC,
        nomeEmpresaParams,
        setNomeEmpresaParams,
        setHomeTrue,
        homeTrue,
        darkMode,
        setCheckoutEtapa,
        checkoutEtapa,
        valorTroco,
        setValorTroco,
        formFinalizarPedido,
        setFormFinalizarPedido,
        formPedidoFinalizado,
        imagemEmpresa,
        setImagemEmpresa,
        statusApi,
        pagamento,
        setPagemento,
        formaDeTrabalho,
        setFormaDeTrabalho,
        formaDePagamento,
        setFormaDePagamento,
        telaHome, 
        setTelaHome
      }}
    >
      {props.children}
    </CartContext.Provider>
  );
}

export { CartContext, CartProvider };
