import "./style.css";
import { Link } from "react-router-dom";
import icon from "../../assets/icon.png";
import iconDark from "../../assets/icon-dark.png";
import { useContext } from "react";
import { CartContext } from "../../contexts/cart";
import { FormatarNomeUrl } from "../../hook/FormatarNome";

function Estabelecimento(props) {
  const { setNomeEmpresa } = useContext(CartContext);

  const modNomeEmpresa = FormatarNomeUrl(props.nome);

  const setDateNameEmpresa = () => {
    setNomeEmpresa(modNomeEmpresa);
  };

  return (
    <div
      className={`estabelecimento col-sm-6 col-md-4 col-xl-3 col-lg-${
        props.colFavorito ? "6" : "3"
      } mb-3 p-2`}
    >
      <Link
        onClick={() => {
          setDateNameEmpresa(modNomeEmpresa);
        }}
        to={`/${modNomeEmpresa}`}
      >
        <div className="row">
          <div className="col-3">
            <div className="d-flex align-items-start justify-content-center h-100 m-auto">
              {props.url_imagem ? (
                <>
                  <img
                    className="img-estabelecimento rounded"
                    src={props.url_imagem}
                    alt="Logotipo"
                  />
                </>
              ) : (
                <>
                  {localStorage.getItem("sessionMode") === "light" ? (
                    <img
                      className="img-fluid rounded"
                      src={icon}
                      alt="Sem imagem"
                      style={{ opacity: 0.35, width: "auto", maxHeight: 96 }}
                    />
                  ) : (
                    <img
                      className="img-fluid rounded"
                      src={iconDark}
                      alt="Sem imagem"
                      style={{ opacity: 0.35, width: "auto", maxHeight: 96 }}
                    />
                  )}
                </>
              )}
            </div>
          </div>

          <div className="col-9">
            <div className="row align-items-center">
              <div className="col">
                <span>{props.nome}</span>

                <div className="avaliacao">
                  <i className="fas fa-star text-warning" />
                  <span>
                    {props.avaliacao.toFixed(1)} - {props.categoria}
                  </span>
                </div>

                <div>
                  {props.situacao === 0 ? (
                    <span className="text-danger small">Fechado</span>
                  ) : (
                    <span className="text-success small">Aberto</span>
                  )}
                </div>
              </div>
              {props.btnRemoverFavorito && (
                <div className="col-auto">
                  <button
                    className={`btn btn-sm ${
                      localStorage.getItem("sessionMode") === "light"
                        ? "btn-outline-danger"
                        : "btn-outline-secondary"
                    }`}
                    onClick={(e) =>
                      props.onClickRemoverFavorito(props.id_favorito)
                    }
                  >
                    <i className="fas fa-trash" />
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
}

export default Estabelecimento;
