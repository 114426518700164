import { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Logo from '../../assets/logo.png';
import LogoDark from '../../assets/logo-dark.png';
import './style.css';

import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import ModalCidades from "../modals/cidade";
import { CartContext } from "../../contexts/cart";

function Navbar() {

    const navigate = useNavigate();
    const [busca, setBusca] = useState('');
    const [isCidadesOpen, setIsCidadesOpen] = useState(false)
    const { nomeEmpresaParams, isMobile, telaHome } = useContext(CartContext);

    const url = window.location.pathname



    function Buscar(e) {
        e.preventDefault()
        navigate(`/busca?q=${busca}`);
    }

    function openModalCidades() {
        setIsCidadesOpen(true)
    }
    function closeModalCidades() {
        setIsCidadesOpen(false)
    }

    function openSidebar() {
        const event = new CustomEvent('openSidebar');
        window.dispatchEvent(event);
    }

    function Logout() {
        localStorage.removeItem('sessionToken');
        localStorage.removeItem('sessionId');
        localStorage.removeItem('sessionEmail');
        localStorage.removeItem('sessionCodCidade');
        localStorage.removeItem('sessionCidade');
        localStorage.removeItem('sessionUF');

        navigate('/');

        if (localStorage.getItem('sessionMode') === 'dark') {
            document.body.classList.remove('dark-mode')
            localStorage.removeItem('sessionMode')
        }
    }

    const removeScroll = () => {
        document.body.classList.add('remove-scrollbar')
    }

    return (
        <>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
            />

            <div className="container-fluid">
                <div className="navbar navbar-expand-lg navbar-light bg-white" >
                    {localStorage.getItem('sessionMode') === 'light' ? (
                        <Link  to={`/${telaHome || nomeEmpresaParams}`} ><img className="mt-1" src={Logo} alt='' style={{ width: 'auto', height: 50 }} /></Link>
                    ) : (
                        <Link  to={`/${telaHome || nomeEmpresaParams}`} ><img className="mt-1" src={LogoDark} alt='' style={{ width: 'auto', height: 50 }} /></Link>

                    )}

                    {url === "/home" ? <div className="ms-auto me-auto mt-1">

                        {!isMobile && <form className="input-group" onSubmit={Buscar}>
                            <input type='text' onChange={(e) => setBusca(e.target.value)} className="form-control box-shadow-none" placeholder="Procurar um restaurante..." aria-label="Search" />
                            <button type='submit' className={`btn ${localStorage.getItem('sessionMode') === 'light' ? 'btn-danger' : 'btn-secondary'} box-shadow-none`} id="button-addon2"><i className="fas fa-search"></i></button>
                        </form>}

                    </div> : <div className="ms-auto me-auto mt-1"></div>}

                    <div className="mt-2 mt-lg-1 d-flex align-item-center justify-content-between justify-content-lg-start">

                        {/* {
                            url === "/pedidos" && <Link to={`/${nomeEmpresaParams}`} className={`btn ${localStorage.getItem('sessionMode') === 'light' ? 'btn-primary' : 'btn-primary'} me-3 box-shadow-none`}>
                                <span className=' d-lg-inline-block '> Novo pedido </span>

                            </Link>
                        } */}

                        {!isMobile && url !== `/${nomeEmpresaParams}` && url !== "/home" && <Link to={-1} className={`btn ${localStorage.getItem('sessionMode') === 'light' ? 'btn-danger' : 'btn-danger'} me-3 box-shadow-none`}>
                            <span className=' d-lg-inline-block '> Voltar </span>

                        </Link>}


                        <Link to="/trocar-endereco" className={`btn ${localStorage.getItem('sessionMode') === 'light' ? 'btn-outline-danger' : 'btn-outline-secondary'} me-3 box-shadow-none`}>
                            <i className="fas fa-map-marker-alt" /><span className=' d-lg-inline-block ms-2'>{localStorage.getItem('sessionCidade')}</span>

                        </Link>




                        {localStorage.getItem('sessionToken') && (
                            <button type="button" className={`btn ${localStorage.getItem('sessionMode') === 'light' ? 'btn-outline-danger' : 'btn-outline-secondary'} dropdown-toggle box-shadow-none`} data-bs-toggle="dropdown" aria-expanded="false">
                                <i className="fas fa-user"></i>
                            </button>
                        )}


                        <ul id='dropdown-hide' className="dropdown-menu dropdown-menu-end" style={{ right: 30 }}>
                            <li><Link to="/perfil" className="dropdown-item">Perfil</Link></li>
                            <li><hr className="dropdown-divider" /></li>
                            <li><Link to="/pedidos" className="dropdown-item">Pedidos</Link></li>
                            <li><Link to="/favoritos" className="dropdown-item">Favoritos</Link></li>
                            <li><Link to="/enderecos" className="dropdown-item">Meus Endereços</Link></li>
                            <li><hr className="dropdown-divider" /></li>
                            <li onClick={Logout} className="dropdown-item" style={{ cursor: 'pointer' }}>Sair</li>
                        </ul>
                    </div>

                </div>
            </div>


            {isMobile && url === "/home" &&
                <form className="input-group" onSubmit={Buscar} style={{ padding: 20 }}>
                    <input type='text' onChange={(e) => setBusca(e.target.value)} className="form-control box-shadow-none" placeholder="Procurar um restaurante..." aria-label="Search" />
                    <button type='submit' className={`btn ${localStorage.getItem('sessionMode') === 'light' ? 'btn-danger' : 'btn-secondary'} box-shadow-none`} id="button-addon2"><i className="fas fa-search"></i></button>
                </form>

            }

            <ModalCidades
                isOpen={isCidadesOpen}
                onRequestClose={closeModalCidades}
            />

        </>
    )
}

export default Navbar;