import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import ScrollToTop from '../ScrollToTop'

import Welcome from '../pages/welcome';
import Home from '../pages/home';
import Busca from "../pages/busca";
import Favoritos from "../pages/favoritos";
import Enderecos from "../pages/enderecos";
import Perfil from "../pages/perfil";
import Cardapio from "../pages/cardapio";
import Pedidos from "../pages/pedidos";
import Sidebar from '../components/sidebar';
import Login from "../pages/login";
import Cadastro from "../pages/cadastro";
import TrocarEndereco from "../pages/trocar-endereco";
import PrivateRoute from "../components/private-route";
import { CheckoutCarrinho } from '../pages/CheckoutCarrinho';
import { CheckoutEndereco } from '../pages/CheckoutEndereco';
import { CheckoutPagamento } from '../pages/CheckoutPagamento';
import { CheckoutStatus } from '../pages/CheckoutStatus';
import { useContext} from "react";
import { CartContext } from "../contexts/cart";
import Error404Page from '../pages/error';
export const Router = () => {
    // Vai vir uma função do context
    const {
        checkoutEtapa
    } = useContext(CartContext);
  
    return (
        <>
            <BrowserRouter>
                <ScrollToTop />
                <Sidebar />
                <Routes>
                    <Route exact path="/" element={<Welcome />} />
                    <Route exact path="*" element={<Error404Page/>} />
                    <Route exact path={`/home`} element={ <Home />} />
                    <Route exact path="/busca" element={<PrivateRoute><Busca /></PrivateRoute>} />
                    <Route exact path="/:nomeEmpresa" element={<Cardapio />} />
                    <Route exact path="/pedidos" element={<PrivateRoute><Pedidos /></PrivateRoute>} />
                    <Route exact path="/favoritos" element={<PrivateRoute><Favoritos /></PrivateRoute>} />
                    <Route exact path="/perfil" element={<PrivateRoute><Perfil /></PrivateRoute>} />
                    <Route exact path="/enderecos" element={<PrivateRoute><Enderecos /></PrivateRoute>} />
                    <Route exact path="/login" element={<Login />} />
                    <Route exact path="/cadastro" element={<Cadastro />} />
                    <Route exact path="/trocar-endereco" element={<PrivateRoute><TrocarEndereco /></PrivateRoute>} />
                    <Route exact path="/checkout/carrinho" element={<PrivateRoute>  <CheckoutCarrinho />  </PrivateRoute>} />
                    <Route exact path="/checkout/endereco" element={<PrivateRoute> {checkoutEtapa === 1 ? <CheckoutEndereco />  : (<Navigate to="/" /> )} </PrivateRoute>} />
                    <Route exact path="/checkout/pagamento" element={<PrivateRoute> {checkoutEtapa === 2 ? <CheckoutPagamento />  : (<Navigate to="/" /> )}  </PrivateRoute>} />
                    {/* <Route exact path="/checkout/status" element={<PrivateRoute> {checkoutEtapa === 3 ? <CheckoutStatus/>  : (<Navigate to="/" /> )}  </PrivateRoute>} /> */}
                    <Route exact path="/checkout/status" element={<PrivateRoute> <CheckoutStatus/>   </PrivateRoute>} />

                </Routes>

            </BrowserRouter>
        </>
    )
}
