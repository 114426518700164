import React, { useState } from 'react'

import { useNavigate, useLocation ,Link } from 'react-router-dom'

import api from '../../../services/api'

import SaltPassword from '../../../services/md5'

const FormLogin = () => {

    const navigate = useNavigate();

    const location = useLocation()

    const [email, setEmail] = useState('');
    const [senha, setSenha] = useState('');
    const [sucesso, setSucesso] = useState('');
    const [loading, setLoading] = useState(false);

    function ProcessaLogin(e){
        e.preventDefault();

        setSucesso('');
        setLoading(true);

        api.post('v1/usuarios/login', {
            email: email,
            senha: SaltPassword(senha),
        })
        .then(response => {            
            localStorage.setItem('sessionToken', response.data.token);
            localStorage.setItem('sessionId', response.data.id_usuario);
            localStorage.setItem('sessionEmail', email);
            localStorage.setItem('sessionCodCidade', response.data.cod_cidade);
            localStorage.setItem('sessionCidade', response.data.cidade);
            localStorage.setItem('sessionUF', response.data.uf);
            // localStorage.setItem('sessionMode', response.data.mode)
            localStorage.setItem('sessionMode',"light")
            
            setSucesso('S');
            navigate(location);

            if(localStorage.getItem('sessionMode') === 'light') {
                document.body.classList.remove('dark-mode')
            } else if(localStorage.getItem('sessionMode') === 'dark') {
                document.body.classList.add('dark-mode')
            }
        })
        .catch(err => {
            setSucesso('N');
            setLoading(false);
        })
    }

    return (
        <>
            <form onSubmit={ProcessaLogin} autoComplete='off' style={{margin:100}}>

                <div className='text-center' >
                    <h3 className="mb-4">Peça seu delivery<br /><span className='text-danger'>agora mesmo</span>.</h3>
                    <h6 className="mb-5">Acesse sua conta</h6>
                </div>

                <div className="mb-4 form-floating">
                    <input type="email" onChange={(e) => setEmail(e.target.value)} className="form-control mb-2" id="email" placeholder="E-mail" />
                    <label htmlFor="email">E-mail</label>
                </div>

                <div className="form-floating">
                    <input type="password" onChange={(e) => setSenha(e.target.value)} className="form-control mb-3" id="senha" placeholder="Senha" />
                    <label htmlFor="senha">Senha</label>
                </div>

                <button type='submit' className="w-100 btn btn-lg btn-danger" disabled={loading}>
                    {loading ? (
                        <div>
                            <span className="spinner-border spinner-border-sm text-light" role="status"></span>
                            <span className="ms-2">Enviando...</span>
                        </div>
                    ) : (
                        <span className="ms-2">Acessar</span>
                    )}
                </button>

                {sucesso === 'N' ? <div className="alert alert-danger mt-2" role="alert">E-mail ou senha inválida</div> : null}

                <div className="mt-3 text-center">
                    Não tenho uma conta. <Link to="/cadastro" className="text-danger text-decoration-none">Criar Agora!</Link>
                </div>

            </form>
        </>
    )
}

export default FormLogin