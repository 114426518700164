import ButtonEditEndereco from "../../endereco/buttonEdit";
import ButtonDeleteEndereco from "../../endereco/buttonDelete";
import ButtonAddEndereco from "../../endereco/buttonAdd";
import { Stack } from "@mui/material";
import api from "../../../services/api";
import { useState } from "react";
import TelefoneModal from "./modalTelefone";

export const EntregaEnderecoCheckout = ({
  setEntregar,
  setRetirar,
  entregar,
  retirar,
  telefone,
  nome,
  cep,
  setTelefone,
  setNome,
  setCEP,
  endereco,
  setEndereco,
  complemento,
  setComplemento,
  bairro,
  setBairro,
  cidade,
  setCidade,
  setUF,
  codCidade,
  setCodCidade,
  enderecos,
  SelecionarEndereco,
  atualizarSite,
  formaDeTrabalho,
}) => {
  // Vai ter uma requisisão aqui de uma api em que retorna os dados do usuario de acordo com seu telefone, no momento vou simular
  // const [infoUser, setInfoUser] = useState({});

  setEndereco("Retirar")

  if( formaDeTrabalho === "R" ){
    setRetirar(true);
    setEntregar(false);

  }
  if( formaDeTrabalho === "E" ){
    setEntregar(true);
    setRetirar(false);

  }

  async function EnderecoPadrao(id) {
    await api.patch(`v1/usuarios/enderecos/padrao/${id}`);
    atualizarSite();
  }

  localStorage.getItem("telefone");

  return (
    <>
      <div
        className="d-flex align-items-center justify-content-center"
        style={{ marginTop: 30 }}
      >
        <label
          className="d-flex align-items-center"
          htmlFor="entregar"
          style={{ cursor: "pointer" }}
        >
          <input
            type="radio"
            id="entregar"
            name="user"
            className="form-check-input mt-1 me-1 shadow-none"
            style={{
              cursor:
                formaDeTrabalho &&
                (formaDeTrabalho === "E" || formaDeTrabalho === "A")
                  ? "pointer"
                  : "default",
              display:
                formaDeTrabalho &&
                (formaDeTrabalho === "E" || formaDeTrabalho === "A")
                  ? ""
                  : "none",
            }}
            checked={ formaDeTrabalho === "A" ? (entregar ? true : false) : (formaDeTrabalho === "E") }
            onChange={() => {
              setEntregar(true);
              setRetirar(false);
            }}
          />
          <h6
            className="mb-0 mt-1 text-uppercase"
            style={{
              display:
                formaDeTrabalho &&
                (formaDeTrabalho === "A" || formaDeTrabalho === "E")
                  ? ""
                  : "none",
            }}
          >
            Entregar
          </h6>
        </label>
        <label
          className="ms-3 d-flex align-items-center"
          htmlFor="retirar"
          style={{ cursor: "pointer" }}
        >
          <input
            type="radio"
            id="retirar"
            name="user"
            className="form-check-input mt-1 me-1 shadow-none"
            style={{
              cursor:
                formaDeTrabalho &&
                (formaDeTrabalho === "R" || formaDeTrabalho === "A")
                  ? "pointer"
                  : "default",
              display:
                formaDeTrabalho &&
                (formaDeTrabalho === "R" || formaDeTrabalho === "A")
                  ? ""
                  : "none",
            }}

            checked={ formaDeTrabalho === "A" ? (retirar ? true : false) : (formaDeTrabalho === "R") }

            onChange={() => {
              setRetirar(true);
              setEntregar(false);
            }}
          />
          <h6
            className="mb-0 mt-1 text-uppercase"
            style={{
              display:
                formaDeTrabalho &&
                (formaDeTrabalho === "R" || formaDeTrabalho === "A")
                  ? ""
                  : "none",
            }}
          >
            {" "}
            Vou Buscar{" "}
          </h6>
        </label>
      </div>


      { (formaDeTrabalho && (formaDeTrabalho === "A" ||
        formaDeTrabalho === "E") && entregar && (
          <>
            <div className="mt-3 mb-4 d-none">
              <input
                type="text"
                className="form-control mb-2"
                placeholder="Telefone"
                value={telefone}
                onChange={(e) => setTelefone(e.target.value)}
              />
              <input
                type="text"
                className="form-control mb-2"
                placeholder="Nome"
                value={nome}
                onChange={(e) => setNome(e.target.value)}
              />
              <input
                type="text"
                className="form-control mb-2"
                placeholder="CEP"
                value={cep}
                onChange={(e) => setCEP(e.target.value)}
              />
              <input
                type="text"
                className="form-control mb-2"
                readOnly
                disabled
                placeholder="Endereço"
                value={endereco}
                onChange={(e) => setEndereco(e.target.value)}
              />
              <input
                type="text"
                className="form-control mb-2"
                placeholder="Complemento"
                value={complemento || ""}
                onChange={(e) => setComplemento(e.target.value)}
              />
              <input
                type="text"
                className="form-control mb-2"
                placeholder="Bairro"
                readOnly
                disabled
                value={bairro}
                onChange={(e) => setBairro(e.target.value)}
              />
              <div className="row mb-2">
                <div className="col-10 pe-2">
                  <input
                    type="text"
                    className="form-control text-uppercase"
                    placeholder="Cidade"
                    value={cidade}
                    onChange={(e) => setCidade(e.target.value)}
                  />
                </div>
                <div className="col-2 ps-0">
                  <select
                    onChange={(e) => setUF(e.target.value)}
                    className="form-control form-select text-uppercase"
                  >
                    <option value="PR">PR</option>
                  </select>
                </div>
              </div>
              <input
                type="hidden"
                readOnly
                disabled
                className="form-control"
                placeholder="Código da Cidade"
                value={codCidade}
                onChange={(e) => setCodCidade(e.target.value)}
              />
            </div>

            <div style={{ marginTop: 20, padding: "10px 20px 0 20px" }}>
              <Stack justifyContent="center" alignItems="center">
                <ButtonAddEndereco
                  atualizarSite={atualizarSite && atualizarSite}
                />
              </Stack>

              <ul
                className="list-group list-group-flush rounded mt-3"
                style={{
                  border:
                    localStorage.getItem("sessionMode") === "light"
                      ? "1px solid #ced4da"
                      : "1px solid rgba(255,255,255, .25)",
                }}
              >
                {enderecos &&
                  enderecos.map((endereco, index) => {
                    if (endereco.IND_PADRAO === "S") {
                      SelecionarEndereco(endereco);
                    }

                    return (
                      <div>
                        <label
                          className="list-group-item py-3 endereco-item"
                          key={endereco.id_endereco}
                          htmlFor={`flexRadioDefault${endereco.id_endereco}`}
                          style={{
                            cursor: "pointer",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>
                            <input
                              type="radio"
                              name="flexRadioDefault"
                              className="form-check-input"
                              id={`flexRadioDefault${endereco.id_endereco}`}
                              checked={
                                endereco.IND_PADRAO === "S" ? true : false
                              }
                              onClick={() => {
                                SelecionarEndereco(endereco);
                                EnderecoPadrao(endereco.id_endereco);
                              }}
                            />
                            <span className="form-check-label ms-2">
                              <b>
                                {endereco.endereco}{" "}
                                {endereco.complemento.length > 0
                                  ? ` - ${endereco.complemento}`
                                  : null}
                              </b>
                              <small className="ms-4 d-block">
                                {endereco.cidade} - {endereco.uf}
                              </small>

                              <small className="ms-4 d-block">
                                {endereco.endereco}
                              </small>
                            </span>
                          </div>

                          <Stack
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            spacing={2}
                          >
                            <ButtonEditEndereco
                              id_endereco={endereco && endereco?.id_endereco}
                              atualizarSite={atualizarSite && atualizarSite}
                            />
                            <ButtonDeleteEndereco
                              id_endereco={endereco?.id_endereco}
                              atualizarSite={atualizarSite}
                            />
                          </Stack>
                        </label>
                      </div>
                    );
                  })}
              </ul>
            </div>
          </>
        ))}


      {(formaDeTrabalho && (formaDeTrabalho === "A" || formaDeTrabalho === "R") && retirar && (
          <>
            <div style={{ marginTop: 50, padding: "0 20px 0 20px" }}>
              <input
                type="text"
                className="form-control mb-2"
                placeholder="Telefone"
                value={telefone}
                onChange={(e) => setTelefone(e.target.value)}
              />
              <input
                type="text"
                className="form-control"
                placeholder="Nome"
                value={nome}
                onChange={(e) => setNome(e.target.value)}
              />
            </div>
          </>
        ))}
    </>
  );
};
