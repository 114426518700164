import api from "../../../services/api";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import DeleteIcon from "@mui/icons-material/Delete";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

function ButtonDeleteEndereco({ id_endereco, atualizarSite }) {
  const [id, setId] = useState(null);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  async function DeletarEndereco() {
    await api.delete(`v1/usuarios/enderecos/${id}`);
    atualizarSite();
  }
  async function ExcluirEndereco(id) {
    handleShow();
    setId(id);
  }

  return (
    <>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">Excluir</Modal.Title>

          <CloseIcon
            sx={{
              color: "#dc3545",
              cursor: "pointer",
              width: 30,
              height: 30,
              "&:hover": {
                backgroundColor: "#dc3545",
                color: "#fff",
                borderRadius: 100,
              },
            }}
            onClick={() => {
              handleClose();
            }}
          />
        </Modal.Header>

        <Modal.Body>
          <Typography> Confirma a exclusão do endereço?</Typography>
        </Modal.Body>

        <Modal.Footer style={{ margin: 0 }}>
          <Button
            variant="contained"
            style={{ left: -20 }}
            onClick={() => {
              handleClose();
            }}
          >
            Não
          </Button>

          <Button
            variant="contained"
            color="error"
            onClick={() => {
              DeletarEndereco();
            }}
          >
            Sim
          </Button>
        </Modal.Footer>
      </Modal>

      <DeleteIcon
        onClick={(e) => ExcluirEndereco(id_endereco)}
        style={{ color: "#dc3545", fontSize: 35 }}
      />
    </>
  );
}

export default ButtonDeleteEndereco;
