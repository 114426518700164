export const FooterStatusCheckout = ({
  navigate,
  homeTrue,
  nomeEmpresaParams,
}) => {
  return (
    <div style={{display: "contents"}}>
      <button
        style={{ borderRadius: 0, padding: 12}}
        className={`btn ${
          localStorage.getItem("sessionMode") === "light"
            ? "btn-primary"
            : "btn-primary"
        } w-100`}
        onClick={() =>
          navigate(`/${homeTrue === false ? nomeEmpresaParams : ""}`)
        }
      >
        Início 
      </button>

      <button
        style={{ borderRadius: 0, marginTop: 20, padding: 12, }}
        className="btn btn-danger w-100"
        onClick={() => {
          navigate("/pedidos");
        }}
      >
        Ver meus pedidos
      </button>
    </div>
  );
};
