import AddIcon from "@mui/icons-material/Add";
import DoneIcon from '@mui/icons-material/Done';

export const FooterCarrinhoCheckout = ({
  cart,
  navigate,
  subtotalCart,
  descontoCart,
  cupomCart,
  entregaCart,
  totalCart,
  nomeEmpresaParams,
  setCheckoutEtapa,
  setCupomCart,
  ValidarCupom

}) => {
  return (
    <div className="container-fluid">

      {cart.length > 0 ? (
        <>
          <button
            style={{
              display: "flex",
              alignItems: "center",
              textAlign: "center",
              justifyContent: "center",
              borderRadius: 0,
              padding: 12,
            }}
            className={`btn ${localStorage.getItem("sessionMode") === "light"
                ? "btn-primary"
                : "btn-primary"
              }  w-100`}
            onClick={() => navigate(`/${nomeEmpresaParams}`)}
          >
            Escolher mais <AddIcon sx={{ marginLeft: 0.5 }} />
          </button>
          <div className="col-12 d-flex justify-content-between align-items-center">

            <span>Subtotal</span>
            <span>
              {new Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL",
              }).format(subtotalCart)}
            </span>
          </div>
          <div className="col-12 d-flex justify-content-between align-items-center mt-2">
            <small>
              Desconto{" "}
              {descontoCart > 0 ? (
                <span className="text-success">{`(cupom ${cupomCart})`}</span>
              ) : null}
            </small>
            <span>
              -{" "}
              {new Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL",
              }).format(descontoCart)}
            </span>
          </div>

          <div className="col-12 d-flex justify-content-between align-items-center mt-2">
            <span>Taxa de entrega</span>
            <span>
              {new Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL",
              }).format(entregaCart)}
            </span>
          </div>
          {/* <div className="input-group" style={{padding: "5px 0 5px 0"}}>
            <input onChange={(e) => setCupomCart(e.target.value)} value={cupomCart} type="text" className={`form-control`} placeholder="Cupom" aria-label="Recipient's username"
              aria-describedby="button-addon2" />
            <button onClick={ValidarCupom} className="btn  btn-success" type="button" id="button-addon2">Aplicar</button>
          </div> */}
          <div className="col-12 d-flex justify-content-between align-items-center ">
            <b>Total </b>
            <h4>
              {new Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL",
              }).format(totalCart)}
            </h4>
          </div>
          <button
            className="btn btn-success w-100"
            style={{
              borderRadius: 0,
              padding: 12,

            }}
            onClick={() => {
              // Mudar a etapa e troca de pagina 

              setCheckoutEtapa(1)
              navigate("/checkout/endereco")

            }}
          >
            Avançar
          </button>
        </>
      ) :

        <button
          className={`btn ${localStorage.getItem("sessionMode") === "light"
              ? "btn-danger"
              : "btn-outline-secondary"
            } w-100`}
          style={{ marginBottom: 10 }}
          onClick={() => {
            navigate(-1)
          }}
        >
          Voltar
        </button>
      }
    </div>


  );
};
