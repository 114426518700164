import { useContext } from "react";
import { CartContext } from "../../../contexts/cart";
import api from "../../../services/api";

export const PedidoFinalizado = () => {
  const {
    setCart,
    formPedidoFinalizado,
    setFormFinalizarPedido,
    setValorTroco,
  } = useContext(CartContext);

  (async () => {


    if (formPedidoFinalizado.itens && formPedidoFinalizado?.itens?.length > 0) {

        const response = await api.post("v1/pedidos", formPedidoFinalizado);

        if (response.data) {
          sessionStorage.removeItem("sessionCart");
          setCart([]);
          setFormFinalizarPedido({});
          setValorTroco("");
        }
      }
   
  })();
};
