import { Button } from "@mui/material"
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import menu from "../../assets/img/CheckoutStatus/menu.svg";
import motoboy from "../../assets/img/CheckoutStatus/motoboy.svg";
import Close from "@mui/icons-material/Close";

export const NotificarPedido = ({ navigate, statusApi }) => {
    return statusApi && <>
        {

            (
                statusApi.STATUS === "0"  ?

                <Button variant="contained" style={{ marginLeft: 20 }} sx={{
                    animation: `${statusApi.STATUS === "0" ? "cancelado 5s infinite" : ""
                        } `,
                    "@keyframes cancelado": {
                        "0%, 100%": {
                            backgroundColor: "#e01902",

                        },
                        "50%": {
                            backgroundColor: "#9c1000",
                        },
                    },
                }} onClick={() => { navigate('/checkout/status') }}>     <Close style={{ marginRight: 10 }} /> Um pedido seu foi cancelado </Button> :
                statusApi.STATUS === "1" ?

                    <Button variant="contained" style={{ marginLeft: 20 }} sx={{
                        animation: `${statusApi.STATUS === "1" ? "mudandoCor 5s infinite" : ""
                            } `,
                        "@keyframes mudandoCor": {
                            "0%, 100%": {
                                backgroundColor: "#eb7134",

                            },
                            "50%": {
                                backgroundColor: "#ebb734",
                            },
                        },
                    }} onClick={() => { navigate('/checkout/status') }}>     <AccessTimeIcon style={{ marginRight: 10 }} /> Pedido em Andamento </Button> :


                    statusApi.STATUS === "2" ?


                        <Button style={{ marginLeft: 20 }}
                            variant="contained"
                            sx={{
                                animation: `${statusApi.STATUS === "2" ? "recebido 5s infinite" : ""
                                    } `,
                                "@keyframes recebido": {
                                    "0%, 100%": {
                                        backgroundColor: "#ebb734",

                                    },
                                    "50%": {
                                        backgroundColor: "#6ccf02",

                                    },
                                },
                            }}

                            onClick={() => { navigate('/checkout/status') }}>
                            <img src={menu} style={{ width: 25, height: 25, marginRight: 10 }} /> Pedido recebido  </Button> :
                        statusApi.STATUS === "3"

                            ?

                            <Button variant="contained" type="button" class="btn btn-success" style={{ marginLeft: 20 }}

                                sx={{
                                    animation: `${statusApi.STATUS === "3" ? "motoboy 5s infinite" : ""
                                        } `,
                                    "@keyframes motoboy": {
                                        "0%, 100%": {
                                            backgroundColor: "#5fc702",

                                        },
                                        "50%": {
                                            backgroundColor: "#3d7d04",

                                        },
                                    },
                                }}
                                onClick={() => { navigate('/checkout/status') }}>    <img src={motoboy} style={{ width: 25, height: 25, marginRight: 10 }} /> Motoboy na estrada  </Button> : ""

            )
        }

    </>
}