import { StateContext } from "./contexts/StateContext";
import { CartProvider } from "./contexts/cart";
import { Router } from "./router/router";

export const App = () => {
  return (
    <CartProvider>
      <StateContext>
        <Router/>
      </StateContext>
    </CartProvider>
  );
}

