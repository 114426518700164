import React from 'react';


function ProdutoItemMultiplos(props){
    return (
        <div className="card rounded-0 border-start-0 border-end-0">
            {/* <div className="card-header d-flex justify-content-between cursor-default">
                {props.titulo}
                {props.obrigatorio ? <span className="badge bg-secondary" style={{ lineHeight: 'inherit' }}>OBRIGATÓRIO</span> : null}
            </div> */}

            <div className="card-header d-flex align-items-center">
                <span className='text-uppercase'><b>{props.titulo}</b></span>
                <small className='px-2'>{props.descricao_opcao}</small>
                <div className='ms-auto'>
                    {props.obrigatorio ? 
                        <small className="badge bg-secondary" style={{ lineHeight: 'inherit', padding: '.17em .32em' }}>OBRIGATÓRIO</small> : 
                        <small className="badge bg-secondary" style={{ lineHeight: 'inherit', padding: '.17em .32em' }}>OPCIONAL</small>
                    }
                </div>
            </div>

            <ul className="list-group list-group-flush">
                {props.opcoes.map(opcao => {
                    return <div className="list-group-item d-flex align-items-center" key={opcao.id_item}>
                        <div className='d-flex flex-column cursor-default'>

                            {opcao.nome_item}

                            {opcao.vl_item > 0 ?
                                <span className={`${localStorage.getItem('sessionMode') === 'light' ? 'text-success' : 'text-secondary'}`}>
                                    {new Intl.NumberFormat('pt-BR', {style: 'currency',currency: 'BRL'}).format(opcao.vl_item)}
                                </span>
                            : null}

                        </div>
                        <div className='ms-auto'>
                            <div className="row">
                                <div className="col-12">
                                    <div className="d-flex align-items-center justify-content-center">
                                        <button 
                                            // onClick={(e) => props.onClickItem({
                                            //     id_opcao: opcao.id_opcao,
                                            //     nome: opcao.nome_item,
                                            //     id_item: opcao.id_item,
                                            //     vl_item: opcao.vl_item,
                                            //     ordem: opcao.ordem,
                                            // }, 'del')}
                                            onClick={(e) => props.onClickItem(opcao, 'del')}
                                            name={`multiplos-${opcao.id_opcao}`} 
                                            id={`multiplos-${opcao.id_item}`} 
                                            className={`btn ${localStorage.getItem('sessionMode') === 'light' ? 'btn-outline-danger' : 'btn-outline-secondary'}`} 
                                            style={{ borderRadius: '100%' }}
                                        >
                                            <i className="fas fa-minus" />
                                        </button>
                                        <span 
                                            className={`cursor-default text-center ${localStorage.getItem('sessionMode') === 'light' ? '' : 'text-secondary'}`}  
                                            style={{ width: '32px' }}
                                        >
                                            {opcao.quantidade ? opcao.quantidade : 0}
                                        </span>
                                        <button 
                                            // onClick={(e) => props.onClickItem({
                                            //     id_opcao: opcao.id_opcao,
                                            //     nome: opcao.nome_item,
                                            //     id_item: opcao.id_item,
                                            //     vl_item: opcao.vl_item,
                                            //     ordem: opcao.ordem,
                                            // }, 'add')}
                                            onClick={(e) => props.onClickItem(opcao, 'add')}
                                            name={`multiplos-${opcao.id_opcao}`} 
                                            id={`multiplos-${opcao.id_item}`} 
                                            className={`btn ${localStorage.getItem('sessionMode') === 'light' ? 'btn-outline-success' : 'btn-outline-secondary'}`} 
                                            style={{ borderRadius: '100%' }}
                                        >
                                            <i className="fas fa-plus" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                })
                
                }
            </ul>

        </div>
    )
}

export default ProdutoItemMultiplos;