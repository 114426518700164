import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Dock } from 'react-dock';
import { CartContext } from "../../contexts/cart";
import Produto from "../produto/sacola";
import './style.css';

import FormLogin from "../forms/login";

function Sidebar(){

    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const {cart, subtotalCart, descontoCart, entregaCart, totalCart, 
        RemoveItemCart, ValidarCupom, setCupomCart, cupomCart, msgCart, setCheckoutEtapa} = useContext(CartContext);

    useEffect(() => {
        window.addEventListener('openSidebar', () => {
            setShow(true);
        })
    }, []);

    function FinalizarPedido(){
        setShow(false);
   
        navigate("/checkout/carrinho");
    }
    
    return (
        <Dock 
            position="right"
            isVisible={show}
            fluid={false}
            size={localStorage.getItem('sessionEmail') === 'visitante@comeraqui.com.br' ? 374 : 300}
            onVisibleChange={(visible) => {
                setShow(visible);
                document.body.classList.remove('remove-scrollbar')
            }}

        >
            <div 
                className={`h-100`}
            >
                {localStorage.getItem('sessionEmail') === 'visitante@comeraqui.com.br' ? (
                    <>
                        <div className="container-fluid">

                            <div className="d-flex justify-content-end mt-2">
                                <button onClick={() => { setShow(false); document.body.classList.remove('remove-scrollbar'); }} className={`btn btn-sm btn-link btn-fechar ${localStorage.getItem('sessionMode') === 'light' ? 'text-danger' : 'text-white'}`} style={{ height: '30px' }}>
                                    <i className="fas fa-times" />
                                </button>
                            </div>
                            
                            <FormLogin />
                            
                        </div>
                    </>
                ) : (
                    <>
                        {cart.length === 0 ?  (
                            <div className="sacola d-flex h-100 flex-column justify-content-center align-items-center text-center">
                                <i className={`fas fa-shopping-bag ${localStorage.getItem('sessionMode') === 'light' ? 'text-danger' : 'text-secondary'}`} style={{ fontSize: '32px' }} />
                                <small className="mt-2 mb-3 text-secondary">Sua sacola está vazia</small>
                                <button className={`btn ${localStorage.getItem('sessionMode') === 'light' ? 'btn-outline-danger' : 'btn-outline-secondary'}`} onClick={() => { setShow(false); document.body.classList.remove('remove-scrollbar') } }>Fechar</button>
                            </div>
                        ) : (
                            <>

                                <div className="container-fluid h-100 pt-2 sidebar">

                                    <div className="d-flex justify-content-between align-items-center">
                                        <h5 className="m-0">Minha Sacola</h5>
                                        <button onClick={() => { setShow(false); document.body.classList.remove('remove-scrollbar'); }} className={`btn btn-sm ${localStorage.getItem('sessionMode') === 'light' ? 'btn-outline-danger' : 'btn-outline-secondary'}`} style={{ height: '30px' }}>
                                            <i className="fas fa-times" />
                                        </button>
                                    </div>

                                    <div className="row produtos pt-2">
                                        {cart.map((prod) => (
                                            <Produto 
                                                key={prod.id_carrinho}
                                                nome={prod.nome}
                                                valor_total={prod.vl_unit * prod.qtd}
                                                qtd={prod.qtd}
                                                valor_unit={prod.vl_unit}
                                                id_carrinho={prod.id_carrinho}
                                                url_foto={prod.url_foto}
                                                onClickRemover={RemoveItemCart}
                                                removidos={prod.removidos}
                                                detalhes={prod.detalhes}
                                                observacao={prod.observacao}
                                                tipo={prod.tipo}
                                                pizzas={prod.pizzas}
                                                bordas={prod.bordas}
                                            />
                                        ))}
                                    </div>

                                    <div className="row align-items-end footer mt-2">

                                        <div className="col-12 d-flex justify-content-between align-items-center">
                                            <span>Subtotal</span>                    
                                            <span>{new Intl.NumberFormat('pt-BR', {style: 'currency',currency: 'BRL'}).format(subtotalCart)}</span>
                                        </div>

                                        {/* <div className="col-12 d-flex align-items-center mt-2">
                                            <div className="input-group">
                                                <input onChange={(e) => setCupomCart(e.target.value)} value={cupomCart} type="text" className={`form-control`} placeholder="Cupom" aria-label="Recipient's username" 
                                                    aria-describedby="button-addon2" />
                                                <button onClick={ValidarCupom} className="btn btn-outline-secondary" type="button" id="button-addon2">Aplicar</button>
                                            </div>  
                                        </div> */}

                                        <div className="col-12 mt-2">
                                            <div className="input-group justify-content-between">
                                                {msgCart.length > 0 ? <small className="text-danger">{msgCart}</small> : null}
                                                <span className="d-inline-block text-success">
                                                - {new Intl.NumberFormat('pt-BR', {style: 'currency',currency: 'BRL'}).format(descontoCart)}
                                                </span>
                                            </div>
                                        </div>

                                        <div className="col-12 d-flex justify-content-between align-items-center mt-2">
                                            <span>Taxa de entrega</span>                    
                                            <span>{new Intl.NumberFormat('pt-BR', {style: 'currency',currency: 'BRL'}).format(entregaCart)}</span>
                                        </div>

                                        <div className="col-12 d-flex justify-content-between align-items-center mt-3">
                                            <b>Total</b>
                                            <h3>{new Intl.NumberFormat('pt-BR', {style: 'currency',currency: 'BRL'}).format(totalCart)}</h3>
                                        </div>

                                        <button onClick={() => { FinalizarPedido(); document.body.classList.remove('remove-scrollbar'); } }  className="btn btn-lg btn-success rounded-0 align-items-center btn-pedido box-shadow-none"> Finalizar Pedido  </button>  
                                    </div>
                                </div>
                            </>
                        )}
                    </>
                )}
            </div>

        </Dock>
    )
}

export default Sidebar;