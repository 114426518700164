import { Link } from "react-router-dom";

function Footer() {
  const ano = new Date().getFullYear();

  return (
    <div
      className="container-fluid py-5"
      style={{marginTop: 'auto'}}

    >
      <div className="footer row">
        <div className="col-12">
          <div className="copyright text-center">
            © {ano}{" "}
            <Link to="/" className="text-decoration-none comer">
              <b>
                <span className="text-dark font-bold">comer</span>
                <span className="text-danger">aqui</span>
              </b>
            </Link>
            <div>
              <small>Todos os direitos reservados</small>
            </div>
            <div className="mt-2">
              <small>
                <a
                  href="https://gerencesistemas.com.br/"
                  target="_blank"
                  rel="noreferrer"
                  className="text-decoration-none text-danger gerence"
                >
                  <b>Gerence Sistemas</b>
                </a>
              </small>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
