import { useEffect, useState } from "react";

import './style.css';

import Modal from 'react-bootstrap/Modal'
import CloseIcon from '@mui/icons-material/Close';
import api from "../../../services/api";

// Modal.setAppElement('#root');

function EnderecoModal({isOpen, onRequestClose,dados_endereco, atualizarSite}){
    
    const [id_endereco, setId_Endereco] = useState(0);
    const [endereco, setEndereco] = useState('');
    const [complemento, setComplemento] = useState('');
    const [bairro, setBairro] = useState('');
    const [cidades, setCidades] = useState([]);
    const [cidade, setCidade] = useState('');
    const [uf, setUF] = useState('');
    const [cep, setCEP] = useState('');
    const [codCidade, setCodCidade] = useState('');
    const [ind_padrao, setInd_padrao] = useState('');
    const [mensagem, setMensagem] = useState('');

    function SalvarCidade(e){
        const [cid, est] = e.target[e.target.selectedIndex].text.split(" - ");
        setCidade(cid);
        setUF(est);
        setCodCidade(e.target.value);        
    }

    async function SalvarEndereco(){

        setMensagem('');

        if (id_endereco > 0) {
            await api.patch(`v1/usuarios/enderecos/${id_endereco}`, {
                endereco,
                complemento,
                bairro,
                cidade,
                uf,
                cep,
                ind_padrao,
                cod_cidade: codCidade
            })
            onRequestClose()
            atualizarSite()
            
        } else {
            await api.post(`v1/usuarios/enderecos`, {
                endereco,
                complemento,
                bairro,
                cidade,
                uf,
                cep,
                ind_padrao,
                cod_cidade: codCidade
            })
            onRequestClose()
            atualizarSite()
        }
    }

    useEffect(() => {
        setId_Endereco(dados_endereco.id_endereco);
        setEndereco(dados_endereco.endereco);
        setComplemento(dados_endereco.complemento);
        setBairro(dados_endereco.bairro);
        setCidade(dados_endereco.cidade);
        setUF(dados_endereco.uf);
        setCEP(dados_endereco.cep);
        setInd_padrao('N');
        setCodCidade(dados_endereco.cod_cidade);
        
        api.get('v1/cidades')
        .then(response => setCidades(response.data))
        .catch(err => console.log(err));

    }, [isOpen]);    


    return (

        <Modal
            show={isOpen}
            onHide={() => { onRequestClose(); }}
            scrollable='true'
            centered
            fullscreen
            
        >
            <Modal.Header className='pb-0' style={{ position: 'relative', alignItems: 'inherit', borderBottom: 'none' }}>
                <Modal.Title style={{ width: '100%', fontSize: 'inherit', fontWeight: 'inherit', alignItems: 'inherit' }}>
                    <div className='d-flex align-items-center justify-content-between'>
                        <h4 style={{ marginBottom: 0 }}>
                            Editar endereço
                        </h4>

                        <button 
                            onClick={() => { onRequestClose(); }} 
                            className='btn btn-sm btn-link text-danger btn-fechar' 
                            style={{ position: 'absolute', right: 8, top: 8 }}
                        >
                            <CloseIcon style={{ fontSize: 32 }} />
                        </button>
                    </div>

                </Modal.Title>
            </Modal.Header>

            <Modal.Body>

                <div className="endereco" style={{marginTop: 20}}>
                    <div className="col-12">
                        <form>
                            <div className="row">
                                <div className="mb-3 col-8 d-inline-block">
                                    <label htmlFor="InputNome" className="form-label mb-1">Endereço</label>
                                    <input type="text" onChange={(e) => setEndereco(e.target.value)} value={endereco} className="form-control mb-2" id="InputNome" aria-describedby="nome" />                    
                                </div>
                                <div className="mb-3 col-4 d-inline-block">
                                    <label htmlFor="InputNome" className="form-label mb-1">Compl.</label>
                                    <input type="text" onChange={(e) => setComplemento(e.target.value)} value={complemento} className="form-control mb-2" placeholder="Ex: Ap. 306" id="InputNome" aria-describedby="nome" />                    
                                </div>
                            </div>

                            <div className="row">
                                <div className="mb-3 col-12">
                                    <label htmlFor="InputNome" className="form-label mb-1">Bairro</label>
                                    <input type="text" onChange={(e) => setBairro(e.target.value)} value={bairro} className="form-control mb-2" id="InputNome" aria-describedby="nome" />                    
                                </div>                                
                            </div>

                            <div className="row">
                                <div className="col-12">
                                    <label htmlFor="InputNome" className="form-label mb-1">Cidade</label>
                                    <div className="form-control mb-3">
                                        <select name="cidades" id="cidades" onChange={SalvarCidade} value={codCidade} style={{ outline: 'none' }}>
                                            <option value="0000000">Escolha a cidade</option>

                                            {cidades.map(c => {
                                                return <option value={c.cod_cidade} key={c.cod_cidade}>{c.cidade} - {c.uf}</option>
                                            })}
                                                                                                                            
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="mb-3 col-12">
                                    <label htmlFor="InputNome" className="form-label mb-1">CEP</label>
                                    <input type="text" onChange={(e) => setCEP(e.target.value)} value={cep} className="form-control" id="InputNome" aria-describedby="nome" />                    
                                </div>                                
                            </div>
                        </form>

                    </div>

           

                    {mensagem.length > 0 ? <div className="alert alert-danger mt-2 text-center">{mensagem}</div> : null}

                </div>

            </Modal.Body>

            <Modal.Footer>

            <div className="row">
                        <div className="col-12 mt-3 d-flex justify-content-end align-items-center">                       
                            <button onClick={SalvarEndereco} type="button mt-3" className={`btn btn-lg ${localStorage.getItem('sessionMode') === 'light' ? 'btn-danger' : 'btn-secondary'}`}>Salvar Dados</button>
                        </div>
                    </div>
            </Modal.Footer>

        </Modal>
    )
}

export default EnderecoModal;