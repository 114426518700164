import React from 'react';
import ReactDOM from 'react-dom/client';
import {App} from './App';
import "./styles/dark-mode.css"
import "./styles/global.css"

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  //  Desativei porque esta mandando 2 vezes as requisições
  // <React.StrictMode>
    <App />
  // </React.StrictMode>
);
