import React, { useState, useContext, useEffect } from "react";

import { useNavigate } from "react-router-dom";

import { CartContext } from "../../contexts/cart";

import FormLogin from "../forms/login";

import Produto from "./produto";
import { Padding } from "@mui/icons-material";

import "../../styles/scrollCartPC.css";
import { TiShoppingCart } from "react-icons/ti";
import pattern from '../../assets/patternBorrado.png'

const MiniSidebar = () => {
  const navigate = useNavigate();
  const {
    cart,
    subtotalCart,
    descontoCart,
    entregaCart,
    totalCart,
    RemoveItemCart,
    cupomCart,
    setCupomCart,
    ValidarCupom,
    setFinalizarPC,
    setCheckoutEtapa,
  } = useContext(CartContext);

  function FinalizarPedido() {
    setCheckoutEtapa(1);
    navigate("/checkout/endereco");
    setFinalizarPC(true);
  }

  return (
    <>
      {/* {localStorage.getItem('sessionEmail') === 'visitante@comeraqui.com.br' ? (
                <>
                    <FormLogin />
                </>
            ) : ( */}
      <div
        style={{
          // position: "fixed", marginTop: -200, zIndex: 3

          // width: "50%",
          // height: "100%",
          // float: "left",
          // padding: "1em",

          padding: 10,
          position: "fixed",
          marginBottom: 5,
          bottom: 0,
          backgroundImage: `url(${pattern})`,

          //   background: "#00000009",
          borderRadius: 5,

          width: "25%",
        }}
      >
        <div style={{ width: "auto", background: localStorage.getItem("sessionMode") === "light" ? "#fcf9f997" : "#303030" }}>
          <div
            className="d-flex align-items-center justify-content-between"
            style={{}}
          >
            <div
              className="d-flex align-items-center"
              style={{ marginBottom: 10, padding: 10 }}
            >
              {/* <i className={`fas fa-shopping-bag ${localStorage.getItem('sessionMode') === 'light' ? 'text-danger' : 'text-secondary'}`} style={{ fontSize: '16px' }} /> */}
              <TiShoppingCart style={{ fontSize: 25, color: "#b8241a" }} />
              <h5 className="ms-2 my-0" > Carrinho </h5>
            </div>
          </div>

          {cart.length === 0 ? (
            <div
              style={{ display: "flex", height: "25vh", justifyContent: "center", alignItems: "center", backgroundImage: `url(${pattern})` }}
            >
              <p
                style={{
                  padding: 30, borderRadius: 5, backdropFilter: "blur(3px)", // Adiciona o efeito de borrão
                }}
              >
                🛒🍔🍕 Carrinho vazio, barriga roncando! Hora de dar um festim
                de sabor com nossas opções de dar água na boca! 🌮🍟🍣
              </p>
            </div>
          ) : (
            <>
              {/* carrinho para pc  */}

              {/* <div className="row produtos mt-2"  > */}
              <div
                id="scrollFinalizeSeuPedidoPC"
                className="p-3"
                style={{ height: "30vh", overflowY: "scroll" }}
              >
                {cart.map((prod) => (
                  <Produto
                    key={prod.id_carrinho}
                    nome={prod.nome}
                    valor_total={prod.vl_unit * prod.qtd}
                    qtd={prod.qtd}
                    valor_unit={prod.vl_unit}
                    id_carrinho={prod.id_carrinho}
                    url_foto={prod.url_foto}
                    onClickRemover={RemoveItemCart}
                    removidos={prod.removidos}
                    detalhes={prod.detalhes}
                    observacao={prod.observacao}
                    valor={prod.vl_produto}
                    tipo={prod.tipo}
                    tamanho={prod.tamanho}
                    tp_sabor={prod.tp_sabor}
                    quantity_flavors={prod.quantity_flavors}
                    quantidade={prod.quantidade}
                    bordas={prod.bordas}
                  />
                ))}
              </div>

              {/* </div> */}

              {/* <div className="col-12 d-flex align-items-center mt-2" style={{padding: "0 10px 0 10px"}}>
                                        <div className="input-group">
                                            <input onChange={(e) => setCupomCart(e.target.value)} value={cupomCart} type="text" className={`form-control`} placeholder="Cupom" aria-label="Recipient's username"
                                                aria-describedby="button-addon2" />
                                            <button onClick={ValidarCupom} className="btn  btn-success" type="button" id="button-addon2">Aplicar</button>
                                        </div>
                                    </div> */}

              <div className="align-items-end mt-3" style={{ backgroundColor: localStorage.getItem("sessionMode") === "light" ? "#ffffff71" : "#404040", padding: "10px 20px 10px 20px" }}>
                <div className="col-12 d-flex justify-content-between align-items-center">
                  <span >Subtotal</span>
                  <span>
                    {new Intl.NumberFormat("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    }).format(subtotalCart)}
                  </span>
                </div>
                
               
                <div className="col-12 d-flex justify-content-between align-items-center mt-2">
                  <small>
                    Desconto{" "}
                    {descontoCart > 0 ? (
                      <span className="text-success">{`(cupom ${cupomCart})`}</span>
                    ) : null}
                  </small>
                  <span>
                    -{" "}
                    {new Intl.NumberFormat("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    }).format(descontoCart)}
                  </span>
                </div>

                <div className="col-12 d-flex justify-content-between align-items-center mt-2">
                  <span>Taxa de entrega</span>
                  <span>
                    {new Intl.NumberFormat("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    }).format(entregaCart)}
                  </span>
                </div>

                <div className="col-12 d-flex justify-content-between align-items-center mt-3">
                  <b>Total</b>
                  <h5>
                    {new Intl.NumberFormat("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    }).format(totalCart)}
                  </h5>
                </div>
              </div>
            </>
          )}
        </div>
        {cart.length > 0 && (
          <div style={{ width: "auto" }}>
            <button
              onClick={() => {
                FinalizarPedido();
              }}
              className="w-100 btn btn-lg btn-success align-items-center"
              style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
            >
              {" "}
              Finalizar Pedido{" "}
            </button>
          </div>
        )}
      </div>
      {/* )} */}
    </>
  );
};

export default MiniSidebar;
