import { useContext, useEffect, useState } from "react";
import { CartContext } from "../../contexts/cart";
import { ToastContainer, toast } from "react-toastify";
import Currency from "../../utils/currency";
import "../../styles/scrollCart.css";
import { FormatarDinheiro } from "../../hook/FormatarDinheiro";
import { PagamentoCheckout } from "../../components/checkout/pagamento";
import { CartaoCheckout } from "../../components/checkout/cartao";
import { PixCheckout } from "../../components/checkout/pix";
import LightLogo from "../../assets/logo-grande.png";
import DarkLogo from "../../assets/logo-dark.png";
import Modal from "react-bootstrap/Modal";
import GerenceLogo from "../../assets/gerence.png";
import { FooterPagamentoCheckout } from "../../components/checkout/checkoutFooter/pagamento";
import pattern from '../../assets/patternBorrado.png'
import { BASE_URL } from "../../constants/BASE_URL";

export const CheckoutPagamento = () => {
  const {
    totalCart,
    isMobile,
    darkMode,
    setCheckoutEtapa,
    valorTroco,
    setValorTroco,
    formFinalizarPedido, 
    formPedidoFinalizado,
    setFormFinalizarPedido,
    imagemEmpresa,
    setPagemento,
    formaDePagamento,
    setCupomCart,
    ValidarCupom,
    msgCart,
    descontoCart,
    cupomCart,
    telaHome, 
    nomeEmpresaParams
     
  } = useContext(CartContext);

  const [site, setSite] = useState(false);
  const [entrega, setEntrega] = useState(true);
  const [pagarSite, setPagarSite] = useState(false);

  const [cartao, setCartao] = useState(false);
  const [pix, setPix] = useState(false);
  const [formPix, setFormPix] = useState(false);
  const [dinheiro, setDinheiro] = useState(false);

  const [formCartao, setFormCartao] = useState(false);
  const [modalTroco, setModalTroco] = useState(false);
  const [inputTroco, setInputTroco] = useState(false);
  const [selecionaPagamento, setSelecionaPagamento] = useState(true);


  const cidadeEmpresa = localStorage.getItem("sessionCidade").toLowerCase()
  const imgEmpresa = BASE_URL + `/assets/img/estabelecimentos/${cidadeEmpresa}/${(nomeEmpresaParams + '.png')}`

  useEffect(() => {

    if(dinheiro){
      setPagemento("D")
    }
    if(pix){
      setPagemento("P")
    }
    if(cartao){
      setPagemento("C")
    }
    
  }, [pix, dinheiro, cartao])

  const handleInputChange = (event) => {
    const { formatValueOnKeyUp } = FormatarDinheiro();
    const { value } = event.target;
    setValorTroco(formatValueOnKeyUp(value));
  };

  const handlePaymentSite = () => {
    if (!cartao && !pix) {
      toast.error("Escolha o meio de pagamento", {
        style: { zIndex: 999, position: "absolute" },
      });
    }

    if (pagarSite) {
      if (cartao && !pix) {
        setFormPix(false);
        setPagarSite(true);
        setFormCartao(true);
      } else if (!cartao && pix) {
        setFormCartao(false);
        setPagarSite(true);
        setFormPix(true);
      }
    }
  };

  const handlePaymentEntrega = () => {
    if (!dinheiro && !cartao) {
      toast.error("Escolha o meio para pagar", {
        toastClassName: "custom-toast",
      });
    }
    if (dinheiro && parseFloat(valorTroco) < parseFloat(totalCart)) {
      toast.error("O valor de pagamento esta incorreto", {
        toastClassName: "custom-toast",
      });
    }

    if (dinheiro && !cartao) {

      // Aqui tem que ver se o troco esta certo e caso esteja libera finalizar compra e finaliza o pedido
      const vl_troco = parseFloat(valorTroco) - parseFloat(totalCart)
      setFormFinalizarPedido({ ...formFinalizarPedido, vl_troco });

    }
    if (!dinheiro && cartao) {
      // colocar que vai pagar com cartão na entrega e finalizar o pedido
    }
  };

  return (
    <>
      <Modal
        id="ads"
        show
        scrollable={true}
        centered="true"
        fullscreen
        backdrop="static"
      >
        <Modal.Header>
          <img src={darkMode === "dark" ? DarkLogo : LightLogo} width={130} />
          {nomeEmpresaParams ? <img src={imgEmpresa} width={50} style={{ marginRight: 30, borderRadius: 100 }} /> : <img src={GerenceLogo} width={80} style={{ marginRight: 30 }} />}
        </Modal.Header>

        <Modal.Body style={{  backgroundImage: `url(${pattern})`  }}>
        <div style={ isMobile ? {  backdropFilter: "blur(3px)"} : {margin: "0 500px",backdropFilter: "blur(5px)", display: "grid"}}> 

          <ToastContainer containerId="toast-container" />

          <PagamentoCheckout
            Currency={Currency}
            totalCart={totalCart}
            setEntrega={setEntrega}
            setSite={setSite}
            setPagarSite={setPagarSite}
            setCartao={setCartao}
            setPix={setPix}
            site={site}
            entrega={entrega}
            setDinheiro={setDinheiro}
            setValorTroco={setValorTroco}
            pix={pix}
            cartao={cartao}
            dinheiro={dinheiro}
            modalTroco={modalTroco}
            setModalTroco={setModalTroco}
            inputTroco={inputTroco}
            handleInputChange={handleInputChange}
            valorTroco={valorTroco}
            setInputTroco={setInputTroco}
            pagarSite={pagarSite}
            darkMode={darkMode}
            selecionaPagamento={selecionaPagamento}
            setSelecionaPagamento={setSelecionaPagamento}
            formaDePagamento={formaDePagamento}
            setCupomCart={setCupomCart}
            ValidarCupom={ValidarCupom}
            msgCart={msgCart}
            cupomCart={cupomCart}
            descontoCart={descontoCart}
            formPedidoFinalizado={formPedidoFinalizado}

          />
          

          {pagarSite && (
            <>
              {formCartao && <CartaoCheckout />}

              {/* tem que cadastra o pix da pessoa no banco de dados  */}

              {formPix && <PixCheckout totalCart={totalCart} keyword={"00020126820014BR.GOV.BCB.PIX013671cb4dda-5f23-4390-8a42-9d94682c6a620220Testando o coneraqui5204000053039865802BR5915Maycon Coutinho6009SAO PAULO61080540900062240520OYKfO7XsabLCuRge35576304F267"} img={imagemEmpresa}/>}
            </>
          )}
          </div>


        </Modal.Body>

        <Modal.Footer style={isMobile ? {} : { margin: "0 500px" }}>
          <FooterPagamentoCheckout
            pagarSite={pagarSite}
            formCartao={formCartao}
            setFormCartao={setFormCartao}
            setPagarSite={setPagarSite}
            setEntrega={setEntrega}
            setCartao={setCartao}
            setPix={setPix}
            setFormPix={setFormPix}
            handlePaymentEntrega={handlePaymentEntrega}
            handlePaymentSite={handlePaymentSite}
            setCheckoutEtapa={setCheckoutEtapa}
            selecionaPagamento={selecionaPagamento}
            setSelecionaPagamento={setSelecionaPagamento}
            dinheiro={dinheiro}
            cartao={cartao}
            finalizarPedido={parseFloat(valorTroco) >= parseFloat(totalCart)}
            pix={pix}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};
