import React, { useState } from "react";
import QRCode from "qrcode.react";
import { Stack } from "@mui/material";

export const PixCheckout = ({ keyword, img, totalCart }) => {
  // Gera o texto do QR code com a palavra-chave

  const [copied, setCopied] = useState(false); // Estado para controlar a mensagem de copiado

  const styles = {
    qrCodeContainer: {
      borderRadius: "10px",
      boxShadow: "0px 5px 7px rgba(0, 0, 0, 0.2)",
    },
  };

  const handleCopyClick = () => {
    const input = document.getElementById("keywordInput");
    input.select();
    document.execCommand("copy");
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000); // Limpar a mensagem de copiado após 2 segundos
  };

  return (
    <Stack
      marginTop={5}
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <div style={styles.qrCodeContainer}>
        {/* Gera o QR code com a palavra-chave */}
        <QRCode
          fgColor="#36BFA6"
          style={styles.qrCodeContainer}
          value={keyword}
          size={300}
        >
          {/* <QRCode  fgColor="#41BFB3" style={styles.qrCodeContainer} value={keyword}  size={300} >
      <QRCode  fgColor="#dc3545" style={styles.qrCodeContainer} value={keyword}  size={300} >*/}
        </QRCode>

        <Stack justifyContent="center" alignItems="center" marginTop={-14}>
          {/* aqui fica a imagem do estabelecimento, porem o qr code fica menos preciso e demora mais para ser lido       */}
          <img
            src={img}
            alt="Imagem no Centro do QR Code"
            style={{
              position: "absolute",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "80px", // Defina o tamanho desejado para a imagem
              height: "80px", // Defina o tamanho desejado para a imagem
              borderRadius: "10px",
              boxShadow: "7px 5px 7px 5px rgba(0, 0, 0, 0.2)",
            }}
          />
        </Stack>
      </div>
      <h3 style={{ marginTop: 130 }}>
        {new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
        }).format(totalCart)}
      </h3>

      <Stack justifyContent="center" alignItems="center" direction="row">
        <input
 id="keywordInput"
 value={keyword}
          style={{
            padding: "10px",
            fontSize: "16px",
            border: "1px solid #ccc",
            borderRadius: "5px 0 0 5px",
            marginRight: "-4px",
          }}
        />
        <button
          style={{
            backgroundColor: "#4CAF50",
            color: "white",
            border: "none",
            padding: "10px",

            width: 100,
            borderRadius: "0 5px 5px 0",
            cursor: "pointer",
            textAlign: 'center'
          }}
          onClick={() => handleCopyClick()}
        >
                    {copied ? 'Copiado!' : 'Copiar'}

        </button>
      </Stack>
    </Stack>
  );
};
