import { useEffect, useState } from "react";
import "react-confirm-alert/src/react-confirm-alert.css";
import EditLocationAltIcon from "@mui/icons-material/EditLocationAlt";
import EnderecoModalEditar from "../modal/editar";
import api from "../../../services/api";

function ButtonEditEndereco({ atualizarSite, id_endereco }) {
  const [isEnderecoOpen, setIsEnderecoOpen] = useState(false);
  const [dadosEndereco, setDadosEndereco] = useState(false);

  useEffect(() => {
    if(id_endereco > 0){

      (async () => {

        const response = await api.get(`v1/usuarios/enderecos/${id_endereco}`);
  
        setDadosEndereco(response.data);
      })();
    }
  
  }, [id_endereco]);

  async function openModalEndereco() {
    if (id_endereco > 0) {
      setIsEnderecoOpen(true);
    } else {
      setIsEnderecoOpen(true);
    }
  }
  function closeModalEndereco() {
    setIsEnderecoOpen(false);
  }

  return (
    <>
      {dadosEndereco && (
        <>
          <EnderecoModalEditar
            isOpen={isEnderecoOpen}
            onRequestClose={closeModalEndereco}
            dados_endereco={dadosEndereco}
            id_endereco={id_endereco}
            atualizarSite={atualizarSite && atualizarSite}
          />

          <EditLocationAltIcon
            onClick={() => {
              openModalEndereco();
            }}
            style={{
              color:
                localStorage.getItem("sessionMode") === "dark"
                  ? "#b9b9b9"
                  : "#333",
              fontSize: 30,
            }}
          />
        </>
      )}
    </>
  );
}
export default ButtonEditEndereco;
