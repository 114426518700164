import './style.css';
import api from '../../services/api';
import { useEffect, useState } from "react";

function Pedido(props) {

    const dt_ped = new Date(props.dt_pedido);
    const [avaliar, setAvaliar] = useState(false);
    const [avaliacao, setAvaliacao] = useState(props.avaliacao);
    const [listaPedidosApi, setListaPedidosApi] = useState()


    useEffect(() => {

        (async () => {

            if (props.id_pedido) {

                const listaPedidos = await api.get(`/v1/estabelecimentos/info/${props.id_pedido}`)
                let lista = listaPedidos.data[0].pedido.itens

                setListaPedidosApi(Object.values(lista).map((objeto) => objeto.NOME))
            }

        })()

    }, [])





    const color = ["#eb4034", "#db7a02", "#e3cd02", "#34b1eb", "#11c408", "#eb4034"]

    function Status(st) {
        switch (st) {
            case "0": return "Pedido Cancelado";
            case "5": return "Pedido Cancelado";
            case "1": return "Pedido pendente";
            case "2": return "Pedido recebido";
            case "3": return "Motoboy na estrada";
            case "4": return "Finalizado";
            default: return "";
        }
    }

    function Avaliar(avaliacao) {
        api.patch(`/v1/pedidos/avaliacao/${props.id_pedido}`, {
            avaliacao
        })
            .then(response => {
                setAvaliar(false);
                setAvaliacao(avaliacao);
            })
            .catch(err => {
                console.log(err);
            })
    }

    return <div className="border-bottom pt-3 pb-3 d-flex justify-content-between">

        <div className="d-flex">
            <div className="me-4 img-pedido">
                <img className="img-pedido" src={props.url_imagem} alt="Estabelecimento" />
            </div>

            <div className="d-inline-block">

                <span className="d-block"><b>{props.nome}</b></span>
                <small className="d-block text-danger">Pedido Nº {props.id_pedido}</small>

                <small className="d-block">{props.qtd_item} {props.qtd_item > 1 ? 'itens' : 'item'} -
                    {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(props.vl_total)} -
                    {new Intl.DateTimeFormat('pt-BR').format(dt_ped)}</small>

                <ul>

                    {

                        listaPedidosApi && listaPedidosApi?.map((item) => <li style={{ fontSize: 11 }}> {item} </li>)


                    }

                </ul>


                <div>
                    {
                        !['A', 'P', 'E'].includes(props.status) ? <>
                            {avaliacao > 0 ? <i className='fas fa-star text-warning' /> : <i className='fas fa-star text-secondary' />}
                            {avaliacao > 1 ? <i className='fas fa-star text-warning' /> : <i className='fas fa-star text-secondary' />}
                            {avaliacao > 2 ? <i className='fas fa-star text-warning' /> : <i className='fas fa-star text-secondary' />}
                            {avaliacao > 3 ? <i className='fas fa-star text-warning' /> : <i className='fas fa-star text-secondary' />}
                            {avaliacao > 4 ? <i className='fas fa-star text-warning' /> : <i className='fas fa-star text-secondary' />}
                        </> : null
                    }
                </div>

                <span className="badge" style={{ background: color[props.status], color: "#fff", fontWeight: 400 }}>{Status(props.status)}</span>
            </div>
        </div>

        <div className="d-flex align-items-center">
            {
                props.status == "4" && !avaliar ?
                    <button onClick={(e) => setAvaliar(true)} className="btn btn-outline-danger">Avaliar</button> : null
            }

            {
                avaliar ? <div>
                    <i className='fas fa-star text-secondary' onClick={(e) => Avaliar(1)} style={{ cursor: 'pointer' }} />
                    <i className='fas fa-star text-secondary' onClick={(e) => Avaliar(2)} style={{ cursor: 'pointer' }} />
                    <i className='fas fa-star text-secondary' onClick={(e) => Avaliar(3)} style={{ cursor: 'pointer' }} />
                    <i className='fas fa-star text-secondary' onClick={(e) => Avaliar(4)} style={{ cursor: 'pointer' }} />
                    <i className='fas fa-star text-secondary' onClick={(e) => Avaliar(5)} style={{ cursor: 'pointer' }} />
                </div> : null
            }

        </div>

    </div>
}

export default Pedido;