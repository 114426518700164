import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import LockIcon from "@mui/icons-material/Lock";
import { Box, Grid } from "@mui/material";

const icons = [
  {
    img: (
      <VerifiedUserIcon sx={{ width: 32, height: "auto", color: "#008000" }} />
    ),
    text: "Compra 100% segura",
  },
  {
    img: <FingerprintIcon sx={{ width: 32, height: "auto", color: "#333" }} />,
    text: "Privacidade protegida",
  },
  {
    img: <LockIcon sx={{ width: 32, height: "auto", color: "#ff2400" }} />,
    text: "Site verificado e protegido",
  },
];

export const CartaoCheckout = () => {
  return (
    <>
      <>
        <h4 className="mt-0 mb-3"> Cartão de Crédito </h4>

        <form onSubmit={() => {}}>
          <div className="row">
            <div className="col-xs-12 mb-2">
              <input
                type="text"
                id="number"
                placeholder="Número do cartão"
                style={{ fontWeight: "bold" }}
                className="form-control"
              />
            </div>
            <div className="col-8 col-sm-9 mb-2">
              <input
                type="text"
                id="expirydate"
                placeholder="Data de validade"
                style={{ fontWeight: "bold" }}
                className="form-control"
              />
            </div>
            <div className="col-4 col-sm-3 mb-2">
              <input
                type="text"
                id="cvc"
                placeholder="CVC"
                style={{ fontWeight: "bold" }}
                className="form-control"
              />
            </div>
            <div className="col-xs-12">
              <input
                type="text"
                id="name"
                placeholder="Nome no cartão"
                style={{ fontWeight: "bold" }}
                className="form-control"
              />
            </div>
          </div>
        </form>

        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mt: { xs: 3 },

          }}
        >
          <Grid container spacing={2}  >
            {icons.map((icon, index) => (
              <Grid
                item
                xs={12}
                key={index}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Grid
                  container
                  spacing={2}
                  sx={{
                    alignItems: "center",
                    justifyContent: "flex-start",
                    textAlign: "center",
                  }}
                >
                  <Grid item xs="auto">
                    {icon.img}
                  </Grid>
                  <Grid item xs="auto" className="cursor-default">
                    {icon.text}
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Box>
      </>
    </>
  );
};
