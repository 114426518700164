import PixIcon from "@mui/icons-material/Pix";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import Modal from "react-bootstrap/Modal";
import { Delete } from "@mui/icons-material";
import { toast } from "react-toastify";
import { useContext, useState } from "react";
import { CartContext } from "../../../contexts/cart";

export const PagamentoCheckout = ({
  Currency,
  totalCart,
  setEntrega,
  setSite,
  setPagarSite,
  setCartao,
  setPix,
  site,
  entrega,
  // setTroco,
  setDinheiro,
  setValorTroco,
  pix,
  cartao,
  dinheiro,
  modalTroco,
  setModalTroco,
  inputTroco,
  handleInputChange,
  valorTroco,
  setInputTroco,
  pagarSite,
  darkMode,
  selecionaPagamento,
  setSelecionaPagamento,
  formaDePagamento,
  setCupomCart,
  ValidarCupom,
  cupomCart,
  msgCart,
  descontoCart,
  formPedidoFinalizado
}) => {
  const [erroTroco, setErroTroco] = useState(false);


  const fdPagamento = formaDePagamento

  if (fdPagamento === 'E') {
    setEntrega(true)
    setSite(false)

  }
  if (fdPagamento === 'S') {
    setEntrega(false)
    setSite(true)
  }

  const {

    isMobile,

  } = useContext(CartContext);

  const handleTroco = (e) => {
    e.preventDefault();

    <>
      <Modal.Header
        style={{
          position: "relative",
          alignItems: "inherit",
          borderBottom: "none",
        }}
      >
        <Modal.Title
          style={{
            width: "100%",
            fontSize: "inherit",
            fontWeight: "inherit",
            alignItems: "inherit",
          }}
        >
          <div className="d-flex align-items-center justify-content-center">
            <h6 className="d-flex align-items-center m-0 cursor-default">
              <span>Dinheiro</span>
            </h6>
          </div>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="pt-0">
        <div className="mb-3 text-center">
          Você vai precisar de troco?
        </div>

        <div className="row">
          <div className="col">
            <button
              className="btn btn-outline-danger w-100"
              onClick={() => {
                setModalTroco(false);
                // setTroco(false);
              }}
            >
              Não
            </button>
          </div>
          <div className="col ps-0">
            <button
              className="btn btn-danger w-100"
              onClick={() => {
                setInputTroco(true);
              }}
            >
              Sim
            </button>
          </div>
        </div>
      </Modal.Body>
    </>
    if (parseFloat(valorTroco) < parseFloat(totalCart)) {
      // toast.error("Valor deve ser maior que o total.");
      setErroTroco("Valor deve ser maior que o total.");

      return;
    }

    if (valorTroco <= 0) {
      toast.error("Troco não informado.");
      return;
    }

    if (parseFloat(valorTroco) > parseFloat(totalCart)) {
      setModalTroco(false);
      setSelecionaPagamento(true);
    }
  };

  return (
    <>
      {selecionaPagamento && (
        <>
          <div style={{ marginBottom: 5 }}>
            <p style={{ marginBottom: 5, padding: 0, display: 'flex' }} > Desconto: <div className="input-group justify-content-between" style={{ paddingLeft: 10 }}>
              {/* {msgCart.length > 0 ? <small className="text-danger">{msgCart}</small> : null} */}
              <span className="d-inline-block text-success">
                - {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(descontoCart)}
              </span>
            </div> </p>
            <p style={{ marginBottom: 5, padding: 0 }}> Taxa de serviço: {Currency.formatterValue.format(formPedidoFinalizado.vl_taxa_entrega)} </p>
            <p style={{ marginBottom: 5, padding: 0, fontWeight: 700 }}>Total: {Currency.formatterValue.format(totalCart)}</p>

          </div>
          <div>

          </div>
        




          <div className="input-group" style={{ padding: "5px 0 5px 0", marginBottom: 10 }}>
            <input onChange={(e) => setCupomCart(e.target.value)} value={cupomCart} type="text" className={`form-control`} placeholder="Cupom" aria-label="Recipient's username"
              aria-describedby="button-addon2" />
            <button onClick={ValidarCupom} className="btn  btn-success" type="button" id="button-addon2">Aplicar</button>
          </div>



          <div className="d-flex align-items-center justify-content-center">
            <label
              className="d-flex align-items-center"
              htmlFor="retirar"
              style={{ cursor: "pointer", display: fdPagamento === "S" ? "none" : "" }}
            >
              <input
                type="radio"
                id="retirar"
                name="pagar"
                className="form-check-input mt-1 me-1 shadow-none"
                style={{ cursor: "pointer", display: fdPagamento === "S" ? "none" : "" }}
                checked={entrega}
                onChange={() => {
                  setEntrega(true);
                  setSite(false);
                  setPagarSite(false);
                  setCartao(false);
                  setPix(false);
                }}
              />
              <h6
                className="mb-0 mt-1 text-uppercase"
                style={{ whiteSpace: "nowrap", display: fdPagamento === "S" ? "none" : "" }}
              >
                Pagar na entrega
              </h6>
            </label>
            <label
              className="ms-3 d-flex align-items-center"
              htmlFor="entregar"
              style={{ cursor: "pointer", display: fdPagamento === "E" ? "none" : "" }}
            >
              <input
                type="radio"

                id="entregar"
                name="pagar"
                className="form-check-input mt-1 me-1 shadow-none"
                style={{ cursor: "pointer", display: fdPagamento === "E" ? "none" : "" }}
                checked={site}
                onChange={() => {
                  setEntrega(false);
                  setSite(true);
                  setPagarSite(true);

                  setCartao(false);
                  setPix(false);
                  setDinheiro(false);

                  // setTroco("");
                  setValorTroco("");
                }}
              />

              <h6
                className="mb-0 mt-1 text-uppercase"
                style={{ whiteSpace: "nowrap", display: fdPagamento === "E" ? "none" : "" }}
              >
                Pagar pelo site
              </h6>
            </label>
          </div>
        </>
      )}

      {selecionaPagamento && site && (
        <>
          <div
            className="d-flex flex-column"
            style={{ margin: "10px 20px 0 20px" }}
          >
            <div className="row my-3">
              <div className="col ">
                <button
                  className={`btn btn-lg border border-success ${pix ? "bg-pix" : "bg-outline-pix"
                    } w-100 shadow-none`}
                  onClick={() => {
                    setPix(!pix);
                    setCartao(false);
                    setPagarSite(true);
                  }}
                  style={{
                    textDecoration: "none",
                    borderRadius: 5,
                  }}
                >
                  <div
                    className="d-flex flex-column align-items-center justify-content-center "
                  // background: localStorage.getItem('sessionMode') === "dark" ?
                  >
                    <PixIcon
                      sx={{
                        width: 42,
                        height: 42,
                      }}
                    />
                    <h5 > Pix  </h5>
                  </div>
                </button>
              </div>
              <div className="col">
                <button
                  className={`btn btn-lg ${cartao ? "btn-danger" : "btn-outline-danger"
                    } text w-100 shadow-none`}
                  onClick={() => {
                    setPagarSite(true);
                    setCartao(!cartao);
                    setPix(false);
                  }}
                >
                  <div className="d-flex flex-column align-items-center justify-content-center ">
                    <CreditCardIcon sx={{ width: 42, height: 42 }} />
                    <h5>Cartão</h5>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </>
      )}

      {entrega && (
        <>
          <div
            className="d-flex flex-column"
            style={{ margin: "10px 20px 0 20px" }}
          >
            <div className="row my-3">
              <div className="col">
                <button
                  className={`btn btn-lg ${darkMode === "dark"
                    ? dinheiro
                      ? "btn-secondary"
                      : "btn-outline-secondary"
                    : dinheiro
                      ? "btn-dark"
                      : "btn-outline-dark"
                    } text w-100 shadow-none`}
                  onClick={() => {
                    setPagarSite(false);
                    setModalTroco(true);
                    setDinheiro(true);
                    setCartao(false);
                    setPix(false);

                  }}
                >
                  <div className="d-flex flex-column align-items-center justify-content-center ">
                    <AttachMoneyIcon sx={{ width: 42, height: 42 }} />
                    <h5>Dinheiro</h5>
                  </div>
                </button>
              </div>


              <div className="col"                   
>
                <button
                  // className={`btn btn-lg border border-success ${pix ? "bg-pix" : "bg-outline-pix"
                  //   } w-100 shadow-none`}
                  className={`btn btn-lg border border-success ${pix ? "bg-pix" : "bg-outline-pix"
                } w-100 shadow-none`}
                  onClick={() => {
                    setPix(!pix);
                    setCartao(false);
                    setPagarSite(false);
                    setDinheiro(false);
                    setValorTroco("");
                  }}

                  
                  style={{
                    textDecoration: "none",
                    // backgroundColor: pix ? "#059146" : "#00000000",

                    borderRadius: 5,
                  }}
                >
                  <div
                    className="d-flex flex-column align-items-center justify-content-center "
                    
                  // background: localStorage.getItem('sessionMode') === "dark" ?
                  >
                    <PixIcon
                      sx={{
                        width: 42,
                        height: 42,
                       
                      }}
                    />
                    <h5 > Pix </h5>
                  </div>
                </button>
              </div>
              <div className="col" style={isMobile ? { marginTop: 20 } : {}}>
                <button
                  className={`btn btn-lg ${cartao ? "btn-danger" : "btn-outline-danger"
                    } text w-100 shadow-none`}
                  onClick={() => {
                    setPagarSite(false);
                    setCartao(!cartao);
                    setDinheiro(false);
                    // setTroco("");
                    setValorTroco("");
                    setPix(false);

                  }}
                >
                  <div className="d-flex flex-column align-items-center justify-content-center ">
                    <CreditCardIcon sx={{ width: 42, height: 42 }} />
                    <h5>Cartão </h5>
                  </div>
                </button>
              </div>
            </div>
          </div>

          <Modal
            show={modalTroco}
            onHide={() => {
              setModalTroco(false);
            }}
            scrollable="true"
            className="no-border"
            backdropClassName="backdropFilter"
            size="sm"
            keyboard="false"
          >
            {
              <>
                <Modal.Body className="text-center">
                  <p>
                    Seu pedido deu{" "}
                    {new Intl.NumberFormat("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    }).format(totalCart)}
                  </p>

                  <div className="text-muted">
                    Digite quanto vai pagar em dinheiro para que o entregador
                    leve o seu troco.
                  </div>

                  <form
                    className="d-flex flex-column align-items-center justify-content-center mt-2"
                    onSubmit={handleTroco}
                  >
                    <div className="d-flex align-items-center w-100 mb-3">
                      <label htmlFor="valorTroco">
                        <h4 className="m-0 text-secondary cursor-default">
                          <b style={{ color: erroTroco ? "red" : null }}>R$</b>
                        </h4>
                      </label>

                      <input
                        id="valorTroco"
                        type="text"
                        onChange={(e) => {
                          setErroTroco(false);
                          handleInputChange(e);
                        }}
                        value={valorTroco}

                        placeholder="0,00"
                        className="form-control w-100 ms-2"
                        style={{
                          borderColor: erroTroco ? "red" : null,
                          color: erroTroco ? "red" : null,
                        }}
                      ></input>
                    </div>
                    {erroTroco && <p style={{ color: "red" }}> {erroTroco} </p>}

                    <button
                      type="submit"
                      className="btn btn-danger w-100"
                      onClick={() => {
                        if (parseFloat(valorTroco) >= parseFloat(totalCart)) {
                          setPagarSite(false);
                        }
                      }}
                    >
                      Confirmar
                    </button>
                  </form>
                </Modal.Body>
              </>
            }
          </Modal>

          {dinheiro && valorTroco && (
            <>
              <div className="d-flex align-items-center justify-content-center">
                <div className="d-flex flex-column align-items-center">
                  <div style={{ marginTop: 10 }}>
                    Dinheiro:{" "}
                    <b style={{ marginLeft: 10 }}>
                      {Currency.formatterValue.format(
                        valorTroco.replace(",", ".")
                      )}
                    </b>
                  </div>
                  <div style={{ marginTop: 10, display: 'flex', alignItems: 'center' }}>
                    Troco: voltar
                    <b style={{ marginLeft: 10 }}>
                      {Currency.formatterValue.format(
                        parseFloat(valorTroco) - parseFloat(totalCart)
                      )}

                    </b>
                    <button
                      className="btn btn-link btn-sm text-danger rounded-full shadow-none p-0"
                      style={{ marginLeft: 10 }}
                      onClick={() => {
                        // setPagarSite(!pagarSite);
                        // setDinheiro(false);
                        // setTroco("");
                        setValorTroco("0");
                      }}
                    >
                      <Delete />
                    </button>
                  </div>
                </div>
              </div>

            </>
          )}
        </>
      )}
    </>
  );
};
