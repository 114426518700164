import React, { useState, useEffect } from 'react'

import './style.css'

import Modal from 'react-bootstrap/Modal'

import api from '../../../services/api'
import CloseIcon from '@mui/icons-material/Close';

import FacebookIcon from '@mui/icons-material/Facebook';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkIcon from '@mui/icons-material/Link';

import { Box, Autocomplete, TextField, IconButton } from '@mui/material'
import { HiSearch } from 'react-icons/hi'
import { toast } from 'react-toastify'

const LogoModal = (props) => {

    const handleToClipboard = () => {
        navigator.clipboard.writeText(window.location.href)
        toast.success('Link copiado')
    }

    return (
        <>
            <Modal
                show={props.isOpen}
                onHide={() => { props.onRequestClose(); }}
                scrollable='true'
                id='modal-cover'
                backdropClassName='backdropFilter'
            >
                <div style={{ position: 'relative', backgroundSize: 'cover', backgroundRepeat: 'noe-repeat', minHeight: '100%', }}>
                    <div 
                        style={{ 
                            width: '100%',
                            height: 'auto',
                            position: 'absolute', 
                            content: "", 
                            top: 0, 
                            left: 0, 
                            bottom: 0, 
                            right: 0, 
                            background: `url(${props.logo})`,
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                            opacity: .075,
                        }}
                    />
                    <Modal.Header className='pb-0' style={{ position: 'relative', alignItems: 'inherit', borderBottom: 'none' }}>

                        <Modal.Title style={{ width: '100%', fontSize: 'inherit', fontWeight: 'inherit', alignItems: 'inherit' }}>
                            <div>
                                <div className='d-flex flex-column justify-content-center w-100'>
                                        <img 
                                            src={props.logo} alt={props.nome} 
                                            className='img-fluid rounded shadow' 
                                            style={{ display: 'flex', margin: 'auto', maxHeight: '130px' }} 
                                        />
                                    <h4 className="mt-2 mb-0 pe-3 text-center cursor-default">{props.nome}</h4>
                                </div>

                                <div className="classificacao text-center mb-2 cursor-default">
                                    <span>{props.endereco} {props.complemento.length > 0 ? ' - ' + props.complemento : null} - {props.bairro} - {props.cidade} - {props.uf}</span>
                                </div>

                                <button 
                                    onClick={() => { props.onRequestClose(); }} 
                                    className='btn btn-sm btn-link text-danger btn-fechar' 
                                    style={{ position: 'absolute', right: 8, top: 8 }}
                                >
                                    <CloseIcon style={{ fontSize: 32 }} />
                                </button>
                            </div>

                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body className='pt-0'>
                        
                        <div className='container-fluid'>
                            <div className='social-buttons d-flex justify-content-center'>

                                {props.facebook && (
                                    <a
                                        href={props.facebook}
                                        alt='Facebook'
                                        target='_blank'
                                        style={{
                                            borderRadius: '100%',
                                            padding: '6px',
                                            border: 'none',
                                            background: '#1775f1',
                                            color: '#FFF',
                                        }}
                                    >
                                        <FacebookIcon />
                                    </a>
                                )}
                                {props.instagram && (
                                    <a
                                        href={props.instagram}
                                        style={{
                                            borderRadius: '100%',
                                            padding: '6px',
                                            border: 'none',
                                            background: '#E1306C',
                                            color: '#FFF',
                                        }}
                                    >
                                        <InstagramIcon />
                                    </a>
                                )}
                                {props.whatsapp && (
                                    <a
                                        style={{
                                            borderRadius: '100%',
                                            padding: '6px',
                                            border: 'none',
                                            background: '#25D366',
                                            color: '#FFF',
                                        }}
                                    >
                                        <WhatsAppIcon />
                                    </a>
                                )}

                                <button
                                    style={{
                                        borderRadius: '100%',
                                        padding: '6px',
                                        border: 'none',
                                        background: '#6c757d',
                                        color: '#FFF'
                                    }}
                                    onClick={handleToClipboard}
                                >
                                    <LinkIcon />
                                </button>

                            </div>
                        </div>

                    </Modal.Body>
                    
                </div>
            </Modal>
        </>
    )
}

export default LogoModal