import React, { useState, useEffect } from 'react'

import { ToastContainer } from 'react-toastify';

import './style.css'

import { createTheme, ThemeProvider, CssBaseline } from '@mui/material'

import { Link } from 'react-router-dom'

import { AppBar, Toolbar, Box, Button } from '@mui/material'

import pattern from '../../assets/pattern.png'
import logo from '../../assets/logo-grande.png'

import { Container, Typography } from '@mui/material'

/// import { AutoComplete } from '../../components'

import api from '../../services/api';

import { toast } from 'react-toastify'

import SaltPassword from '../../services/md5';

import { useNavigate } from 'react-router-dom';

import { Autocomplete, TextField, IconButton, Grid } from '@mui/material'

import { HiSearch } from 'react-icons/hi'

import DevicesIcon from '@mui/icons-material/Devices'
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining'
import RoomServiceIcon from '@mui/icons-material/RoomService'

const Welcome = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const navigate = useNavigate()

    const theme = createTheme({
        palette: {
            primary: {
                main: '#dc3545',
            },
        },
    })

    const [cidades, setCidades] = useState([])

    const [cidade, setCidade] = useState('');
    const [uf, setUF] = useState('');    
    const [codCidade, setCodCidade] = useState('');

    useEffect(() => {
        if(localStorage.getItem('sessionToken')) {
            navigate('/home')
        }
        const carregarCidades = async() => {
            const response = await api.get('v1/cidades')
            setCidades(response.data)
        }
        carregarCidades()
    }, [navigate])

    const handleEscolherCidade = (e) => {
        e.preventDefault()

        if(!codCidade) {
            toast.error('Escolhe uma cidade');
        } else {
            api.post('v1/usuarios/login', {
                email: 'visitante@comeraqui.com.br',
                senha: SaltPassword('ger5455++')
            })
            .then(response => {
                localStorage.setItem('sessionToken', response.data.token);
                localStorage.setItem('sessionId', response.data.id_usuario);
                localStorage.setItem('sessionEmail', 'visitante@comeraqui.com.br');
    
                localStorage.setItem('sessionCodCidade', codCidade);
                localStorage.setItem('sessionCidade', cidade);
                localStorage.setItem('sessionUF', uf);

                localStorage.setItem('sessionMode', response.data.mode)
                
                navigate(`/home`);
            })
            .catch(err => {
                console.log(err)
            })
        }
    }

    const handleSelecionarCidade = (value) => {        
        if(value === 'CIANORTE - PR') {
            setCidade('CIANORTE'); setUF('PR'); setCodCidade(4105508)
        } else if(value === 'TERRA BOA - PR') {
            setCidade('TERRA BOA'); setUF('PR'); setCodCidade(4127205)
        }
    }

    const [inputValue, setInputValue] = useState("");
    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        if (inputValue.length > 2) {
            setOpen(true);
        }
    };
    const handleInputChange = (event, newInputValue) => {
        setInputValue(newInputValue);
        if (newInputValue.length > 2) {
            setOpen(true);
        } else {
            setOpen(false);
        }
    };

    return (
        <>
            <ThemeProvider theme={theme}>
                <CssBaseline />

                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                />

                <AppBar position='fixed' color='inherit' elevation={0} sx={{ bgcolor: '#FFF' }}>
                    <Toolbar sx={{ minHeight: '64px!important' }}>

                        <Box flexGrow={1} />

                        <Button sx={{ width: { xs: '100%', md: 'auto' }, color: '#dc3545!important' }} LinkComponent={Link} to='/cadastro'>
                            Cadastrar
                        </Button>
                    
                        <Button 
                            variant='contained' disableElevation  LinkComponent={Link} to='/login' 
                            sx={{ ml: .75, width: { xs: '100%', md: 'auto' }, color: '#fff!important' }}
                        >
                            Entrar
                        </Button>
                        
                    </Toolbar>
                </AppBar>

                <Box style={{ paddingTop: '94px' }}>
                    <Box sx={{ 
                        display: 'flex', 
                        alignItems: 'center', 
                        py: 3, 
                        background: `url(${pattern})`,
                        backgroundRepeat: 'repeat',
                        backgroundPosition: 'center',
                        backgroundColor: '#FFF',
                    }}
                    >
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: 'auto' }}>
                            
                            <img src={logo} alt='Comer Aqui - Gerence Sistemas' className='img-logo' height='auto' style={{ m: 'auto' }} />
                            
                            <Container maxWidth='sm' sx={{ textAlign: 'center' }}>
                                <Typography variant='h6' color='ButtonText' fontFamily='Poppins' sx={{ my: 3 }}>O <span style={{ color: '#dc3545', fontWeight: 600 }}>sistema ideal</span> para pedir sua <span style={{ color: '#dc3545', fontWeight: 600 }}>comida favorita</span></Typography>
                            </Container>

                            <div className='d-none'>
                                {/* <AutoComplete /> */}
                            </div>

                            <Container maxWidth='sm'>
                                <Box component='form' onSubmit={handleEscolherCidade} sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Autocomplete
                                        fullWidth
                                        noOptionsText='A cidade não possui estabelecimentos.'
                                        options={cidades && cidades.map((c) => c.cidade + ' - ' + c.uf  )}
                                        renderInput={(params) => (
                                            <TextField {...params} label="Escolher cidade" />
                                        )}
                                        onChange={(event, value) => handleSelecionarCidade(value)}
                                        open={open}
                                        onOpen={handleOpen}
                                        onClose={() => setOpen(false)}
                                        inputValue={inputValue}
                                        onInputChange={handleInputChange}
                                    />
                                    <IconButton
                                        type='submit'
                                        className='search-button'
                                        sx={{ ml: .75, color: '#FFF', backgroundColor: '#dc3545' }}
                                    >
                                        <HiSearch />
                                    </IconButton>
                                </Box>
                            </Container>

                        </Box>
                    </Box>
                </Box>

                <Container maxWidth='false' sx={{ pt: 3 }}>
                    <Typography variant='h5' color='primary' align='center' sx={{ mb: 2, fontWeight: 600 }}>Como Comer Aqui?</Typography>

                    <Grid id="sobre" container sx={{ textAlign: 'center', mb: 5, pb: 5 }}>
                        <Grid item xs={12} md={4}>
                            <DevicesIcon sx={{ width: 128 , height: 128 }} />
                            <Typography>Escolha o estabelecimento aonde estiver.</Typography>
                        </Grid>
                        <Grid item xs={6} md={4}>
                            <DeliveryDiningIcon sx={{ width: 128 , height: 128 }} />
                            <Typography>Faça seu pedido e aguarde sua entrega.</Typography>
                        </Grid>
                        <Grid item xs={6} md={4}>
                            <RoomServiceIcon sx={{ width: 128 , height: 128 }} />
                            <Typography>Tenha um bom apetite e volte sempre.</Typography>
                        </Grid>
                    </Grid>
                </Container>

                <Typography align='center' variant='h5' sx={{ fontWeight: 600 }}>Aumente suas vendas!</Typography>
                <Typography align='center' variant='h4' color='primary' sx={{ mb: 3, fontWeight: 600 }}>Cadastre seu negócio.</Typography>

                <Box sx={{ py: 5, bgcolor: '#dc3545', color: '#FFF' }}>
                    <Container maxWidth='sm'>
                        <Typography align='center' sx={{ mb: 2, marginBottom: 4 }}>Tenha seu estabelecimento online! <br />Preencha o formulário abaixo e entraremos em contato!</Typography>

                        <TextField
                            label='Nome do Estabelecimento'
                            variant='filled'
                            fullWidth
                            sx={{ bgcolor: '#FFF', borderTopLeftRadius: '4px', borderTopRightRadius: '4px', '&hover': { borderBottom: 'none' }}}
                        />

                        <TextField
                            label='Nome do Contato'
                            variant='filled'
                            fullWidth
                            sx={{ bgcolor: '#FFF', marginTop: 2 }}
                        />

                        <TextField
                            label='Telefone de Contato'
                            variant='filled'
                            fullWidth
                            sx={{ bgcolor: '#FFF', mb: 2, borderBottomLeftRadius: '4px', borderBottomRightRadius: '4px',marginTop: 2 }}
                        />

                        <Button
                            variant='outlined'
                            color='inherit'
                            fullWidth
                            disableElevation
                            size='large'

                            sx={{marginTop: 2}}
                        >
                            Cadastrar
                        </Button>

                    </Container>
                </Box>

                <div className='container py-3'>
                    <div className="footer row align-items-center">
                        <div className="col">
                            <div className="copyright">
                                © {new Date().getFullYear()} <Link to='/' className='text-decoration-none comer'><b><span className='text-dark font-bold'>comer</span><span className='text-danger'>aqui</span></b></Link>
                                <div><small>Todos os direitos reservados</small></div>
                            </div>
                        </div>
                        <div className='col-auto'>
                            <div><small><a href='https://gerencesistemas.com.br/' target='_blank' rel="noreferrer" className='text-decoration-none text-danger gerence'><b>Gerence Sistemas</b></a></small></div>
                        </div>
                    </div>
                </div>

            </ThemeProvider>
        </>
    )
}

export default Welcome