import React from "react";
import { toast } from "react-toastify";

import "./style.css";

import iconLgLight from "../../../assets/icon-lg-light.png";
import iconLgDark from "../../../assets/icon-lg-dark.png";

function Produto(props) {
  const dark = localStorage.getItem('sessionMode') === 'dark'

  return (
    <div
      // className="col-sm-6 mb-3"
      style={{
        borderRadius: "9px 9px",
        backgroundColor: dark ? "#2e2e2e" : "#FBFBFB",
        boxShadow: "1px 1px 1px 1px rgba(0, 0, 0, 0.1)",
        width: 500,
        height: 200,
        cursor: "pointer",
        display: "flex",
        flexDirection: "row",
        marginBottom: 25,
        marginLeft: props.isMobile ? "" : "25px",
        padding: 0



      }}
      onClick={(e) =>
        props.situacao === 0
          ? toast.error("Estabelecimento fechado")
          : props.onClickProduto(props.id_produto)
      }
    >
      {props.url_foto ? (
        <img
          alt="Produto"
          src={props.url_foto}
          style={{
            width: "100%",
            objectFit: "cover",
            minWidth: "130px",
            borderRadius: "8px 0 0 8px",
            backgroundPosition: "center",
          }}
        />
      ) : (
        <>
          {localStorage.getItem("sessionMode") === "light" ? (
            <img
              // className="img-fluid rounded"
              alt="Produto"
              src={iconLgLight}
              style={{ opacity: 0.35 }}
            />
          ) : (
            <img
              // className="img-fluid rounded"
              alt="Produto"
              src={iconLgDark}
              style={{ opacity: 0.35 }}
            />
          )}
        </>
      )}

      <div

        style={{ display: "flex", flexDirection: "column", width: 800 }}
      >
        <h4
          style={{
            fontWeight: "semibold",
            color: dark ? "#fff" : "#b80000",
            marginTop: "20px",
            marginLeft: 10,
            marginBottom: "8px",
          }}
        >
          {props.nome}
        </h4>
        <p
          style={{
            fontSize: "14px",
            color: "#6d6d6d",
            marginLeft: 20,
            height: "100px",
            marginBottom: "4px",

          }}
        >
          {props.descricao}
        </p>
        {props.vl_promocao > 0 ? (
          <div style={{textAlign: 'end', marginRight: 20}}>
            <span className="badge bg-success" style={{ width: 80 }}>
              {new Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL",
              }).format(props.vl_promocao)}
            </span>

            <small style={{marginLeft: 5, color: '#bf0d0a'}} ><s>  {new Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL",
            }).format(props.vl_produto)} </s>

            </small>

          </div>
        ) : (
          props.vl_produto && (
            <h5 style={{ fontWeight: "semibold", textAlign: "end", marginRight: 20 }}>
              {new Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL",
              }).format(props.vl_produto)}
            </h5>
          )
        )}
      </div>

    </div>
  );
}

export default Produto;
