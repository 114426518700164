import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { CartContext } from "../../contexts/cart";
import { ToastContainer } from "react-toastify";

import "../../styles/scrollCart.css";
import { CarrinhoCheckout } from "../../components/checkout/carrinho";

import LightLogo from "../../assets/logo-grande.png";
import DarkLogo from "../../assets/logo-dark.png";

import Modal from "react-bootstrap/Modal";
import GerenceLogo from "../../assets/gerence.png";
import { FooterCarrinhoCheckout } from "../../components/checkout/checkoutFooter/carrinho";
import { BASE_URL } from "../../constants/BASE_URL";

export const CheckoutCarrinho = () => {
  const navigate = useNavigate();
  const {
    cart,
    RemoveItemCart,
    subtotalCart,
    descontoCart,
    cupomCart,
    entregaCart,
    totalCart,
    nomeEmpresaParams,
    isMobile,
    darkMode,
    setCheckoutEtapa,
    checkoutEtapa,
    ValidarCupom,
    setCupomCart,
    telaHome
  } = useContext(CartContext);

  const cidade = localStorage.getItem("sessionCidade").toLowerCase()
  const imgEmpresa = BASE_URL + `/assets/img/estabelecimentos/${cidade}/${(nomeEmpresaParams + '.png')}`

  return (
    <>
      <Modal
        id="ads"
        show
        scrollable={true}
        centered="true"
        fullscreen
        backdrop="static"
      >
        <Modal.Header>
          <img src={darkMode === "dark" ? DarkLogo : LightLogo} width={130} />
          {nomeEmpresaParams ? <img src={imgEmpresa} width={50} style={{ marginRight: 30, borderRadius: 100 }} /> : <img src={GerenceLogo} width={80} style={{ marginRight: 30 }} />}
        </Modal.Header>

        <Modal.Body style={isMobile ? {} : { margin: "0 500px" }}>
          <ToastContainer containerId="toast-container" />

          <CarrinhoCheckout
            cart={cart}
            nomeEmpresaParams={nomeEmpresaParams}
            RemoveItemCart={RemoveItemCart}
            navigate={navigate}
          />
        </Modal.Body>

        <Modal.Footer style={isMobile ? {} : { margin: "0 500px" }}>
          <FooterCarrinhoCheckout
            cart={cart}
            navigate={navigate}
            subtotalCart={subtotalCart}
            descontoCart={descontoCart}
            cupomCart={cupomCart}
            entregaCart={entregaCart}
            totalCart={totalCart}
            nomeEmpresaParams={nomeEmpresaParams}
            setCheckoutEtapa={setCheckoutEtapa}
            checkoutEtapa={checkoutEtapa}
            setCupomCart={setCupomCart}
            ValidarCupom={ValidarCupom}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};
