import { useEffect, useState } from "react";
import "./style.css";
import api from "../../../../services/api";
import axios from "axios";

function TelefoneModal({
  telefone,
  nome,
  cep,
  setTelefone,
  setNome,
  setCEP,
  endereco,
  setEndereco,
  complemento,
  setComplemento,
  bairro,
  setBairro,
  cidade,
  setCidade,
  setUF,
  // codCidade,
  // setCodCidade,
  // enderecos,
  // SelecionarEndereco,
  atualizarSite,
  uf,
  setEnderecoTelefone,
  setUsuarioExiste,
  // setId_Endereco,
  setRetirar,
  setEntregar,
  entregar,
  retirar,
  buscarTelefone,
  navigate,
  setCheckoutEtapa

}) {
  const [numeroValido, setNumeroValido] = useState(false);
  const [mudando, setMudando] = useState(false);
  const [pegouInfoNumero, setPegouInfoNumero] = useState(false);
  const numeroTelefoneQuantidade = 11;

  async function EnderecoPorCep(cep) {
    try {
      const infoCep = await api.get(`/v1/endereco/${cep}`);

      return infoCep;
    } catch {
      alert("CEP não existe");
    }
  }

  async function EnderecoPorTelefoneApi(telefone) {
    try {

      if (telefone) {
        const resultApi = await api.get(`v1/usuarios/login/${telefone}`);

        if (!!resultApi?.data) {
          localStorage.setItem("telefone", telefone);
          localStorage.setItem("sessionToken", resultApi?.data.token);

          localStorage.setItem("sessionToken", resultApi?.data.token);
          localStorage.setItem("sessionId", resultApi?.data.id_usuario);

          // Como a pessoa não é cadastrada ela não tem email, caso fique com não entra na pagina
          localStorage.setItem("sessionEmail", "");
          localStorage.setItem("sessionCodCidade", resultApi?.data.cod_cidade);
          localStorage.setItem("sessionCidade", resultApi?.data.cidade);
          localStorage.setItem("sessionUF", resultApi?.data.uf);
          localStorage.setItem("sessionMode", resultApi?.data.mode);
          const { nome, cep, complemento } = resultApi?.data;

          setNome(nome);
          setCEP(cep?.replace("-", ""));
          setComplemento(complemento);
          setNumeroValido(true);
          setUsuarioExiste(true);

          
          setCheckoutEtapa(2);
          navigate("/checkout/pagamento");
          
        } else {
          setNumeroValido(false);
          atualizarSite();
          setUsuarioExiste(false);
        }
      }
    } catch {
      setNumeroValido(false);
      atualizarSite();
      setUsuarioExiste(false);
    }
  }

  useEffect(() => {
    setEnderecoTelefone(true);
    atualizarSite();
  }, [mudando]);

  useEffect(() => {
    EnderecoPorTelefoneApi(telefone);

    if(telefone.length === 11){
       EnderecoPorTelefoneApi(telefone)
    }

    if (cep.length < 8) {
      setBairro("");
      setCidade("");
      setUF("");
      setEndereco("");
      atualizarSite();
      setEnderecoTelefone(false);
    }

    // função em que libera a proxima etapa (Não funciona)
     if (telefone.length === 11 && nome && cep.length === 8) {
       setEnderecoTelefone(false);
     } else {
       atualizarSite();
       setEnderecoTelefone(true);
     }

    if (cep.length === 8) {
      (async () => {
        const responseApi = await EnderecoPorCep(cep);
        const api = responseApi.data;
        setBairro(api.bairro);
        setEndereco(api.logradouro);
        setCidade(api.localidade);
        setUF(api.uf);
        atualizarSite();
      })();
    }
  }, [cep, telefone, nome, buscarTelefone]);

  return (
    <div className="endereco">
      <div
        className="d-flex align-items-center justify-content-center"
        style={{ marginBottom: 20 }}
      >
        <label
          className="d-flex align-items-center"
          htmlFor="entregar"
          style={{ cursor: "pointer" }}
        >
          <input
            type="radio"
            id="entregar"
            name="user"
            className="form-check-input mt-1 me-1 shadow-none"
            style={{ cursor: "pointer" }}
            checked={entregar ? true : false}
            onChange={() => {
              setEntregar(true);
              setRetirar(false);
            }}
          />
          <h6 className="mb-0 mt-1 text-uppercase">Entregar</h6>
        </label>
        <label
          className="ms-3 d-flex align-items-center"
          htmlFor="retirar"
          style={{ cursor: "pointer" }}
        >
          <input
            type="radio"
            id="retirar"
            name="user"
            className="form-check-input mt-1 me-1 shadow-none"
            style={{ cursor: "pointer" }}
            checked={retirar ? true : false}
            onChange={() => {
              setRetirar(true);
              setEntregar(false);
            }}
          />
          <h6 className="mb-0 mt-1 text-uppercase">Retirar </h6>
        </label>
      </div>

      {entregar ? (
        <div className="col-12">
          <form>
            <div className="row">
              <div className=" col-12">
                <input
                  style={{ fontWeight: "bold" }}
                  type="text"
                  placeholder="Telefone"
                  onBlur={() => {
                    if (telefone.length === numeroTelefoneQuantidade) {
                      if (!pegouInfoNumero) {
                        EnderecoPorTelefoneApi(telefone);
                        setPegouInfoNumero(true);
                      }
                    }
                  }}
                  onChange={(e) => {
                    const regex = /^[0-9]{0,11}$/;
                    const inputValue = e.target.value;
                    if (regex.test(inputValue)) {
                      setTelefone(inputValue);
                    }
                  }}
                  value={telefone}
                  className="form-control mb-2"
                  id="InputTelefone"
                  aria-describedby="Telefone"
                />
              </div>
            </div>
            <div className="row">
              <div className=" col-12">
                <input
                  type="text"
                  style={{ fontWeight: "bold" }}
                  placeholder="Nome"
                  onChange={(e) => {
                    setMudando(!mudando);
                    setNome(e.target.value);
                  }}
                  value={nome}
                  className="form-control mb-2"
                  id="InputNome"
                  aria-describedby="nome"
                />
              </div>
            </div>
            <div className="row">
              <div className="mb-2 col-12">
                <input
                  type="text"
                  style={{ fontWeight: "bold" }}
                  placeholder="CEP"
                  onChange={(e) => {
                    setMudando(!mudando);
                    const regex = /^[0-9]{0,8}$/;
                    const inputValue = e.target.value;
                    if (regex.test(inputValue)) {
                      setCEP(inputValue);
                    }
                  }}
                  value={cep}
                  className="form-control"
                  id="InputNome"
                  aria-describedby="nome"
                />
              </div>
            </div>
            <div className="row">
              <div className="mb-2 col-8 d-inline-block">
                <input
                  style={{ fontWeight: "bold" }}
                  placeholder="Endereço"
                  type="text"
                  onChange={(e) => {
                    setMudando(!mudando);

                    setEndereco(e.target.value);
                  }}
                  value={endereco}
                  className="form-control mb-2"
                  id="InputNome"
                  aria-describedby="nome"
                  disabled
                />
              </div>
              <div className=" col-4 d-inline-block">
                <input
                  type="text"
                  style={{ fontWeight: "bold" }}
                  placeholder="Complemento"
                  onChange={(e) => {
                    setMudando(!mudando);
                    setComplemento(e.target.value);
                  }}
                  value={complemento}
                  className="form-control mb-2"
                  id="InputNome"
                  aria-describedby="nome"
                  disabled={
                    telefone.length !== numeroTelefoneQuantidade ||
                    cep.length !== 8
                      ? true
                      : false
                  }
                />
              </div>
            </div>

            <div className="row">
              <div className=" col-12">
                <input
                  style={{ fontWeight: "bold" }}
                  placeholder="Bairro"
                  type="text"
                  onChange={(e) => {
                    setMudando(!mudando);

                    setBairro(e.target.value);
                  }}
                  value={bairro}
                  className="form-control mb-2"
                  id="InputNome"
                  aria-describedby="nome"
                  disabled
                />
              </div>
            </div>

            <div className="row">
              <div className="mb-2 col-8 d-inline-block">
                <input
                  style={{ fontWeight: "bold" }}
                  placeholder="Cidade"
                  type="text"
                  className="form-control mb-2"
                  aria-describedby="nome"
                  name="cidades"
                  id="cidades"
                  onChange={(e) => {
                    setMudando(!mudando);
                    setCidade(e.target.value);
                  }}
                  value={cidade}
                  disabled
                />
              </div>
              <div className=" col-4 d-inline-block">
                <input
                  type="text"
                  style={{ fontWeight: "bold" }}
                  placeholder="Estado"
                  onChange={(e) => {
                    setMudando(!mudando);
                    setUF(e.target.value);
                  }}
                  value={uf}
                  className="form-control mb-2"
                  id="InputNome"
                  aria-describedby="nome"
                  disabled
                />
              </div>
            </div>
          </form>
        </div>
      ) : (
        <div className="col-12">
          <form>

            <div className="row">
              <div className=" col-12">
                <input
                  style={{ fontWeight: "bold" }}
                  type="text"
                  placeholder="Telefone"
                  onChange={(e) => {
                    const regex = /^[0-9]{0,11}$/;
                    const inputValue = e.target.value;
                    if (regex.test(inputValue)) {
                      setTelefone(inputValue);
                    }
                  }}
                  value={telefone}
                  className="form-control mb-2"
                  id="InputTelefone"
                  aria-describedby="Telefone"
                />
              </div>
            </div>
            <div className="row">
              <div className=" col-12">
                  

                <input
                  type="text"
                  style={{ fontWeight: "bold" }}
                  placeholder="Nome"
                  onChange={(e) => {
                    setMudando(!mudando);
                    setNome(e.target.value);
                  }}
                  value={nome}
                  className="form-control mb-2"
                  id="InputNome"
                  aria-describedby="nome"
 
                />
              </div>
            </div>
          </form>
        </div>
      )}
    </div>
  );
}

export default TelefoneModal;
