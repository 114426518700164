import { BASE_URL } from "../../constants/BASE_URL";
import "./style.css";
import { Link } from "react-router-dom";

function Categoria({ descricao, id_categoria, url_imagem }) {
  return (
    <div className="categoria text-center" onClick={() => {}}>
      <Link to={`/busca?id_cat=${id_categoria}&descr=${descricao}`}>
        <div>
          <img className="img-categoria" src={BASE_URL + url_imagem} alt="Categoria" />
        </div>
        <span>{descricao}</span>
      </Link>
    </div>
  );
}

export default Categoria;
