import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
export const FooterEnderecoCheckout = ({
  navigate,
  handleUserInfo,
  endereco,
  darkMode,
  setEnderecoTelefone,
  SalvarDadosUsuarioTelefone,
  setCheckoutEtapa,
}) => {
  return (
    <>
      <div className="container-fluid">
        <button
          className={`btn btn-danger w-100`}
          style={{
            display: "flex",
            alignItems: "center",
            textAlign: "center",
            justifyContent: "center",
            borderRadius: 0,
            padding: 12,
            marginBottom: 20,
          }}
          onClick={() => {
            setEnderecoTelefone(false);
            // SalvarDadosUsuarioTelefone();

            setCheckoutEtapa(0);
            navigate("/checkout/carrinho");
          }}
        >
          <KeyboardBackspaceIcon sx={{ marginRight: 1 }} /> Voltar
        </button>

        <button
          className={`btn ${
            endereco
              ? darkMode === "dark"
                ? "btn-secondary"
                : "btn-primary"
              : " btn-warning"
          } w-100`}
          disabled={endereco ? false : true}
          style={{ borderRadius: 0, padding: 12 }}
          onClick={() => {
            handleUserInfo();

            if (
              endereco &&
              localStorage.getItem("sessionEmail") ===
                "visitante@comeraqui.com.br"
            ) {
              SalvarDadosUsuarioTelefone();
            }else {
              setCheckoutEtapa(2);

              navigate("/checkout/pagamento");
            }
          }}
        >
          {endereco
            ? localStorage.getItem("sessionEmail") ===
              "visitante@comeraqui.com.br"
              ? "Salvar"
              : "Avançar"
            : "Escolha um endereço!"}
        </button>
      </div>
    </>
  );
};
