export const FormatarDinheiro = () => {

    const isNumeric = (value) => {
        const regra = /^[0-9]+$/;
    
        if (String(value).match(regra)) {
          return true;
        }
        return false;
      };
    
      const formatPrice = (value) => {
        if (value) {
          const val = (value / 100).toFixed(2).replace(".", ",");

          return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        }
        return 0;
      };
    
      const formatValueOnKeyUp = (event) => {

        let value = event.replace(".", "");
        value = value.replace(".", "");
        value = value.replace(".", "");
        value = value.replace(",", "");

    
        if (!isNumeric(value)) {
          return "0,00";
        }
        if (value > 99999 ) {
          return formatPrice(99999);
        }
        return formatPrice(value);
      };

      return {isNumeric,formatPrice,formatValueOnKeyUp}
}