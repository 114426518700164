import icon from '../../../assets/icon.png'
import iconDark from '../../../assets/icon-dark.png'

function Produto(props){
    return <>
        <div >
            <div >

                {props.tipo !== 'E' && (

                    <ul className='p-0 m-0 finalizar-pedido'>
                    
                        <div className="d-flex align-items-center justify-content-between py-1 border-bottom">

                            <div className='d-flex align-items-center'>
                          
                                <small>{props.qtd > 0 ? `${new Intl.NumberFormat('pt-BR', {minimumIntegerDigits: 2}).format(props.qtd)} x ` : null}<b>{props.nome} </b></small>
                                <div>{props.valor}</div>
                            </div>
                            
                            <div className='d-flex align-items-center'>
                                <small>
                                    {new Intl.NumberFormat('pt-BR', {style: 'currency',currency: 'BRL'}).format(props.valor_total)}
                                    
                                </small>
                                <a role='button' onClick={(e) => props.onClickRemover(props.id_carrinho)} className="d-flex ms-2 text-danger">
                                    <i className='fas fa-trash' style={{ fontSize: '14px', height: '14px', textDecoration: 'none' }} />
                                </a>
                            </div>

                        </div>
                        

                    </ul>
                )}

                {props.tipo === 'E' && (
                    <div>

                        <ul className='p-0 m-0 finalizar-pedido'>
                        
                            <div className="d-flex align-items-center justify-content-between py-1 border-bottom">

                                <div className='d-flex align-items-center'>
                                    <small>{props.qtd > 0 ? `${new Intl.NumberFormat('pt-BR', {minimumIntegerDigits: 2}).format(props.qtd)} x ` : null} <b>{props.nome} </b></small>
                                    <div>{props.valor}</div>
                                </div>
                                
                                <div className='d-flex align-items-center'>
                                    <small>
                                        {new Intl.NumberFormat('pt-BR', {style: 'currency',currency: 'BRL'}).format(props.valor_total)}
                                        
                                    </small>
                                    <a role='button' onClick={(e) => props.onClickRemover(props.id_carrinho)} className="d-flex ms-2 text-danger">
                                        <i className='fas fa-trash' style={{ fontSize: '14px', height: '14px', textDecoration: 'none' }} /> 
                                    </a>
                                </div>

                            </div>
                            

                        </ul>

                    </div>
                )}

                {props.detalhes && (
                    <ul className='p-0 m-0'>
                        {props.detalhes.map((detalhe, i) => {
                            return (
                                <li key={`${detalhe.nome}-${i}`} id={`${detalhe.nome}-${i}`} className='d-flex align-items-center justify-content-between py-1 border-bottom'>
                                    <small className='ps-4 ms-1'>{detalhe.quantidade > 0 ? `${detalhe.quantidade} x ` : null}{detalhe.nome}</small>
                                </li>
                            )
                        })}
                    </ul>
                )}

                {props.removidos ? (
                    <ul className='p-0 m-0'>
                        {props.removidos.map((removido, i) => (
                            <li key={`${removido}-${i}`} id={`${removido}-${i}`} className='d-flex align-items-center justify-content-between py-1 border-bottom'>
                                <small className='ps-4 ms-1' key={removido.descricao}>S/ {removido.descricao}</small>
                            </li>
                        ))}
                    </ul>
                ) : null
                }

                {props.bordas && (
                    <ul className='p-0 m-0'>
                        {props.bordas.map((borda, i) => (
                            <li key={`${i}`} id={`${i}`} className='d-flex align-items-center justify-content-between py-1 border-bottom'> 
                                <small className='ps-4 ms-1' key={borda.nome}>Borda {borda.nome}</small>
                            </li>
                        ))}
                    </ul>
                )}

                {props.observacao ?
                    (
                        <div className='ps-4 ms-1'>
                        <small className="d-block mt-2"><b>Observação:</b></small>
                        <small className="d-block">{props.observacao}</small>
                        </div>
                    )
                    : null
                }

                { props.onClickRemover ? 
                    <button onClick={(e) => props.onClickRemover(props.id_carrinho)} className="btn btn-sm btn-outline-danger mt-2 d-none">
                        <i className='fas fa-trash' />
                    </button>
                : null
                }
            </div>
        </div>
    </>
}

export default Produto;