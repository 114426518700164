import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate } from "react-router-dom";

export const FooterPagamentoCheckout = ({
  pagarSite,
  formCartao,
  setFormCartao,
  setSite,
  setPagarSite,
  setEntrega,
  setCartao,
  setPix,
  pix,
  setFormPix,
  handlePaymentEntrega,
  handlePaymentSite,
  setCheckoutEtapa,
  selecionaPagamento,
  setSelecionaPagamento,
  dinheiro,
  cartao,
  finalizarPedido,
}) => {
  const navigate = useNavigate();

  return (
    <>
      <div className="container-fluid">
        <>
          <button
            className={`btn btn-danger w-100`}
            style={{
              display: "flex",
              alignItems: "center",
              textAlign: "center",
              justifyContent: "center",
              borderRadius: 0,
              padding: 12,
              marginBottom: 20,
            }}
            onClick={() => {
              if (selecionaPagamento) {
                setCheckoutEtapa(1);
                navigate("/checkout/endereco");
                setFormCartao(false);
                setFormPix(false);
                // setPagar(true);
                // setSite(true);
                setPagarSite(true);
                setEntrega(false);
                setCartao(false);
                setPix(false);
              } else {
                setSelecionaPagamento(true);
                setFormPix(false);
                setPix(false);
                setPagarSite(false);
                setFormCartao(false);
              }
            }}
          >
            <KeyboardBackspaceIcon sx={{ marginRight: 1 }} /> Voltar
          </button>

          {pagarSite && (
            <button
              style={{ borderRadius: 0, padding: 12 }}
              className="btn btn-success w-100"
              disabled={!pagarSite}
              onClick={() => {
                if (pagarSite && formCartao) {
                  handlePaymentSite();
                  setCheckoutEtapa(3);
                  navigate("/checkout/status");
                } else {
                  dinheiro ? handlePaymentEntrega() : handlePaymentSite();

                  setSelecionaPagamento(false);
                }
              }}
            >
              {pagarSite && formCartao ? "Finalizar Pedido" : "Avançar"}
            </button>
          )}
          {!pagarSite && (
            <button
              className={`btn btn-dark w-100`}
              style={{ borderRadius: 0, padding: 12 }}
              disabled={
                dinheiro
                  ? finalizarPedido
                    ? false
                    : true
                  : cartao
                  ? false
                  : pix ? false : true
              }
              onClick={() => {
                handlePaymentEntrega();
                setCheckoutEtapa(3);
                navigate("/checkout/status");
              }}
            >
              {dinheiro &&
                (finalizarPedido ? "Finalizar pedido" : "Pagamento incorreto")}
              {!dinheiro && !cartao && !pix && "Forma de pagamento"}
              {!dinheiro && cartao && "Finalizar pedido"}
              {!dinheiro && !cartao && pix && "Finalizar pedido"}

              
            </button>
          )}
        </>
      </div>
    </>
  );
};
